import React, { useState, useEffect } from 'react';
import axios from 'axios';

function UserProfile() {
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken');
        if (!token) {
          throw new Error('No se encontró el token de autenticación');
        }
        const response = await axios.get('/api/usuarios/me', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setUserData(response.data);
        localStorage.setItem('isAdmin', response.data.es_admin);
      } catch (error) {
        setError('Error al obtener datos del usuario: ' + error.message);
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  const handleChangePassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setPasswordError('Las contraseñas no coinciden');
      return;
    }
    try {
      const token = sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken');
      await axios.put('/api/usuarios/change-password', 
        { newPassword },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      alert('Contraseña cambiada exitosamente');
      setNewPassword('');
      setConfirmPassword('');
      setPasswordError('');
    } catch (error) {
      setPasswordError('Error al cambiar la contraseña: ' + error.message);
    }
  };

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  if (!userData) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="container mx-auto px-4">
      <h2 className="text-2xl font-bold mb-4">Perfil de Usuario</h2>
      <div className="bg-white shadow-md rounded-lg p-6 mb-4">
        <p><strong>Nombre:</strong> {userData.nombre}</p>
        <p><strong>Email:</strong> {userData.email}</p>
        <p><strong>Nombre de usuario Radicale:</strong> {userData.radicale_username}</p>
        <p><strong>ID del Calendario:</strong> {userData.calendar_id}</p>
        <p><strong>Tipo de Usuario:</strong> {userData.es_admin ? 'Administrador' : 'Usuario'}</p>
      </div>
      <div className="bg-white shadow-md rounded-lg p-6">
        <h3 className="text-xl font-bold mb-4">Cambiar Contraseña</h3>
        <form onSubmit={handleChangePassword}>
          <div className="mb-4">
            <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700">Nueva Contraseña</label>
            <input
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">Confirmar Contraseña</label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              required
            />
          </div>
          {passwordError && <p className="text-red-500 mb-4">{passwordError}</p>}
          <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Cambiar Contraseña
          </button>
        </form>
      </div>
    </div>
  );
}

export default UserProfile;
