import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { FaComments, FaDatabase, FaToggleOn, FaToggleOff, FaCog, FaInfoCircle, FaSpinner, FaBullhorn, FaPlug, FaSync } from 'react-icons/fa';
import MauticConfig from './MauticConfig';
import { v4 as uuidv4 } from 'uuid';

function Integraciones() {
  const [isSyncing, setIsSyncing] = useState(false);
  const [integraciones, setIntegraciones] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [currentIntegration, setCurrentIntegration] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showMauticConfig, setShowMauticConfig] = useState(false);

  const fetchIntegrationsConfig = useCallback(async () => {
    setIsLoading(true);
    setError('');
    try {
      const token = sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken');
      if (!token) {
        throw new Error('No se encontró el token de autenticación');
      }
      const response = await axios.get('/api/integraciones', {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (response.data) {
        const allIntegrations = [
          { id: 1, tipo: 'nimboxTalk', configuracion: {}, activo: false },
          { id: 2, tipo: 'nimboxCRM', configuracion: {}, activo: false },
          { id: 3, tipo: 'webhook', configuracion: {}, activo: false },
          { id: 4, tipo: 'mautic', configuracion: {}, activo: false }
        ];
        const updatedIntegrations = allIntegrations.map(integration => {
          const serverIntegration = response.data.find(i => i.tipo === integration.tipo);
          return serverIntegration ? { ...integration, ...serverIntegration } : integration;
        });
        setIntegraciones(updatedIntegrations);
      }
    } catch (error) {
      console.error('Error al cargar la configuración de las integraciones:', error);
      if (error.response) {
        console.log('Respuesta del servidor:', error.response.data);
        console.log('Estado HTTP:', error.response.status);
        setError(`Error del servidor: ${error.response.status}. ${error.response.data.message || 'No se pudo cargar la configuración de las integraciones.'}`);
      } else if (error.request) {
        console.log('No se recibió respuesta del servidor');
        setError('No se pudo conectar con el servidor. Por favor, verifique su conexión a internet.');
      } else {
        console.log('Error al configurar la solicitud:', error.message);
        setError('Ocurrió un error al intentar cargar la configuración. Por favor, intente de nuevo más tarde.');
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchIntegrationsConfig();
  }, [fetchIntegrationsConfig]);

  const handleInputChange = (e, integration) => {
    const { name, value } = e.target;
    setIntegraciones(prevState => prevState.map(item => 
      item.tipo === integration 
        ? { 
            ...item, 
            configuracion: { 
              ...item.configuracion, 
              [name]: value 
            } 
          } 
        : item
    ));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      const integrationToUpdate = integraciones.find(item => item.tipo === currentIntegration);
      let configToSend = { ...integrationToUpdate.configuracion };

      if (currentIntegration === 'nimboxTalk') {
        // Validar campos de NimboxTalk
        if (!configToSend.accountId || !configToSend.userToken) {
          throw new Error('Account ID y Token de Acceso son obligatorios para NimboxTalk');
        }

        // Obtener el token de autorización (short token)
        const shortToken = sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken');
        if (!shortToken) {
          throw new Error('No se encontró el token de autorización');
        }

        // Usar el short token como embedding token
        configToSend.embeddingToken = shortToken;

        // Usar la URL predeterminada de NimboxTalk
        const nimboxTalkUrl = 'https://nimboxtalk.chat';

        try {
          // Crear Dashboard App en NimboxTalk
          const dashboardAppUrl = `https://nimboxtalk.chat/api/v1/accounts/${configToSend.accountId}/dashboard_apps`;
          console.log('URL de la petición Dashboard App:', dashboardAppUrl);
          // Enviar la configuración al servidor para que realice las peticiones
          const serverResponse = await axios.post('/api/nimboxtalk/configure', {
            ...configToSend,
            shortToken: shortToken // Usar el shortToken del usuario
          }, {
            headers: { Authorization: `Bearer ${shortToken}` }
          });

          // Actualizar la configuración con los IDs recibidos del servidor
          configToSend.dashboardAppId = serverResponse.data.dashboardAppId;
          configToSend.webhookId = serverResponse.data.webhookId;
        } catch (nimboxError) {
          console.error('Error al configurar NimboxTalk:', nimboxError);
          throw new Error(`Error al configurar NimboxTalk: ${nimboxError.message}`);
        }
      }

      const token = sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken');
      if (!token) {
        throw new Error('No se encontró el token de autenticación');
      }
    
      const response = await axios.put(`/api/integraciones/${integrationToUpdate.id}`, {
        tipo: currentIntegration,
        configuracion: configToSend,
        activo: true // Activamos la integración automáticamente si la configuración es exitosa
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      setIntegraciones(prevState => prevState.map(item => 
        item.tipo === currentIntegration ? response.data : item
      ));

      setSuccess(`Configuración de ${currentIntegration} guardada y activada exitosamente`);
      setShowModal(false);
    } catch (error) {
      console.error('Error detallado:', error);
      setError(`Error al configurar ${currentIntegration}: ${error.message}`);
    }
  };

  const toggleIntegration = async (integration) => {
    try {
      const integrationToToggle = integraciones.find(item => item.tipo === integration);
      const token = sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken');
      const response = await axios.put(`/api/integraciones/${integrationToToggle.id}`, {
        ...integrationToToggle,
        activo: !integrationToToggle.activo
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });

      setIntegraciones(prevState => prevState.map(item => 
        item.tipo === integration ? response.data : item
      ));

      setSuccess(`Integración ${integration} ${response.data.activo ? 'activada' : 'desactivada'} exitosamente`);

      // Si la integración de NimboxCRM se activa, sincronizar los leads
      if (integration === 'nimboxCRM' && response.data.activo) {
        syncLeadsWithNimboxCRM();
      }
    } catch (error) {
      setError(`Error al cambiar el estado de la integración ${integration}: ${error.response?.data?.message || error.message}`);
    }
  };

  const syncLeadsWithNimboxCRM = async () => {
    setIsSyncing(true);
    setError('');
    setSuccess('');
    try {
      const token = sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken');
      if (!token) {
        throw new Error('No se encontró el token de autenticación');
      }

      // Hacer la solicitud a nuestro backend para sincronizar los leads
      const response = await axios.post('/api/sync-leads-nimboxcrm', {}, {
        headers: { Authorization: `Bearer ${token}` }
      });

      if (response.data.success) {
        setSuccess('Leads sincronizados exitosamente con NimboxCRM');
      } else {
        throw new Error(response.data.message || 'Error desconocido al sincronizar leads');
      }
    } catch (error) {
      console.error('Error al sincronizar leads con NimboxCRM:', error);
      setError(`Error al sincronizar leads: ${error.message}`);
    } finally {
      setIsSyncing(false);
    }
  };

  const renderIntegrationCard = (integration) => (
    <div className={`bg-white shadow-xl rounded-xl px-6 py-6 transform transition duration-500 hover:scale-105 border border-gray-200 hover:border-blue-400 ${!integration.activo ? 'opacity-50' : ''}`}>
      <div className="flex flex-col items-center h-full">
        <div className={`p-3 rounded-full mb-2 ${integration.activo ? 'bg-blue-100' : 'bg-gray-100'}`}>
          {React.createElement(getIconForIntegration(integration.tipo), { className: `text-4xl ${integration.activo ? 'text-blue-600' : 'text-gray-400'}` })}
        </div>
        <h3 className={`text-lg font-bold mb-2 ${integration.activo ? 'text-gray-800' : 'text-gray-500'}`}>{getTitleForIntegration(integration.tipo)}</h3>
        <p className="text-xs text-gray-600 text-center mb-4 flex-grow overflow-y-auto">{getDescriptionForIntegration(integration.tipo)}</p>
        <div className="flex flex-col space-y-2 w-full mt-auto">
          <button
            onClick={() => {
              if (integration.tipo === 'mautic') {
                setShowMauticConfig(true);
              } else {
                setCurrentIntegration(integration.tipo);
                setShowModal(true);
              }
            }}
            className={`${integration.activo ? 'bg-blue-500 hover:bg-blue-600' : 'bg-gray-400 hover:bg-gray-500'} text-white font-bold py-2 px-4 rounded-lg text-sm transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg flex items-center justify-center`}
          >
            <FaCog className="mr-2" /> Configurar
          </button>
          <button
            onClick={() => toggleIntegration(integration.tipo)}
            className={`${integration.activo ? 'bg-green-500 hover:bg-green-600' : 'bg-gray-300 hover:bg-gray-400'} text-white font-bold py-2 px-4 rounded-lg text-sm flex items-center justify-center transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg`}
          >
            {integration.activo ? <FaToggleOn className="mr-2" /> : <FaToggleOff className="mr-2" />}
            {integration.activo ? 'Activado' : 'Desactivado'}
          </button>
        </div>
      </div>
    </div>
  );

  const getIconForIntegration = (tipo) => {
    switch (tipo) {
      case 'nimboxTalk':
        return FaComments;
      case 'nimboxCRM':
        return FaDatabase;
      case 'webhook':
        return FaCog;
      case 'mautic':
        return FaBullhorn;
      default:
        return FaInfoCircle;
    }
  };

  const getTitleForIntegration = (tipo) => {
    switch (tipo) {
      case 'nimboxTalk':
        return 'NimboxTalk';
      case 'nimboxCRM':
        return 'NimboxCRM';
      case 'webhook':
        return 'Webhook';
      case 'mautic':
        return 'Mautic';
      default:
        return tipo;
    }
  };

  const getDescriptionForIntegration = (tipo) => {
    switch (tipo) {
      case 'nimboxTalk':
        return 'Nuestra integración con NimboxTalk te permite sincronizar y gestionar de manera eficiente los leads de tu Bandeja de Entrada Multicanal. Con esta integración, puedes trabajar en un solo lugar con todos tus leads, sin importar el canal por el que lleguen, mejorando la productividad y asegurando que no se pierda ninguna oportunidad de negocio.';
      case 'nimboxCRM':
        return 'Nuestra integración con NimboxCRM permite una sincronización fluida de los leads, además de gestionar tus citas directamente en el calendario. Esta funcionalidad te ayuda a mantener toda tu información centralizada y organizada, asegurando que tanto los leads como las citas estén siempre actualizados y accesibles en cualquier momento.';
      case 'webhook':
        return 'Integra fácilmente nuestros servicios con tus sistemas a través de Webhooks. Recibe notificaciones automáticas en tiempo real sobre eventos clave y actualizaciones, permitiéndote mantener tus sistemas sincronizados y agilizar tus procesos operativos.';
      case 'mautic':
        return 'Integra Mautic para segmentar tus contactos y realizar tareas de Marketing Automation. Esta integración te permite crear campañas personalizadas, automatizar el envío de correos electrónicos y realizar un seguimiento detallado de tus leads a lo largo del embudo de ventas.';
      default:
        return 'Descripción no disponible.';
    }
  };


  return (
    <div className="container mx-auto px-4 py-12">
      <div className="flex flex-col md:flex-row justify-between items-center mb-12">
        <h1 className="text-4xl font-extrabold text-gray-800 mb-4 md:mb-0">Integraciones</h1>
        <button
          onClick={fetchIntegrationsConfig}
          className="bg-blue-100 hover:bg-blue-200 text-blue-800 font-bold py-3 px-6 rounded-lg transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg flex items-center"
          disabled={isLoading}
        >
          {isLoading ? <FaSpinner className="mr-2 animate-spin" /> : <FaInfoCircle className="mr-2" />}
          {isLoading ? 'Cargando...' : 'Actualizar estado'}
        </button>
      </div>

      
      {error && (
        <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-8 rounded-lg shadow" role="alert">
          <p className="font-bold">Error</p>
          <p>{error}</p>
        </div>
      )}
      
      {success && (
        <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-8 rounded-lg shadow" role="alert">
          <p className="font-bold">Éxito</p>
          <p>{success}</p>
        </div>
      )}
      
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <FaSpinner className="animate-spin text-6xl text-blue-500" />
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
          {integraciones.map(integration => renderIntegrationCard(integration))}
        </div>
      )}
      {showMauticConfig && (
        <MauticConfig isOpen={showMauticConfig} onClose={() => setShowMauticConfig(false)} />
      )}

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
          <div className="bg-white p-8 rounded-lg shadow-2xl w-full max-w-md">
            <h2 className="text-2xl font-bold mb-6 text-gray-800">
              Configuración de {getTitleForIntegration(currentIntegration)}
            </h2>
            <form onSubmit={handleSubmit} className="space-y-6">
              {currentIntegration === 'nimboxCRM' && (
                <button
                  type="button"
                  onClick={syncLeadsWithNimboxCRM}
                  className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg flex items-center justify-center"
                  disabled={isSyncing}
                >
                  {isSyncing ? <FaSpinner className="animate-spin mr-2" /> : <FaSync className="mr-2" />}
                  {isSyncing ? 'Sincronizando...' : 'Sincronizar Leads'}
                </button>
              )}
              {currentIntegration && (
                <>
                  {currentIntegration === 'nimboxTalk' && (
                    <>
                      <div>
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="accountId">
                          Account ID
                        </label>
                        <input
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="accountId"
                          type="text"
                          name="accountId"
                          value={integraciones.find(i => i.tipo === currentIntegration)?.configuracion?.accountId || ''}
                          onChange={(e) => handleInputChange(e, currentIntegration)}
                          placeholder="Ingrese Account ID de NimboxTalk"
                        />
                      </div>
                      <div>
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="userToken">
                          Token de Acceso
                        </label>
                        <input
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="userToken"
                          type="text"
                          name="userToken"
                          value={integraciones.find(i => i.tipo === currentIntegration)?.configuracion?.userToken || ''}
                          onChange={(e) => handleInputChange(e, currentIntegration)}
                          placeholder="Ingrese Token de Acceso de NimboxTalk"
                        />
                      </div>
                    </>
                  )}
                  {currentIntegration === 'nimboxCRM' && (
                    <>
                      <div>
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="url">
                          URL
                        </label>
                        <input
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="url"
                          type="text"
                          name="url"
                          value={integraciones.find(i => i.tipo === currentIntegration)?.configuracion?.url || ''}
                          onChange={(e) => handleInputChange(e, currentIntegration)}
                          placeholder="Ingrese URL de NimboxCRM"
                        />
                      </div>
                      <div>
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="apiKey">
                          Clave API
                        </label>
                        <input
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="apiKey"
                          type="text"
                          name="apiKey"
                          value={integraciones.find(i => i.tipo === currentIntegration)?.configuracion?.apiKey || ''}
                          onChange={(e) => handleInputChange(e, currentIntegration)}
                          placeholder="Ingrese Clave API de NimboxCRM"
                        />
                      </div>
                    </>
                  )}
                  {currentIntegration === 'webhook' && (
                    <>
                      <div>
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="url">
                          URL del Webhook
                        </label>
                        <input
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="url"
                          type="text"
                          name="url"
                          value={integraciones.find(i => i.tipo === currentIntegration)?.configuracion?.url || ''}
                          onChange={(e) => handleInputChange(e, currentIntegration)}
                          placeholder="Ingrese URL del Webhook"
                        />
                      </div>
                    </>
                  )}
                  {currentIntegration === 'mautic' && (
                    <>
                      <div>
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="url">
                          URL de Mautic
                        </label>
                        <input
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="url"
                          type="text"
                          name="url"
                          value={integraciones.find(i => i.tipo === currentIntegration)?.configuracion?.url || ''}
                          onChange={(e) => handleInputChange(e, currentIntegration)}
                          placeholder="Ingrese URL de Mautic"
                        />
                      </div>
                      <div>
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                          Usuario
                        </label>
                        <input
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="username"
                          type="text"
                          name="username"
                          value={integraciones.find(i => i.tipo === currentIntegration)?.configuracion?.username || ''}
                          onChange={(e) => handleInputChange(e, currentIntegration)}
                          placeholder="Ingrese usuario de Mautic"
                        />
                      </div>
                      <div>
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                          Contraseña
                        </label>
                        <input
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="password"
                          type="password"
                          name="password"
                          value={integraciones.find(i => i.tipo === currentIntegration)?.configuracion?.password || ''}
                          onChange={(e) => handleInputChange(e, currentIntegration)}
                          placeholder="Ingrese contraseña de Mautic"
                        />
                      </div>
                    </>
                  )}
                </>
              )}
              <div className="flex items-center justify-between">
                <button
                  className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg"
                  type="submit"
                >
                  Guardar configuración
                </button>
                <button
                  className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg"
                  type="button"
                  onClick={() => setShowModal(false)}
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {showMauticConfig && (
        <MauticConfig isOpen={showMauticConfig} onClose={() => setShowMauticConfig(false)} />
      )}
    </div>
  );
}

export default Integraciones;
