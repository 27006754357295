import React, { useState, useEffect, useCallback } from 'react';
import { InfoIcon, PlusIcon, TrashIcon, SaveIcon, RefreshCwIcon, UserIcon, BuildingIcon, PhoneIcon, MailIcon, Maximize2Icon, XIcon, MessageSquareIcon, ChevronDownIcon } from 'lucide-react';

// Componentes UI mejorados
const Button = ({ children, className, variant, onClick, size = 'default', disabled = false }) => (
  <button 
    className={`rounded-md ${className} 
    ${variant === 'outline' ? 'border border-gray-300 hover:bg-gray-100 text-gray-700' : 
      variant === 'destructive' ? 'bg-red-500 text-white hover:bg-red-600' : 
      'bg-blue-500 text-white hover:bg-blue-600'}
    ${size === 'small' ? 'px-2 py-1 text-sm' : 'px-4 py-2'}
    ${disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}
    flex items-center justify-center`} 
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </button>
);
const Input = ({ placeholder, value, onChange, type = "text", icon }) => (
  <div className="relative">
    {icon && <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-400">{icon}</span>}
    <input 
      placeholder={placeholder} 
      value={value} 
      onChange={onChange} 
      type={type} 
      className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 ${icon ? 'pl-10' : ''}`} 
    />
  </div>
);
const Textarea = ({ placeholder, className, value, onChange }) => (
  <textarea 
    placeholder={placeholder} 
    className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 ${className}`} 
    value={value} 
    onChange={onChange} 
  />
);
const Card = ({ children }) => <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-6">{children}</div>;
const CardHeader = ({ children }) => <div className="px-6 py-4 bg-gray-50 border-b">{children}</div>;
const CardContent = ({ children }) => <div className="px-6 py-4">{children}</div>;
const CardTitle = ({ children, className }) => <h2 className={`text-2xl font-bold ${className}`}>{children}</h2>;
const Select = ({ children, onValueChange, value, placeholder }) => (
  <div className="relative">
    <select 
      value={value}
      onChange={(e) => onValueChange(e.target.value)} 
      className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 appearance-none"
    >
      {placeholder && <option value="" disabled>{placeholder}</option>}
      {children}
    </select>
    <ChevronDownIcon className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
  </div>
);
const SelectItem = ({ value, children }) => <option value={value}>{children}</option>;
const Tooltip = ({ children, content }) => (
  <div className="relative group">
    {children}
    <div className="absolute z-10 p-2 text-sm bg-gray-800 text-white rounded shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-48 text-center">
      {content}
    </div>
  </div>
);
const Badge = ({ children, variant, className, onClick }) => (
  <span 
    className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium 
    ${variant === 'secondary' ? 'bg-gray-100 text-gray-800' : 'bg-blue-100 text-blue-800'} 
    ${className} ${onClick ? 'cursor-pointer hover:bg-opacity-75' : ''}`} 
    onClick={onClick}
  >
    {children}
  </span>
);

export default function PromptAndCompanyData({ promptText, companyData, botPersonalization, onPromptChange, onCompanyDataChange, onBotPersonalizationChange, saveCurrentData, templates, onLoadTemplate, selectedTemplate, setSelectedTemplate, onSaveTemplate, onDeleteTemplate, onSetDefaultTemplate }) {
  const [localPromptText, setLocalPromptText] = useState(promptText);
  const [localCompanyData, setLocalCompanyData] = useState({
    ...companyData,
    botName: companyData.botName || ''
  });
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [tone, setTone] = useState(botPersonalization.tone || 'Neutro');
  const [newTemplateName, setNewTemplateName] = useState('');
  const [isDefaultTemplate, setIsDefaultTemplate] = useState(false);

  useEffect(() => {
    setLocalPromptText(promptText);
    setLocalCompanyData(companyData);
  }, [promptText, companyData]);

  const loadTemplateData = useCallback((template) => {
    if (template) {
      setLocalPromptText(template.prompt || '');
      setLocalCompanyData({
        name: template.company_data?.name || '',
        description: template.company_data?.description || '',
        contact: template.company_data?.contact || '',
        email: template.company_data?.email || '',
        botName: template.company_data?.botName || ''
      });
      setTone(template.tone || 'Neutro');
      setIsDefaultTemplate(template.plantilla_predeterminada || false);
      onPromptChange(template.prompt || '');
      onCompanyDataChange({
        name: template.company_data?.name || '',
        description: template.company_data?.description || '',
        contact: template.company_data?.contact || '',
        email: template.company_data?.email || '',
        botName: template.company_data?.botName || ''
      });
      onBotPersonalizationChange({
        name: template.company_data?.botName || '',
        tone: template.tone || 'Neutro'
      });
    }
  }, [onPromptChange, onCompanyDataChange, onBotPersonalizationChange]);

  useEffect(() => {
    const defaultTemplate = templates.find(t => t.plantilla_predeterminada);
    if (defaultTemplate) {
      loadTemplateData(defaultTemplate);
      setSelectedTemplate(defaultTemplate);
    }
  }, [templates, loadTemplateData]);

  useEffect(() => {
    if (selectedTemplate) {
      loadTemplateData(selectedTemplate);
    }
  }, [selectedTemplate, loadTemplateData]);

  const insertVariable = (variable) => {
    setLocalPromptText(`${localPromptText}{{${variable}}}`);
  };

  const generateSamplePrompt = () => {
    const samplePrompt = `Actúa como {{botName}}, un asistente virtual profesional de {{companyName}}, especializado en agendar citas con el equipo comercial.

Instrucciones:

    Mantén un tono {{tone}} y eficiente.
    Recopila la información necesaria para agendar la cita de manera rápida y personalizada.

Ejemplo de interacción:
"Hola, soy {{botName}} de {{companyName}}, una empresa dedicada a {{companyDescription}}. Estoy aquí para ayudarte a programar una cita con nuestro equipo comercial. ¿Podrías proporcionarme los detalles necesarios? Si necesitas más información, puedes contactarnos en {{companyContact}} o a través de {{companyEmail}}."`;
    setLocalPromptText(samplePrompt);
  };

  const handleCompanyDataChange = (key, value) => {
    setLocalCompanyData({ ...localCompanyData, [key]: value });
  };

  const handleSaveChanges = () => {
    onPromptChange(localPromptText);
    onCompanyDataChange({
      ...localCompanyData,
      botName: localCompanyData.botName || ''
    });
    onBotPersonalizationChange({ name: localCompanyData.botName, tone });
  };



  const replaceVariables = (text) => {
    if (!text) return '';
    return text.replace(/{{(\w+)}}/g, (_, key) => {
      let value;
      if (key === 'tone') value = tone;
      else if (key === 'companyName') value = localCompanyData.name;
      else if (key === 'companyDescription') value = localCompanyData.description;
      else if (key === 'companyContact') value = localCompanyData.contact;
      else if (key === 'companyEmail') value = localCompanyData.email;
      else if (key === 'botName') value = localCompanyData.botName;
      else return `{{${key}}}`;

      return value ? value : `{{${key}}}`;
    });
  };

  const toneOptions = [
    { value: 'Formal', description: 'Profesional y serio, adecuado para contextos empresariales o legales.' },
    { value: 'Amigable', description: 'Cercano y cálido, busca generar una conexión con el usuario.' },
    { value: 'Humorístico', description: 'Ligero y divertido, perfecto para aplicaciones informales o de entretenimiento.' },
    { value: 'Neutro', description: 'Directo y objetivo, ideal para proporcionar información clara y concisa.' },
    { value: 'Motivador', description: 'Anima al usuario a tomar acción o sentirse bien con su progreso.' },
    { value: 'Empático', description: 'Transmite comprensión y apoyo, útil en situaciones de servicio al cliente.' },
    { value: 'Autoritario', description: 'Firme y directo, adecuado para recordatorios o advertencias importantes.' },
    { value: 'Creativo/Inspiracional', description: 'Usa lenguaje atractivo y motivador, adecuado para aplicaciones creativas o artísticas.' },
    { value: 'Informativo', description: 'Proporciona datos o instrucciones claras y completas.' },
    { value: 'Respetuoso', description: 'Protocolo cortés, suele utilizarse en contextos delicados.' },
  ];

  const getToneExample = (tone) => {
    switch (tone) {
      case 'Formal':
        return "Estimado cliente, le informamos que su solicitud ha sido procesada satisfactoriamente.";
      case 'Amigable':
        return "👋 ¡Hola! ¿Qué tal? Tengo buenas noticias, ¡tu pedido ya está en camino! 📦";
      case 'Humorístico':
        return "😅 ¡Ups! Parece que alguien ha estado de compras. No te preocupes, tu cartera se recuperará... eventualmente. 💸";
      case 'Neutro':
        return "Su pedido ha sido enviado. Llegará en 3-5 días hábiles.";
      case 'Motivador':
        return "🎉 ¡Excelente elección! Este producto te ayudará a alcanzar tus metas. ¡Sigue así! 💪";
      case 'Empático':
        return "😊 Entendemos que la espera puede ser difícil. Estamos haciendo todo lo posible para que recibas tu pedido lo antes posible.";
      case 'Autoritario':
        return "⚠️ Es crucial que siga las instrucciones al pie de la letra para garantizar el correcto funcionamiento del producto.";
      case 'Creativo/Inspiracional':
        return "✨ Imagina las posibilidades que se abren ante ti con este producto. ¡El único límite es tu imaginación! 🌈";
      case 'Informativo':
        return "ℹ️ Este producto está fabricado con materiales de alta calidad y tiene una garantía de 2 años. Para más detalles, consulte el manual de usuario.";
      case 'Respetuoso':
        return "Nos complace informarle que su solicitud ha sido atendida. Agradecemos su confianza en nuestros servicios.";
      default:
        return "Ejemplo de mensaje en el tono seleccionado.";
    }
  };

  return (
    <>
      <div className="max-w-7xl mx-auto p-6 bg-gray-100 rounded-xl shadow-md">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <Card>
            <CardHeader>
              <CardTitle className="text-blue-600">Datos de la Empresa</CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              <div className="space-y-4">
                <div>
                  <label htmlFor="companyName" className="block text-sm font-medium text-gray-700 mb-1">Nombre de la empresa</label>
                  <Input 
                    id="companyName"
                    icon={<UserIcon className="h-5 w-5" />}
                    placeholder="Nombre de la empresa" 
                    value={localCompanyData.name}
                    onChange={(e) => handleCompanyDataChange('name', e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="companyDescription" className="block text-sm font-medium text-gray-700 mb-1">Descripción de la empresa</label>
                  <Input 
                    id="companyDescription"
                    icon={<BuildingIcon className="h-5 w-5" />}
                    placeholder="Descripción de la empresa"
                    value={localCompanyData.description}
                    onChange={(e) => handleCompanyDataChange('description', e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="companyContact" className="block text-sm font-medium text-gray-700 mb-1">Número de contacto</label>
                  <Input 
                    id="companyContact"
                    icon={<PhoneIcon className="h-5 w-5" />}
                    placeholder="Número de contacto"
                    value={localCompanyData.contact}
                    onChange={(e) => handleCompanyDataChange('contact', e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="companyEmail" className="block text-sm font-medium text-gray-700 mb-1">Email de contacto</label>
                  <Input 
                    id="companyEmail"
                    icon={<MailIcon className="h-5 w-5" />}
                    placeholder="Email de contacto" 
                    type="email"
                    value={localCompanyData.email}
                    onChange={(e) => handleCompanyDataChange('email', e.target.value)}
                  />
                </div>
              </div>
              <div className="mt-8 mb-4">
                <h3 className="text-lg font-semibold text-gray-700 mb-4">Personalidad del Bot</h3>
                <div className="border-t border-gray-200 mb-4"></div>
                <div className="space-y-4">
                  <div>
                    <label htmlFor="botName" className="block text-sm font-medium text-gray-700 mb-1">Nombre del bot</label>
                    <Input 
                      id="botName"
                      icon={<UserIcon className="h-5 w-5" />}
                      placeholder="Nombre del bot" 
                      value={localCompanyData.botName}
                      onChange={(e) => handleCompanyDataChange('botName', e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <label htmlFor="tone" className="block text-sm font-medium text-gray-700 mb-1">
                  Tono de la conversación
                </label>
                <Select
                  value={tone}
                  onValueChange={setTone}
                  icon={<MessageSquareIcon className="h-5 w-5" />}
                >
                  {toneOptions.map((option) => (
                    <SelectItem key={option.value} value={option.value}>
                      {option.value}
                    </SelectItem>
                  ))}
                </Select>
                <p className="mt-1 text-sm text-gray-500">{toneOptions.find(o => o.value === tone)?.description}</p>
                <div className="mt-2 bg-gray-100 p-4 rounded-lg">
                  <div className="flex items-start space-x-2">
                    <div className="w-8 h-8 bg-green-500 rounded-full flex items-center justify-center text-white font-bold">
                      A
                    </div>
                    <div className="flex-1">
                      <div className="bg-white p-3 rounded-lg shadow-sm relative">
                        <div className="absolute top-1/2 -left-2 w-4 h-4 bg-white transform rotate-45"></div>
                        <p className="text-sm text-gray-800">
                          {getToneExample(tone)}
                        </p>
                      </div>
                      <p className="text-xs text-gray-500 mt-1">12:00 PM</p>
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle className="flex items-center justify-between text-blue-600">
                <span>Configuración del Prompt</span>
                <Tooltip content="Define cómo se comportará tu asistente virtual">
                  <InfoIcon className="h-5 w-5 text-blue-400 cursor-help" />
                </Tooltip>
              </CardTitle>
            </CardHeader>
            <CardContent className="space-y-6">
              <div className="space-y-2">
                <div className="relative">
                  <Textarea 
                    placeholder="Escribe aquí el prompt para tu asistente virtual..." 
                    className={`min-h-[200px] text-gray-700 w-full ${isFullScreen ? 'fixed inset-0 z-50 h-full p-4' : ''}`}
                    value={localPromptText}
                    onChange={(e) => setLocalPromptText(e.target.value)}
                  />
                  {isFullScreen && (
                    <Button 
                      variant="outline" 
                      size="small" 
                      className="fixed top-4 right-4 z-50"
                      onClick={() => setIsFullScreen(false)}
                    >
                      <XIcon className="h-4 w-4" />
                    </Button>
                  )}
                  {!isFullScreen && (
                    <Button 
                      variant="outline" 
                      size="small" 
                      className="absolute top-2 right-2"
                      onClick={() => setIsFullScreen(true)}
                    >
                      <Maximize2Icon className="h-4 w-4" />
                    </Button>
                  )}
                </div>
                <div className="flex justify-end space-x-2">
                  <Button 
                    variant="outline" 
                    size="small" 
                    onClick={generateSamplePrompt}
                  >
                    <RefreshCwIcon className="mr-1 h-3 w-3" />
                    <span className="text-xs">Generar ejemplo</span>
                  </Button>
                </div>
              </div>
              <div>
                <h3 className="font-semibold mb-2 text-blue-600">Variables disponibles:</h3>
                <p className="text-sm text-gray-600 mb-2">
                  Haz clic en una variable para insertarla en tu prompt.
                </p>
                <div className="grid grid-cols-1 gap-2">
                  {[
                    { name: 'companyName', description: 'Nombre de tu empresa' },
                    { name: 'companyDescription', description: 'Descripción de tu empresa' },
                    { name: 'companyContact', description: 'Número de contacto de tu empresa' },
                    { name: 'companyEmail', description: 'Email de contacto de tu empresa' },
                    { name: 'botName', description: 'Nombre del bot' },
                    { name: 'tone', description: 'Tono de la conversación seleccionado' }
                  ].map((variable) => (
                    <div key={variable.name} className="flex items-center">
                      <Badge variant="secondary" className="cursor-pointer hover:bg-blue-200 mr-2" onClick={() => insertVariable(variable.name)}>
                        {`{{${variable.name}}}`}
                      </Badge>
                      <span className="text-sm text-gray-600">{variable.description}</span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="mt-3"></div>
              <div className="bg-white p-4 rounded-md border border-gray-200 relative">
                <h3 className="font-semibold mb-2 text-blue-600">Vista previa:</h3>
                <p className="text-sm text-gray-700">
                  {replaceVariables(localPromptText)}
                </p>
                <img 
                  src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDIwIDIwIj48cGF0aCBmaWxsPSIjYzAyNmQzIiBkPSJNMTIgNS41YTEgMSAwIDEgMCAwIDJhMSAxIDAgMCAwIDAtMm0tNSAxYTEgMSAwIDEgMSAyIDBhMSAxIDAgMCAxLTIgMG0zLjUtNGEuNS41IDAgMCAwLTEgMFYzaC0zQTEuNSAxLjUgMCAwIDAgNSA0LjV2NEExLjUgMS41IDAgMCAwIDYuNSAxMGg2LjI5NGwuMzI2LTFINi41YS41LjUgMCAwIDEtLjUtLjV2LTRhLjUuNSAwIDAgMSAuNS0uNWg3YS41LjUgMCAwIDEgLjUuNXYzLjU4M2ExLjQyIDEuNDIgMCAwIDEgMSAuMDE2VjQuNUExLjUgMS41IDAgMCAwIDEzLjUgM2gtM3ptLTIgOWgxLjkwOGExLjQyIDEuNDIgMCAwIDAtLjQwOC45OTd2LjAwNkg1LjMxYS44MS44MSAwIDAgMC0uODEuODF2LjQzN2MwIC42OS4xMzEgMS40NTYuODAyIDIuMDY5QzUuOTkgMTYuNDQ2IDcuMzQgMTcgMTAgMTdjMS41NSAwIDIuNjU1LS4xODggMy40NDQtLjQ3YTEuNCAxLjQgMCAwIDAgLjY3OC40MTlhMS4zIDEuMyAwIDAgMC0uMTE3LjQzOWMtLjkxNi4zNjctMi4xMzcuNTktMy43NTUuNjFWMThoLS41di0uMDAyYy0yLjYxNi0uMDMzLTQuMTk1LS41OTUtNS4xMjItMS40NGMtLjg3NS0uOC0xLjA4OS0xLjc3Ny0xLjEyMy0yLjU1NkgzLjV2LS42OWMwLS45OTkuODEtMS44MDkgMS44MS0xLjgwOUg4LjV6bTYuMzc4LTIuMjE4bC4zNDggMS4wNzFhMi4yIDIuMiAwIDAgMCAxLjM5OSAxLjM5N2wxLjA3MS4zNDhsLjAyMS4wMDZhLjQyMy40MjMgMCAwIDEgMCAuNzk4bC0xLjA3MS4zNDhhMi4yIDIuMiAwIDAgMC0xLjM5OSAxLjM5N2wtLjM0OCAxLjA3YS40MjMuNDIzIDAgMCAxLS43OTggMGwtLjM0OS0xLjA3YTIuMiAyLjIgMCAwIDAtLjY1LS45NzdhMi4yIDIuMiAwIDAgMC0uNzQ4LS40MjZsLTEuMDcxLS4zNDhhLjQyMy40MjMgMCAwIDEgMC0uNzk4bDEuMDcxLS4zNDhhMi4yIDIuMiAwIDAgMCAxLjM3Ny0xLjM5N2wuMzQ4LTEuMDdhLjQyMy40MjMgMCAwIDEgLjc5OSAwbTQuOTA1IDcuOTMxbC0uNzY2LS4yNDhhMS41OCAxLjU4IDAgMCAxLS45OTgtLjk5OGwtLjI1LS43NjVhLjMwMi4zMDIgMCAwIDAtLjU3IDBsLS4yNDguNzY1YTEuNTggMS41OCAwIDAgMS0uOTg0Ljk5OGwtLjc2NS4yNDhhLjMwMy4zMDMgMCAwIDAtLjE0Ni40NmMuMDM2LjA1LjA4Ny4wOS4xNDYuMTFsLjc2NS4yNDlhMS41OCAxLjU4IDAgMCAxIDEgMS4wMDJsLjI0OC43NjRhLjMwMi4zMDIgMCAwIDAgLjU3IDBsLjI0OS0uNzY0YTEuNTggMS41OCAwIDAgMSAuOTk5LS45OTlsLjc2NS0uMjQ4YS4zMDIuMzAyIDAgMCAwIDAtLjU3em0tNi4xNzQtLjUyN2wuMDcuMDUzWiIvPjwvc3ZnPg=="
                  alt="Bot seleccionado"
                  className="absolute bottom-2 right-2 w-8 h-8"
                />
              </div>
              <div className="mt-6"></div>
              
              {/* Gestión de plantillas */}
              <div className="mt-6 border-t pt-6 bg-gray-50 rounded-lg p-6 shadow-md">
                <h3 className="font-semibold mb-4 text-blue-600 text-xl">Gestión de Plantillas</h3>
                <div className="space-y-4">
                  <div className="flex items-center space-x-2">
                    <Select
                      value={selectedTemplate ? selectedTemplate.name : ''}
                      onValueChange={(value) => {
                        const template = templates.find(t => t.name === value);
                        setSelectedTemplate(template);
                      }}
                      placeholder="Seleccionar plantilla"
                      className="flex-grow"
                    >
                      {templates.map((template) => (
                        <SelectItem key={template.name} value={template.name}>
                          {template.name} {template.plantilla_predeterminada ? '(Por defecto)' : ''}
                        </SelectItem>
                      ))}
                    </Select>
                    <Button
                      variant="outline"
                      size="small"
                      onClick={() => {
                        setSelectedTemplate(null);
                        setLocalPromptText('');
                        setLocalCompanyData({ name: '', description: '', contact: '', email: '', botName: '' });
                        setTone('Neutro');
                        setIsDefaultTemplate(false);
                        onPromptChange('');
                        onCompanyDataChange({ name: '', description: '', contact: '', email: '', botName: '' });
                        onBotPersonalizationChange({ name: '', tone: 'Neutro' });
                      }}
                      disabled={!selectedTemplate}
                      className="whitespace-nowrap"
                    >
                      <RefreshCwIcon className="mr-1 h-4 w-4" />
                      <span>Limpiar</span>
                    </Button>
                  </div>
                  {selectedTemplate && (
                    <p className="text-sm text-gray-600 mt-2">
                      Plantilla seleccionada: <strong>{selectedTemplate.name}</strong>
                      {selectedTemplate.plantilla_predeterminada && <span className="ml-2 text-green-600">(Por defecto)</span>}
                    </p>
                  )}
                  <div className="flex items-center space-x-2">
                    <Input
                      placeholder="Nombre de la nueva plantilla"
                      value={newTemplateName}
                      onChange={(e) => setNewTemplateName(e.target.value)}
                      className="flex-grow"
                    />
                    <Button
                      variant="outline"
                      size="small"
                      onClick={() => {
                        if (newTemplateName) {
                          onSaveTemplate({
                            name: newTemplateName,
                            promptText: localPromptText,
                            companyData: localCompanyData,
                            botPersonalization: { name: localCompanyData.botName, tone },
                            plantilla_predeterminada: isDefaultTemplate
                          });
                          setNewTemplateName('');
                        }
                      }}
                      disabled={!newTemplateName}
                      className="whitespace-nowrap"
                    >
                      <PlusIcon className="mr-1 h-4 w-4" />
                      <span>Guardar Nueva</span>
                    </Button>
                  </div>
                  {selectedTemplate && (
                    <div className="space-y-4 bg-white p-6 rounded-lg shadow-sm">
                      <div className="flex justify-between items-center">
                        <h4 className="font-medium text-gray-800 text-lg">Plantilla seleccionada: {selectedTemplate.name}</h4>
                        <Button
                          variant="destructive"
                          size="small"
                          onClick={() => onDeleteTemplate(selectedTemplate.name)}
                          className="whitespace-nowrap"
                        >
                          <TrashIcon className="mr-1 h-4 w-4" />
                          <span>Eliminar</span>
                        </Button>
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                          <h5 className="font-medium text-gray-700 mb-2">Datos de la Empresa</h5>
                          <p><strong>Nombre:</strong> {localCompanyData.name}</p>
                          <p><strong>Descripción:</strong> {localCompanyData.description}</p>
                          <p><strong>Contacto:</strong> {localCompanyData.contact}</p>
                          <p><strong>Email:</strong> {localCompanyData.email}</p>
                        </div>
                        <div>
                          <h5 className="font-medium text-gray-700 mb-2">Personalidad del Bot</h5>
                          <p><strong>Nombre del Bot:</strong> {localCompanyData.botName}</p>
                          <p><strong>Tono:</strong> {tone}</p>
                        </div>
                      </div>
                      <div className="flex items-center space-x-2 mt-4">
                        <label className="inline-flex items-center cursor-pointer">
                          <input
                            type="checkbox"
                            className="sr-only peer"
                            checked={selectedTemplate.plantilla_predeterminada}
                            onChange={(e) => {
                              setIsDefaultTemplate(e.target.checked);
                              onSetDefaultTemplate(selectedTemplate.name);
                            }}
                          />
                          <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                          <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Usar como plantilla por defecto</span>
                        </label>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </CardContent>
          </Card>
        </div>

        <Button className="w-full mt-8" onClick={() => saveCurrentData({ promptText: localPromptText, companyData: localCompanyData, botPersonalization: { name: localCompanyData.botName, tone } })}>
          <SaveIcon className="mr-2 h-4 w-4" />
          <span>Guardar Cambios</span>
        </Button>
      </div>
    </>
  );
}
