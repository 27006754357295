import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const RADICALE_URL = process.env.RADICALE_URL || 'https://calendar.hiveagile.dev';

function Usuarios() {
  const [usuarios, setUsuarios] = useState([]);
  const [nombre, setNombre] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [esAdmin, setEsAdmin] = useState(false);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [editingUser, setEditingUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [upcomingAppointments, setUpcomingAppointments] = useState([]);
  const [pastAppointments, setPastAppointments] = useState([]);

  useEffect(() => {
    checkUserStatus();
  }, []);

  async function checkUserStatus() {
    try {
      const token = sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken');
      if (!token) {
        throw new Error('No se encontró el token de autenticación');
      }
      const response = await axios.get('/api/usuarios/me', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setCurrentUser(response.data);
      if (response.data.es_admin) {
        setIsAdmin(true);
        fetchUsuarios();
      } else {
        setError('No tienes permisos de administrador para ver esta página.');
      }
      fetchUpcomingAppointments(response.data.id);
      fetchPastAppointments(response.data.id);
    } catch (error) {
      console.error('Error al verificar el estado del usuario:', error);
      setError('Error al verificar permisos: ' + (error.response?.data?.error || error.message));
      setIsAdmin(false);
    }
  }

  async function fetchUpcomingAppointments(userId) {
    try {
      const response = await axios.get(`/api/usuarios/${userId}/citas/upcoming`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken')}` }
      });
      setUpcomingAppointments(response.data);
    } catch (err) {
      console.error('Error al obtener las citas próximas:', err);
    }
  }

  async function fetchPastAppointments(userId) {
    try {
      const response = await axios.get(`/api/usuarios/${userId}/citas/past`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken')}` }
      });
      setPastAppointments(response.data);
    } catch (err) {
      console.error('Error al obtener las citas pasadas:', err);
    }
  }

  async function fetchUsuarios() {
    try {
      const token = sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken');
      if (!token) {
        throw new Error('No se encontró el token de autenticación');
      }
      const response = await axios.get('/api/usuarios', {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (Array.isArray(response.data)) {
        setUsuarios(response.data);
      } else {
        throw new Error('Formato de respuesta inesperado');
      }
      setError('');
    } catch (error) {
      setError('Error al cargar usuarios: ' + (error.response?.data?.error || error.message));
      console.error('Error fetching usuarios:', error.response?.data || error.message);
    }
  }

  const handleCreateUsuario = async (e) => {
    e.preventDefault();
    try {
      const token = sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken');
      if (!token) {
        throw new Error('No se encontró el token de autenticación');
      }
      const response = await axios.post('/api/usuarios', 
        { nombre, email, password, es_admin: esAdmin },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setUsuarios([...usuarios, response.data]);
      setNombre('');
      setEmail('');
      setPassword('');
      setEsAdmin(false);
      setError('');
      setIsModalOpen(false);
    } catch (error) {
      setError('Error al crear usuario: ' + (error.response?.data?.error || error.message));
      console.error('Error creating usuario:', error.response?.data || error);
    }
  };

  const handleDeleteUsuario = async (id) => {
    try {
      const response = await axios.delete(`/api/usuarios/${id}`);
      if (response.status === 200) {
        setUsuarios(usuarios.filter(usuario => usuario.id !== id));
        setError('');
      } else {
        throw new Error('Error inesperado al eliminar usuario');
      }
    } catch (error) {
      const errorMessage = error.response?.data?.error || error.response?.data?.details || error.message;
      setError('Error al eliminar usuario: ' + errorMessage);
      console.error('Error deleting usuario:', error.response?.data || error);
    }
  };

  const handleEditUsuario = (usuario) => {
    setEditingUser(usuario);
    setNombre(usuario.nombre);
    setEmail(usuario.email);
    setPassword('');
    setEsAdmin(usuario.es_admin || false);
    setIsModalOpen(true);
  };

  const handleUpdateUsuario = async (e) => {
    e.preventDefault();
    try {
      const updateData = { nombre, es_admin: esAdmin };
      if (password) {
        updateData.password = password;
      }
      const response = await axios.put(`/api/usuarios/${editingUser.id}`, updateData);
      setUsuarios(usuarios.map(u => u.id === editingUser.id ? { ...u, nombre: response.data.nombre, es_admin: response.data.es_admin } : u));
      setEditingUser(null);
      setNombre('');
      setEmail('');
      setPassword('');
      setEsAdmin(false);
      setError('');
      setIsModalOpen(false);
    } catch (error) {
      setError('Error al actualizar usuario: ' + (error.response?.data?.error || error.message));
      console.error('Error updating usuario:', error.response?.data || error);
    }
  };

  const filteredUsuarios = usuarios.filter(usuario =>
    usuario.nombre.toLowerCase().includes(searchTerm.toLowerCase()) ||
    usuario.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container mx-auto px-4">
      <h2 className="text-2xl font-bold mb-4">Usuarios</h2>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      {isAdmin ? (
        <>
          <div className="flex justify-between items-center mb-4">
            <input
              type="text"
              placeholder="Buscar usuarios..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 mr-2"
            />
            <button
              onClick={() => {
                setEditingUser(null);
                setNombre('');
                setEmail('');
                setPassword('');
                setIsModalOpen(true);
              }}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Añadir Usuario
            </button>
          </div>
          {/* Resto del contenido para administradores */}
        </>
      ) : currentUser ? (
        <div>
          <h3 className="text-xl font-bold mb-2">Tu perfil</h3>
          <p><strong>Nombre:</strong> {currentUser.nombre}</p>
          <p><strong>Email:</strong> {currentUser.email}</p>
          <p><strong>Rol:</strong> {currentUser.es_admin ? 'Administrador' : 'Usuario'}</p>
          
          <h4 className="text-lg font-bold mt-4 mb-2">Próximas Citas</h4>
          <ul>
            {upcomingAppointments.map((appointment) => (
              <li key={appointment.id} className="mb-2">
                <p>Fecha: {appointment.fecha}</p>
                <p>Hora: {appointment.hora_inicio} - {appointment.hora_fin}</p>
                <p>Servicio: {appointment.servicio?.nombre || 'No especificado'}</p>
              </li>
            ))}
          </ul>
          
          <h4 className="text-lg font-bold mt-4 mb-2">Citas Pasadas</h4>
          <ul>
            {pastAppointments.map((appointment) => (
              <li key={appointment.id} className="mb-2">
                <p>Fecha: {appointment.fecha}</p>
                <p>Hora: {appointment.hora_inicio} - {appointment.hora_fin}</p>
                <p>Servicio: {appointment.servicio?.nombre || 'No especificado'}</p>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <p>Cargando información del usuario...</p>
      )}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
          <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
            <div className="mt-3 text-center">
              <h3 className="text-lg leading-6 font-medium text-gray-900">{editingUser ? 'Editar Usuario' : 'Añadir Usuario'}</h3>
              <form onSubmit={editingUser ? handleUpdateUsuario : handleCreateUsuario} className="mt-2">
                <input
                  type="text"
                  placeholder="Nombre"
                  value={nombre}
                  onChange={(e) => setNombre(e.target.value)}
                  className="w-full px-3 py-2 mb-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-3 py-2 mb-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                  disabled={editingUser}
                />
                <input
                  type="password"
                  placeholder={editingUser ? "Nueva contraseña (dejar en blanco para no cambiar)" : "Contraseña"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full px-3 py-2 mb-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required={!editingUser}
                />
                <div className="flex items-center mb-3">
                  <input
                    type="checkbox"
                    id="esAdmin"
                    checked={esAdmin}
                    onChange={(e) => setEsAdmin(e.target.checked)}
                    className="mr-2"
                  />
                  <label htmlFor="esAdmin">Es Administrador</label>
                </div>
                <div className="flex justify-end mt-2">
                  <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
                  >
                    {editingUser ? 'Actualizar' : 'Crear'}
                  </button>
                  <button
                    type="button"
                    onClick={() => setIsModalOpen(false)}
                    className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  >
                    Cancelar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {Array.isArray(filteredUsuarios) && filteredUsuarios.length > 0 ? (
          filteredUsuarios.map(usuario => (
            <div key={usuario.id} className="bg-white shadow-md rounded-lg overflow-hidden">
              <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2">{usuario.nombre || 'N/A'}</div>
                <p className="text-gray-700 text-base mb-2">
                  <strong>Email:</strong> {usuario.email || 'N/A'}
                </p>
                {isAdmin && (
                  <>
                    <p className="text-gray-700 text-base mb-2">
                      <strong>API Key:</strong> {usuario.short_token || 'N/A'}
                    </p>
                    <p className="text-gray-700 text-base mb-2">
                      <strong>Es Admin:</strong> {usuario.es_admin ? 'Sí' : 'No'}
                    </p>
                    <p className="text-gray-700 text-base mb-2">
                      <strong>Radicale Username:</strong> {usuario.radicale_username || 'N/A'}
                    </p>
                    <p className="text-gray-700 text-base mb-2">
                      <strong>Calendar ID:</strong> {usuario.calendar_id || 'N/A'}
                    </p>
                  </>
                )}
              </div>
              <div className="px-6 pt-4 pb-2">
                <button
                  onClick={() => handleEditUsuario(usuario)}
                  className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
                >
                  Editar
                </button>
                <button
                  onClick={() => handleDeleteUsuario(usuario.id)}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Eliminar
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>No se encontraron usuarios.</p>
        )}
      </div>
    </div>
  );
}

export default Usuarios;
