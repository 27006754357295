import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Horarios() {
  const [horarios, setHorarios] = useState([]);
  const [error, setError] = useState('');
  const [newHorario, setNewHorario] = useState({ dia_inicio: '', dia_fin: '', hora_inicio: '', hora_fin: '' });

  useEffect(() => {
    fetchHorarios();
  }, []);

  async function fetchHorarios() {
    try {
      const token = sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken');
      if (!token) {
        throw new Error('No se encontró el token de autenticación');
      }
      const response = await axios.get('/api/horarios', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setHorarios(response.data);
      setError('');
    } catch (error) {
      setError('Error al cargar horarios: ' + error.message);
      console.error('Error fetching horarios:', error);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      const token = sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken');
      if (!token) {
        throw new Error('No se encontró el token de autenticación');
      }
      await axios.post('/api/horarios', newHorario, {
        headers: { Authorization: `Bearer ${token}` }
      });
      fetchHorarios();
      setNewHorario({ dia_inicio: '', dia_fin: '', hora_inicio: '', hora_fin: '' });
    } catch (error) {
      setError('Error al crear horario: ' + error.message);
      console.error('Error creating horario:', error);
    }
  }

  async function handleDelete(id) {
    try {
      const token = sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken');
      if (!token) {
        throw new Error('No se encontró el token de autenticación');
      }
      await axios.delete(`/api/horarios/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      fetchHorarios();
    } catch (error) {
      setError('Error al eliminar horario: ' + error.message);
      console.error('Error deleting horario:', error);
    }
  }

  function handleInputChange(e) {
    setNewHorario({ ...newHorario, [e.target.name]: e.target.value });
  }

  const diasSemana = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];

  return (
    <div className="container mx-auto p-4 bg-gray-100 min-h-screen">
      <h1 className="text-3xl font-bold mb-6 text-gray-800">Horarios de Atención</h1>
      {error && <p className="text-red-500 mb-4 bg-red-100 p-2 rounded">{error}</p>}
      <form onSubmit={handleSubmit} className="mb-8 bg-white p-6 rounded-lg shadow-md">
        <div className="flex flex-wrap items-end -mx-2">
          <div className="w-full md:w-1/5 px-2 mb-4 md:mb-0">
            <select
              name="dia_inicio"
              value={newHorario.dia_inicio}
              onChange={handleInputChange}
              className="p-2 border rounded w-full"
              required
            >
              <option value="">Día inicio</option>
              {diasSemana.map((dia, index) => (
                <option key={index} value={index + 1}>{dia}</option>
              ))}
            </select>
          </div>
          <div className="w-full md:w-1/5 px-2 mb-4 md:mb-0">
            <select
              name="dia_fin"
              value={newHorario.dia_fin}
              onChange={handleInputChange}
              className="p-2 border rounded w-full"
              required
            >
              <option value="">Día fin</option>
              {diasSemana.map((dia, index) => (
                <option key={index} value={index + 1}>{dia}</option>
              ))}
            </select>
          </div>
          <div className="w-full md:w-1/5 px-2 mb-4 md:mb-0">
            <input
              type="time"
              name="hora_inicio"
              value={newHorario.hora_inicio}
              onChange={handleInputChange}
              className="p-2 border rounded w-full"
              required
            />
          </div>
          <div className="w-full md:w-1/5 px-2 mb-4 md:mb-0">
            <input
              type="time"
              name="hora_fin"
              value={newHorario.hora_fin}
              onChange={handleInputChange}
              className="p-2 border rounded w-full"
              required
            />
          </div>
          <div className="w-full md:w-1/5 px-2">
            <button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white p-2 rounded w-full transition duration-300">
              Agregar Horario
            </button>
          </div>
        </div>
      </form>
      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <table className="w-full">
          <thead className="bg-gray-200">
            <tr>
              <th className="p-3 text-left">Día</th>
              <th className="p-3 text-left">Hora Inicio</th>
              <th className="p-3 text-left">Hora Fin</th>
              <th className="p-3 text-left">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {horarios.map((horario, index) => (
              <tr key={horario.id} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                <td className="p-3">{diasSemana[horario.dia_semana - 1]}</td>
                <td className="p-3">{horario.hora_inicio.substring(0, 5)}</td>
                <td className="p-3">{horario.hora_fin.substring(0, 5)}</td>
                <td className="p-3">
                  <button 
                    onClick={() => handleDelete(horario.id)} 
                    className="bg-red-500 hover:bg-red-600 text-white p-2 rounded transition duration-300"
                  >
                    Eliminar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Horarios;
