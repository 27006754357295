import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaPlus, FaEdit, FaTrash, FaClock, FaLink, FaCog } from 'react-icons/fa';

function Servicios() {
  const [servicios, setServicios] = useState([]);
  const [error, setError] = useState('');
  const [newServicio, setNewServicio] = useState({ nombre: '', descripcion: '', duracion: '15', es_de_pago: false, precio: '0' });
  const [editingServicio, setEditingServicio] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isStripeConfigModalOpen, setIsStripeConfigModalOpen] = useState(false);
  const [stripeSecretKey, setStripeSecretKey] = useState('');
  const [hasStripeConfig, setHasStripeConfig] = useState(false);

  useEffect(() => {
    fetchServicios();
  }, []);

  async function fetchServicios() {
    try {
      const token = sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken');
      if (!token) {
        throw new Error('No se encontró el token de autenticación');
      }
      const response = await axios.get('/api/servicios', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setServicios(response.data);
      setError('');
    } catch (error) {
      setError('Error al cargar servicios: ' + error.message);
      console.error('Error fetching servicios:', error);
    }
  }

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    if (editingServicio) {
      setEditingServicio({ ...editingServicio, [name]: newValue });
    } else {
      setNewServicio({ ...newServicio, [name]: newValue });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken');
      if (!token) {
        throw new Error('No se encontró el token de autenticación');
      }
      const servicioToSubmit = editingServicio || newServicio;
      
      const data = {
        nombre: servicioToSubmit.nombre,
        descripcion: servicioToSubmit.descripcion,
        duracion: servicioToSubmit.duracion,
        es_de_pago: servicioToSubmit.es_de_pago,
        precio: servicioToSubmit.es_de_pago ? servicioToSubmit.precio : '0'
      };
      
      if (editingServicio) {
        const response = await axios.put(`/api/servicios/${editingServicio.id}`, data, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setServicios(servicios.map(s => s.id === editingServicio.id ? response.data : s));
      } else {
        const response = await axios.post('/api/servicios', data, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setServicios([...servicios, response.data]);
      }
      
      setNewServicio({ nombre: '', descripcion: '', duracion: '15', es_de_pago: false, precio: '0' });
      setEditingServicio(null);
      setIsModalOpen(false);
      setError('');
    } catch (error) {
      setError('Error al guardar servicio: ' + (error.response?.data?.error || error.message));
      console.error('Error saving servicio:', error.response?.data || error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const token = sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken');
      if (!token) {
        throw new Error('No se encontró el token de autenticación');
      }
      await axios.delete(`/api/servicios/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setServicios(servicios.filter(servicio => servicio.id !== id));
      setError('');
    } catch (error) {
      setError('Error al eliminar servicio: ' + error.message);
      console.error('Error deleting servicio:', error);
    }
  };

  const generatePaymentLink = async (id) => {
    try {
      const token = sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken');
      if (!token) {
        throw new Error('No se encontró el token de autenticación');
      }
      const response = await axios.post(`/api/servicios/${id}/payment-link`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      window.open(response.data.paymentLink, '_blank');
    } catch (error) {
      setError('Error al generar enlace de pago: ' + error.message);
      console.error('Error generating payment link:', error);
    }
  };

  const openModal = (servicio = null) => {
    if (servicio) {
      setEditingServicio(servicio);
    } else {
      setNewServicio({ nombre: '', descripcion: '', duracion: '15', es_de_pago: false, precio: '0' });
    }
    setIsModalOpen(true);
  };

  const handleStripeConfigSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken');
      if (!token) {
        throw new Error('No se encontró el token de autenticación');
      }
      await axios.post('/api/servicios/stripe-config', { stripeSecretKey }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setHasStripeConfig(true);
      setIsStripeConfigModalOpen(false);
      setError('');
    } catch (error) {
      setError('Error al configurar la clave secreta de Stripe: ' + error.message);
    }
  };

  const checkStripeConfig = async () => {
    try {
      const token = sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken');
      if (!token) {
        throw new Error('No se encontró el token de autenticación');
      }
      const response = await axios.get('/api/servicios/stripe-config', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setHasStripeConfig(response.data.hasStripeConfig);
      if (response.data.hasStripeConfig) {
        setStripeSecretKey(response.data.stripeSecretKey);
      }
    } catch (error) {
      console.error('Error al verificar la configuración de Stripe:', error);
    }
  };

  useEffect(() => {
    checkStripeConfig();
  }, []);

  return (
    <div className="container mx-auto px-4">
      <div className="flex flex-col md:flex-row justify-between items-center mb-6">
        <h2 className="text-3xl font-bold mb-4 md:mb-0">Mis Servicios</h2>
        <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2">
          <button
            onClick={() => setIsStripeConfigModalOpen(true)}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center justify-center"
          >
            <FaCog className="mr-2" /> Configurar Stripe
          </button>
          <button
            onClick={() => openModal()}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded flex items-center justify-center"
          >
            <FaPlus className="mr-2" /> Nuevo Servicio
          </button>
        </div>
      </div>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {servicios.map(servicio => (
          <div key={servicio.id} className="bg-gray-50 shadow-lg rounded-lg overflow-hidden transition-transform duration-300 hover:shadow-xl border border-gray-300">
            <div className="px-6 py-4 hover:bg-gray-50 transition duration-200">
              <div className="flex justify-between items-center mb-2">
                <div className="text-gray-900 font-bold text-xl">{servicio.nombre}</div>
                {servicio.es_de_pago && <div className="text-2xl">💵</div>}
              </div>
              <p className="text-gray-700 text-base mb-4">{servicio.descripcion}</p>
              {servicio.es_de_pago && (
                <div className="text-center mb-4">
                  <span className="font-bold text-3xl text-green-600">
                    {`${servicio.precio} €`}
                  </span>
                </div>
              )}
              <div className="flex items-center text-gray-600 mb-4">
                <FaClock className="mr-2" />
                <span className="text-sm">{servicio.duracion} minutos</span>
              </div>
              <div className="flex flex-col justify-between h-full">
                <div>
                  <div className="flex justify-between mt-4 mb-2">
                    <div className="flex space-x-2">
                      <button
                        onClick={() => openModal(servicio)}
                        className="text-indigo-600 hover:text-indigo-700 font-bold transition duration-300"
                      >
                        <FaEdit />
                      </button>
                      <button
                        onClick={() => handleDelete(servicio.id)}
                        className="text-red-600 hover:text-red-700 font-bold transition duration-300"
                      >
                        <FaTrash />
                      </button>
                      {servicio.es_de_pago && (
                        <button
                          onClick={() => generatePaymentLink(servicio.id)}
                          className="text-green-600 hover:text-green-700 font-bold transition duration-300"
                        >
                          <FaLink />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
          <div className="bg-white p-8 rounded-lg shadow-xl w-full max-w-md">
            <h3 className="text-2xl font-bold mb-6 text-center">{editingServicio ? 'Editar Servicio' : 'Nuevo Servicio'}</h3>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="nombre">
                  Nombre del servicio
                </label>
                <input
                  type="text"
                  name="nombre"
                  value={editingServicio ? editingServicio.nombre : newServicio.nombre}
                  onChange={handleInputChange}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="descripcion">
                  Descripción
                </label>
                <textarea
                  name="descripcion"
                  value={editingServicio ? editingServicio.descripcion : newServicio.descripcion}
                  onChange={handleInputChange}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  rows="4"
                  required
                ></textarea>
              </div>
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="duracion">
                  Duración (minutos)
                </label>
                <input
                  type="number"
                  name="duracion"
                  value={editingServicio ? editingServicio.duracion : newServicio.duracion}
                  onChange={handleInputChange}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                  min="1"
                />
              </div>
              <div>
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    name="es_de_pago"
                    checked={editingServicio ? editingServicio.es_de_pago : newServicio.es_de_pago}
                    onChange={handleInputChange}
                    className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500"
                  />
                  <span className="ml-2 text-sm font-medium text-gray-900">Servicio de pago</span>
                </label>
              </div>
              {(editingServicio ? editingServicio.es_de_pago : newServicio.es_de_pago) && (
                <div>
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="precio">
                    Precio (€)
                  </label>
                  <input
                    type="number"
                    name="precio"
                    value={editingServicio ? editingServicio.precio : newServicio.precio}
                    onChange={handleInputChange}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                    min="0"
                    step="0.01"
                  />
                </div>
              )}
              <div className="flex items-center justify-between pt-4">
                <button
                  type="submit"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  {editingServicio ? 'Actualizar' : 'Crear'}
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setIsModalOpen(false);
                    setEditingServicio(null);
                  }}
                  className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10"
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {isStripeConfigModalOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
          <div className="bg-white p-8 rounded-lg shadow-xl w-full max-w-md">
            <h3 className="text-2xl font-bold mb-4">Configurar Stripe</h3>
            <form onSubmit={handleStripeConfigSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="stripeSecretKey">
                  Clave Secreta de Stripe
                </label>
                <input
                  type="text"
                  id="stripeSecretKey"
                  value={stripeSecretKey}
                  onChange={(e) => setStripeSecretKey(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="flex items-center justify-between">
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Guardar
                </button>
                <button
                  type="button"
                  onClick={() => setIsStripeConfigModalOpen(false)}
                  className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default Servicios;
