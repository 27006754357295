import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaVideo, FaCalendarAlt, FaBan, FaList, FaPlus, FaFilter, FaTrash, FaDownload, FaCalendarCheck, FaBroom, FaClock } from 'react-icons/fa';
import { supabase } from '../supabaseClient';
import { Calendar, dayjsLocalizer } from 'react-big-calendar';
import dayjs from 'dayjs';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../styles/Calendar.css';
import Modal from 'react-modal';
import Select from 'react-select';
import { useMediaQuery } from 'react-responsive';

const localizer = dayjsLocalizer(dayjs);

function Citas() {
  const [currentTime, setCurrentTime] = useState('');
  const [currentWeekday, setCurrentWeekday] = useState('');
  const isMobile = useMediaQuery({ maxWidth: 768 });

  useEffect(() => {
    fetchCurrentTime();
    fetchCurrentWeekday();
  }, []);

  const fetchCurrentTime = async () => {
    try {
      const response = await axios.get('/api/time');
      setCurrentTime(response.data.current_time);
    } catch (error) {
      console.error('Error fetching current time:', error);
    }
  };

  const fetchCurrentWeekday = async () => {
    try {
      const response = await axios.get('/api/weekday');
      setCurrentWeekday(response.data.current_weekday);
    } catch (error) {
      console.error('Error fetching current weekday:', error);
    }
  };
  console.log('Rendering Citas component');
  const [citas, setCitas] = useState([]);
  const [error, setError] = useState('');
  const [newCita, setNewCita] = useState({ fecha: '', hora_inicio: '', hora_fin: '', servicio_id: null, contacto_nombre: '', contacto_email: '', contacto_telefono: '' });
  const [calendarUrl, setCalendarUrl] = useState('');
  const [festivos, setFestivos] = useState([]);
  const [isImportingFestivos, setIsImportingFestivos] = useState(false);
  const [servicios, setServicios] = useState([]);
  const [viewMode, setViewMode] = useState('list');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showFestivosMenu, setShowFestivosMenu] = useState(false);
  const [showAddCitaMenu, setShowAddCitaMenu] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [provincias, setProvincias] = useState([]);
  const [localidades, setLocalidades] = useState([]);
  const [selectedProvincia, setSelectedProvincia] = useState(null);
  const [selectedLocalidad, setSelectedLocalidad] = useState(null);
  const [provincia, setProvincia] = useState('');
  const [festivosAImportar, setFestivosAImportar] = useState([]);
  const [festivosFilter, setFestivosFilter] = useState({
    nacionales: true,
    locales: true,
    personalizados: true
  });
  const [selectedFestivos, setSelectedFestivos] = useState([]);
  const [filtroTiempo, setFiltroTiempo] = useState('todos');

  const filtrarCitasPorTiempo = (citasArray) => {
    if (!Array.isArray(citasArray)) {
      console.error('citasArray is not an array:', citasArray);
      return [];
    }
    const hoy = new Date();
    const inicioSemana = new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate() - hoy.getDay());
    const inicioMes = new Date(hoy.getFullYear(), hoy.getMonth(), 1);

    return citasArray.filter(cita => {
      if (!cita || !cita.fecha) {
        console.error('Invalid cita object:', cita);
        return false;
      }
      const fechaCita = new Date(cita.fecha);
      switch (filtroTiempo) {
        case 'dia':
          return fechaCita.toDateString() === hoy.toDateString();
        case 'semana':
          return fechaCita >= inicioSemana && fechaCita < new Date(inicioSemana.getTime() + 7 * 24 * 60 * 60 * 1000);
        case 'mes':
          return fechaCita.getMonth() === hoy.getMonth() && fechaCita.getFullYear() === hoy.getFullYear();
        default:
          return true;
      }
    });
  };

  useEffect(() => {
    fetchCitas();
    fetchServicios();
    fetchCalendarUrl();
    fetchFestivos();
    fetchProvincias();
  }, []);

  const fetchProvincias = async () => {
    try {
      console.log('Fetching provincias...');
      const { data, error } = await supabase
        .from('festivos_globales')
        .select('provincia')
        .order('provincia');
    
      if (error) throw error;
    
      console.log('Provincias response:', data);
      if (Array.isArray(data) && data.length > 0) {
        const uniqueProvincias = [...new Set(data.map(item => item.provincia))];
        setProvincias(uniqueProvincias.map(provincia => ({ value: provincia, label: provincia })));
        setError('');
      } else {
        throw new Error('La respuesta de provincias está vacía o no es un array');
      }
    } catch (error) {
      console.error('Error fetching provincias:', error);
      setError('Error al cargar provincias: ' + error.message);
      setProvincias([]);
    }
  };

  const handleComunidadAutonomaChange = async (selectedOption) => {
    setSelectedComunidadAutonoma(selectedOption);
    setSelectedCapital(null);
    try {
      const response = await axios.get(`/api/capitales/${selectedOption.value}`);
      setCapitales(response.data);
    } catch (error) {
      console.error('Error fetching capitales:', error);
    }
  };

  const handleCapitalChange = (selectedOption) => {
    setSelectedCapital(selectedOption);
  };

  const handleImportFestivosProvinciales = async () => {
    if (!selectedProvincia) {
      setError('Por favor, seleccione una provincia');
      return;
    }
    setIsImportingFestivos(true);
    try {
      const token = sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken');
      if (!token) {
        throw new Error('No se encontró el token de autenticación');
      }
      
      // Importar festivos nacionales y provinciales
      const response = await axios.post('/api/festivos/importar', {
        provincia: selectedProvincia.value,
        localidad: selectedLocalidad ? selectedLocalidad.value : null
      }, {
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      console.log('Response from server:', response);
      
      if (response.data.festivos) {
        setFestivos(response.data.festivos);
        alert('Festivos importados correctamente');
        fetchFestivos(); // Refresh the festivos list after importing
      } else {
        throw new Error('No se recibieron festivos del servidor');
      }
    } catch (error) {
      console.error('Error importing festivos:', error);
      let errorMessage = 'Error al importar festivos: ';
      if (error.response) {
        console.error('Error response:', error.response);
        errorMessage += error.response.data?.error || error.response.statusText;
      } else if (error.request) {
        console.error('Error request:', error.request);
        errorMessage += 'No se recibió respuesta del servidor';
      } else {
        errorMessage += error.message;
      }
      setError(errorMessage);
    } finally {
      setIsImportingFestivos(false);
    }
  };

  const handleProvinciaChange = async (selectedOption) => {
    setSelectedProvincia(selectedOption);
    setProvincia(selectedOption.value);
    setSelectedLocalidad(null);
    setFestivosAImportar([]);
    try {
      const token = sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken');
      if (!token) {
        throw new Error('No se encontró el token de autenticación');
      }
      const response = await axios.get('/api/festivos/localidades', {
        headers: { Authorization: `Bearer ${token}` },
        params: { provincia: selectedOption.value }
      });
      if (Array.isArray(response.data) && response.data.length > 0) {
        setLocalidades(response.data.map(loc => ({ value: loc, label: loc })));
        setError('');
      } else {
        throw new Error('No se encontraron localidades para esta provincia');
      }
    } catch (error) {
      console.error('Error al obtener localidades:', error);
      setError('Error al obtener localidades: ' + (error.response?.data?.error || error.message));
      setLocalidades([]);
    }
  };

  const handleLocalidadChange = async (selectedOption) => {
    setSelectedLocalidad(selectedOption);
    try {
      const token = sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken');
      if (!token) {
        throw new Error('No se encontró el token de autenticación');
      }
      const response = await axios.get('/api/festivos/localidad', {
        headers: { Authorization: `Bearer ${token}` },
        params: { 
          provincia: selectedProvincia.value,
          localidad: selectedOption.value
        }
      });
      setFestivosAImportar(response.data);
      setError('');
    } catch (error) {
      console.error('Error al obtener festivos de la localidad:', error);
      setError('Error al obtener festivos de la localidad: ' + (error.response?.data?.error || error.message));
    }
  };

  const handleRemoveFestivoAImportar = (index) => {
    setFestivosAImportar(prevFestivos => prevFestivos.filter((_, i) => i !== index));
  };


  const handleDeleteFestivo = async (id) => {
    try {
      const token = sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken');
      if (!token) {
        throw new Error('No se encontró el token de autenticación');
      }
      const response = await axios.delete(`/api/festivos/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log('Delete festivo response:', response);
      if (response.status === 200) {
        fetchFestivos();
        alert('Festivo eliminado correctamente');
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error deleting festivo:', error);
      let errorMessage = 'Error al eliminar festivo: ';
      if (error.response) {
        console.error('Error response:', error.response);
        errorMessage += error.response.data?.error || error.response.statusText;
      } else if (error.request) {
        console.error('Error request:', error.request);
        errorMessage += 'No se recibió respuesta del servidor';
      } else {
        errorMessage += error.message;
      }
      setError(errorMessage);
    }
  };

  const downloadICS = (cita) => {
    const event = {
      start: new Date(`${cita.fecha}T${cita.hora_inicio}`),
      end: new Date(`${cita.fecha}T${cita.hora_fin}`),
      title: `Cita con ${cita.contacto_nombre || cita.contacto_email}`,
      description: `Cita con ${cita.contacto_nombre || cita.contacto_email}.\nEntrar: ${cita.jitsi_url}`,
      url: cita.jitsi_url,
      location: 'Online'
    };

    const reminderTime = new Date(event.start.getTime() - 10 * 60000); // 10 minutes before

    const icsContent = [
      'BEGIN:VCALENDAR',
      'VERSION:2.0',
      'BEGIN:VEVENT',
      `DTSTART:${event.start.toISOString().replace(/[-:]/g, '').split('.')[0]}Z`,
      `DTEND:${event.end.toISOString().replace(/[-:]/g, '').split('.')[0]}Z`,
      `SUMMARY:${event.title}`,
      `DESCRIPTION:${event.description}`,
      `URL:${event.url}`,
      `LOCATION:${event.location}`,
      'BEGIN:VALARM',
      'ACTION:DISPLAY',
      'DESCRIPTION:Reminder',
      `TRIGGER:-PT10M`,
      'END:VALARM',
      'END:VEVENT',
      'END:VCALENDAR'
    ].join('\n');

    const blob = new Blob([icsContent], { type: 'text/calendar;charset=utf-8' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `cita_${cita.id}.ics`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    fetchCitas();
    fetchServicios();
    fetchCalendarUrl();
    fetchFestivos();
    fetchProvincias();
  }, []);

  useEffect(() => {
    console.log('Provincias actualizadas:', provincias);
  }, [provincias]);

  const handleApiError = (error, errorMessage) => {
    console.error(errorMessage, error);
    if (error.response && error.response.status === 401) {
      // Redirect to login page or show authentication error
      setError('Sesión expirada. Por favor, inicie sesión nuevamente.');
    } else {
      setError(errorMessage + ': ' + (error.response?.data?.error || error.message));
    }
  };

  useEffect(() => {
    if (provincia) {
      fetchLocalidades(provincia);
    }
  }, [provincia]);

  // Eliminada la función fetchIcsUrl

  async function fetchFestivos() {
    try {
      const token = sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken');
      if (!token) {
        throw new Error('No se encontró el token de autenticación');
      }
      const response = await axios.get('/api/festivos', {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log('Respuesta de festivos:', response.data);
      if (Array.isArray(response.data)) {
        const festivosConTipo = response.data.map(festivo => ({
          ...festivo,
          tipo: festivo.es_nacional ? 'nacional' : (festivo.provincia ? 'local' : 'personalizado')
        }));
        setFestivos(festivosConTipo);
      } else {
        console.warn('Los datos de festivos no son un array:', response.data);
        setFestivos([]);
      }
    } catch (error) {
      handleApiError(error, 'Error al cargar festivos');
      setFestivos([]);
    }
  }


  async function fetchLocalidades(provincia) {
    try {
      const token = sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken');
      if (!token) {
        throw new Error('No se encontró el token de autenticación');
      }
      const response = await axios.get('/api/festivos/localidades', {
        headers: { Authorization: `Bearer ${token}` },
        params: { provincia }
      });
      console.log('Respuesta de localidades:', response.data);
      if (Array.isArray(response.data)) {
        setLocalidades(response.data.map(item => ({ value: item, label: item })));
      } else {
        console.warn('La respuesta de localidades no es un array:', response.data);
        setLocalidades([]);
      }
    } catch (error) {
      console.error('Error fetching localidades:', error);
      console.error('Error response:', error.response);
      setError('Error al cargar localidades: ' + (error.response?.data?.error || error.message));
      setLocalidades([]);
    }
  }

  async function fetchCitas() {
    try {
      const token = sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken');
      if (!token) {
        throw new Error('No se encontró el token de autenticación');
      }
      const response = await axios.get('/api/citas', {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log('Citas fetched:', response.data);
      if (Array.isArray(response.data) && response.data.length > 0) {
        const citasClasificadas = clasificarCitas(response.data);
        setCitas(citasClasificadas);
        setError('');
      } else {
        console.warn('No se encontraron citas o los datos no son un array');
        setCitas({ pendientes: [], pasadas: [], canceladas: [] });
        setError('No se encontraron citas');
      }
    } catch (error) {
      setError('Error al cargar citas: ' + (error.response?.data?.error || error.message));
      console.error('Error fetching citas:', error.response?.data || error);
    }
  }

  console.log('Citas state after fetching:', citas);

  function clasificarCitas(citas) {
    const ahora = new Date();
    return citas.reduce((acc, cita) => {
      const fechaCita = new Date(`${cita.fecha}T${cita.hora_fin}`);
      if (cita.estado === 'cancelada') {
        acc.canceladas.push(cita);
      } else if (fechaCita < ahora) {
        acc.pasadas.push(cita);
      } else {
        acc.pendientes.push(cita);
      }
      return acc;
    }, { pendientes: [], pasadas: [], canceladas: [] });
  }

  async function fetchCalendarUrl() {
    try {
      const token = sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken');
      if (!token) {
        console.log('Usuario no autenticado, no se puede obtener el ID del calendario');
        return;
      }
      const { data, error } = await supabase
        .from('usuarios')
        .select('calendar_id');
      
      if (error) throw error;
      
      if (data && data.length > 0) {
        // Assuming we want the first calendar_id if multiple are returned
        setCalendarUrl(data[0].calendar_id);
      } else {
        console.log('No se encontró el ID del calendario para el usuario');
      }
    } catch (error) {
      console.error('Error al obtener el ID del calendario:', error);
    }
  }


  async function fetchCalendarEvents() {
    if (!calendarUrl) return;
    try {
      const response = await axios.get(calendarUrl);
      const jcalData = ical.parse(response.data);
      const comp = new ical.Component(jcalData);
      const vevents = comp.getAllSubcomponents('vevent');
      const events = vevents.map(vevent => {
        const event = new ical.Event(vevent);
        return {
          id: event.uid,
          title: event.summary,
          start: event.startDate.toJSDate(),
          end: event.endDate.toJSDate(),
        };
      });
      setCitas(events);
    } catch (error) {
      console.error('Error fetching calendar events:', error);
      setError('Error al obtener eventos del calendario: ' + error.message);
    }
  }

  async function fetchServicios() {
    try {
      const token = sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken');
      if (!token) {
        throw new Error('No se encontró el token de autenticación');
      }
      const response = await axios.get('/api/servicios', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setServicios(response.data);
    } catch (error) {
      console.error('Error fetching servicios:', error);
      setError('Error al cargar servicios: ' + error.message);
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCita({ ...newCita, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken');
      if (!token) {
        throw new Error('No se encontró el token de autenticación');
      }
      
      // Verificar si la fecha es un festivo
      const esFestivo = festivos.some(festivo => festivo.fecha === newCita.fecha);
      if (esFestivo) {
        throw new Error('No se pueden crear citas en días festivos');
      }

      const citaData = {
        fecha: newCita.fecha,
        hora_inicio: newCita.hora_inicio,
        hora_fin: newCita.hora_fin,
        servicio_id: newCita.servicio_id || 1,
        contacto_nombre: newCita.contacto_nombre,
        contacto_email: newCita.contacto_email
      };

      if (newCita.contacto_telefono) {
        citaData.contacto_telefono = newCita.contacto_telefono;
      }

      const response = await axios.post('/api/citas', citaData, {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      console.log('Cita created:', response.data);
      if (response.data && response.data.cita) {
        setCitas(prevCitas => ({
          ...prevCitas,
          pendientes: [...prevCitas.pendientes, response.data.cita]
        }));
        setNewCita({ fecha: '', hora_inicio: '', hora_fin: '', servicio_id: '', contacto_nombre: '', contacto_email: '', contacto_telefono: '' });
        setError('');
        setIsModalOpen(false); // Cerrar el modal después de crear la cita

        // Enviar notificación por correo electrónico
        try {
          await sendCitaNotification(response.data.cita, 'nueva');
          console.log('Notificación de nueva cita enviada');
        } catch (emailError) {
          console.error('Error al enviar la notificación de la cita:', emailError);
          // Aquí puedes decidir si quieres mostrar un mensaje al usuario o manejarlo de otra manera
        }
      } else {
        throw new Error('La respuesta del servidor no contiene los datos esperados de la cita');
      }
    } catch (error) {
      let errorMessage = 'Error al crear cita: ';
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage += error.response.data.error;
      } else if (error.message) {
        errorMessage += error.message;
      } else {
        errorMessage += 'Se produjo un error desconocido';
      }
      setError(errorMessage);
      console.error('Error creating cita:', error.response?.data || error);
      
      // Mostrar el mensaje de error en una alerta
      alert(errorMessage);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/api/citas/${id}`);
      setCitas(prevCitas => ({
        ...prevCitas,
        pendientes: prevCitas.pendientes.filter(cita => cita.id !== id),
        pasadas: prevCitas.pasadas.filter(cita => cita.id !== id),
        canceladas: prevCitas.canceladas.filter(cita => cita.id !== id)
      }));
      setError('');
    } catch (error) {
      setError('Error al eliminar cita: ' + (error.response?.data?.error || error.message));
      console.error('Error deleting cita:', error);
    }
  };

  const handleCancel = async (id) => {
    try {
      const token = sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken');
      if (!token) {
        throw new Error('No se encontró el token de autenticación');
      }
      console.log('Cancelando cita con ID:', id);
      const response = await axios.put(`/api/citas/${id}/cancel`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log('Respuesta de cancelación:', response);
      if (response.status === 200 && response.data.cita) {
        // Actualizar el estado local de las citas
        setCitas(prevCitas => {
          const updatedCitas = { ...prevCitas };
          const citaToCancel = updatedCitas.pendientes.find(cita => cita.id === id);
          if (citaToCancel) {
            updatedCitas.pendientes = updatedCitas.pendientes.filter(cita => cita.id !== id);
            updatedCitas.canceladas = [...updatedCitas.canceladas, response.data.cita];
          }
          return updatedCitas;
        });
        setError('');
        console.log('Cita cancelada con éxito');
      } else {
        throw new Error('La respuesta del servidor no contiene la información esperada');
      }
    } catch (error) {
      console.error('Error al cancelar cita:', error);
      let errorMessage = 'Error al cancelar cita: ';
      if (error.response && error.response.data) {
        console.error('Datos de respuesta de error:', error.response.data);
        errorMessage += error.response.data.details || error.response.data.error || error.response.statusText;
      } else if (error.request) {
        errorMessage += 'No se recibió respuesta del servidor';
      } else {
        errorMessage += error.message;
      }
      setError(errorMessage);
    }
  };

  const handleDeleteCancelled = async () => {
    if (!window.confirm('¿Está seguro de que desea eliminar todas las citas canceladas? Esta acción no se puede deshacer.')) {
      return;
    }
    try {
      const token = sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken');
      if (!token) {
        throw new Error('No se encontró el token de autenticación');
      }
      const response = await axios.delete('/api/citas/canceladas', {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (response.status === 200) {
        setCitas(prevCitas => ({
          ...prevCitas,
          canceladas: []
        }));
        setError('');
        console.log('Citas canceladas eliminadas con éxito');
      } else {
        throw new Error('Error al eliminar las citas canceladas');
      }
    } catch (error) {
      console.error('Error al eliminar las citas canceladas:', error);
      let errorMessage = 'Error al eliminar las citas canceladas: ';
      if (error.response && error.response.data) {
        errorMessage += error.response.data.error || error.response.statusText;
      } else if (error.request) {
        errorMessage += 'No se recibió respuesta del servidor';
      } else {
        errorMessage += error.message;
      }
      setError(errorMessage);
    }
  };

  const handleDeleteSingleCancelled = async (id) => {
    if (!window.confirm('¿Está seguro de que desea eliminar esta cita cancelada? Esta acción no se puede deshacer.')) {
      return;
    }
    try {
      const token = sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken');
      if (!token) {
        throw new Error('No se encontró el token de autenticación');
      }
      const response = await axios.delete(`/api/citas/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (response.status === 200) {
        setCitas(prevCitas => ({
          ...prevCitas,
          canceladas: prevCitas.canceladas.filter(cita => cita.id !== id)
        }));
        setError('');
        console.log('Cita cancelada eliminada con éxito');
      } else {
        throw new Error('Error al eliminar la cita cancelada');
      }
    } catch (error) {
      console.error('Error al eliminar la cita cancelada:', error);
      let errorMessage = 'Error al eliminar la cita cancelada: ';
      if (error.response && error.response.data) {
        errorMessage += error.response.data.error || error.response.statusText;
      } else if (error.request) {
        errorMessage += 'No se recibió respuesta del servidor';
      } else {
        errorMessage += error.message;
      }
      setError(errorMessage);
    }
  };

  const handleReschedule = async (id, newDate, newStartTime, newEndTime) => {
    try {
      const token = sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken');
      if (!token) {
        throw new Error('No se encontró el token de autenticación');
      }

      // Verificar que la nueva fecha no sea anterior a la actual
      const currentDate = new Date();
      const newAppointmentDate = new Date(`${newDate}T${newStartTime}`);
      if (newAppointmentDate < currentDate) {
        throw new Error('No se puede reprogramar una cita para una fecha y hora anterior a la actual');
      }

      const response = await axios.put(`/api/citas/${id}`, {
        fecha: newDate,
        hora_inicio: newStartTime,
        hora_fin: newEndTime
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });

      if (response.status === 200) {
        fetchCitas(); // Actualizar la lista de citas
        setError('');
        alert('Cita reprogramada con éxito');
      } else {
        throw new Error('Error al reprogramar la cita');
      }
    } catch (error) {
      console.error('Error rescheduling cita:', error);
      let errorMessage = 'Error al reprogramar cita: ';
      if (error.response) {
        if (error.response.status === 400) {
          errorMessage += error.response.data.error;
        } else if (error.response.status === 500) {
          if (error.response.data?.error?.includes('Error de configuración del calendario')) {
            errorMessage = error.response.data.error + '. Por favor, contacte al administrador del sistema.';
          } else {
            errorMessage += 'Error interno del servidor. Por favor, inténtelo de nuevo más tarde o contacte al soporte técnico.';
          }
        } else {
          errorMessage += error.response.data?.message || error.response.data?.error || error.response.statusText;
        }
        console.error('Error response:', error.response);
      } else if (error.request) {
        errorMessage += 'No se recibió respuesta del servidor. Por favor, verifique su conexión a internet.';
        console.error('Error request:', error.request);
      } else {
        errorMessage += error.message;
      }
      setError(errorMessage);
      alert(errorMessage);
    }
  };

  const calendarEvents = React.useMemo(() => {
    const citasEvents = Object.values(citas)
      .flat()
      .filter(cita => cita.estado !== 'cancelada') // Filtrar citas canceladas
      .map(cita => ({
        id: cita.id,
        title: cita.contacto_nombre || 'Sin nombre',
        start: dayjs(`${cita.fecha} ${cita.hora_inicio}`).toDate(),
        end: dayjs(`${cita.fecha} ${cita.hora_fin}`).toDate(),
        cita: cita,
        color: '#3174ad', // Color azul para las citas
      }));

    const festivosEvents = Array.isArray(festivos) ? festivos
      .filter(festivo => 
        (festivosFilter.nacionales && festivo.tipo === 'nacional') ||
        (festivosFilter.locales && festivo.tipo === 'local') ||
        (festivosFilter.personalizados && festivo.tipo === 'personalizado')
      )
      .map(festivo => ({
        id: `festivo-${festivo.fecha}`,
        title: festivo.descripcion || 'Festivo',
        start: dayjs(festivo.fecha).toDate(),
        end: dayjs(festivo.fecha).add(1, 'day').toDate(),
        allDay: true,
        color: festivo.tipo === 'nacional' ? '#ff0000' : (festivo.tipo === 'local' ? '#ffa500' : '#008000'),
        tipo: festivo.tipo
      })) : [];

    return [...citasEvents, ...festivosEvents];
  }, [citas, festivos, festivosFilter]);

  const handleEventClick = (event) => {
    if (event.cita) {
      setSelectedEvent(event);
    } else {
      // Si es un festivo, mostrar una alerta con la información
      alert(`Festivo: ${event.title}\nFecha: ${event.start.toLocaleDateString()}`);
    }
  };

  const handleSendReminder = async (cita) => {
    try {
      const response = await axios.post(`/api/citas/${cita.id}/recordatorio`, {}, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken')}` }
      });
      if (response.status === 200) {
        alert('Recordatorio enviado con éxito');
      }
    } catch (error) {
      console.error('Error al enviar recordatorio:', error);
      alert('Error al enviar recordatorio');
    }
  };

  const EventModal = ({ event, onClose, isOpen }) => {
    const [isRescheduling, setIsRescheduling] = useState(false);
    const [newDate, setNewDate] = useState('');
    const [newStartTime, setNewStartTime] = useState('');
    const [newEndTime, setNewEndTime] = useState('');

    useEffect(() => {
      if (event) {
        setNewDate(event.cita.fecha);
        setNewStartTime(event.cita.hora_inicio);
        setNewEndTime(event.cita.hora_fin);
      }
    }, [event]);

    const handleRescheduleSubmit = () => {
      handleReschedule(event.cita.id, newDate, newStartTime, newEndTime);
      setIsRescheduling(false);
      onClose();
    };

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        className="modal"
        overlayClassName="overlay"
      >
        {event && (
          <div className="bg-white rounded-lg p-4 max-w-lg w-full mx-auto text-sm">
            <h2 className="text-2xl font-bold mb-4 text-gray-800 border-b pb-2">Detalles de la Cita</h2>
            <div className="grid grid-cols-2 gap-3 mb-4">
              <div>
                <p className="font-semibold text-gray-600">Nombre</p>
                <p>{event.cita.contacto_nombre}</p>
              </div>
              <div>
                <p className="font-semibold text-gray-600">Email</p>
                <p>{event.cita.contacto_email}</p>
              </div>
              <div>
                <p className="font-semibold text-gray-600">Teléfono</p>
                <p>{event.cita.contacto_telefono || 'No disponible'}</p>
              </div>
              <div>
                <p className="font-semibold text-gray-600">Fecha</p>
                <p>{new Date(event.cita.fecha).toLocaleDateString()}</p>
              </div>
              <div>
                <p className="font-semibold text-gray-600">Hora de inicio</p>
                <p>{event.cita.hora_inicio}</p>
              </div>
              <div>
                <p className="font-semibold text-gray-600">Hora de fin</p>
                <p>{event.cita.hora_fin}</p>
              </div>
            </div>
            <div className="mb-4">
              <p className="font-semibold text-gray-600">Servicio</p>
              <p>{servicios.find(s => s.id === event.cita.servicio_id)?.nombre || 'Sin servicio asociado'}</p>
            </div>
            <div className="mb-4">
              <p className="font-semibold text-gray-600">Videollamada</p>
              <a href={event.cita.jitsi_url} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline" title="Enlace JITSI">
                <FaVideo className="inline mr-1" />
                Iniciar videollamada
              </a>
            </div>
            {isRescheduling ? (
              <div className="mb-4">
                <h3 className="text-lg font-semibold mb-2">Reprogramar Cita</h3>
                <div className="grid grid-cols-2 gap-3">
                  <div>
                    <label className="block text-xs font-medium text-gray-700">Nueva Fecha</label>
                    <input
                      type="date"
                      value={newDate}
                      onChange={(e) => setNewDate(e.target.value)}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm text-xs"
                    />
                  </div>
                  <div>
                    <label className="block text-xs font-medium text-gray-700">Nueva Hora de Inicio</label>
                    <input
                      type="time"
                      value={newStartTime}
                      onChange={(e) => setNewStartTime(e.target.value)}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm text-xs"
                    />
                  </div>
                  <div>
                    <label className="block text-xs font-medium text-gray-700">Nueva Hora de Fin</label>
                    <input
                      type="time"
                      value={newEndTime}
                      onChange={(e) => setNewEndTime(e.target.value)}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm text-xs"
                    />
                  </div>
                </div>
                <div className="mt-3 flex justify-end space-x-2">
                  <button
                    onClick={() => setIsRescheduling(false)}
                    className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-1 px-2 rounded text-xs"
                  >
                    Cancelar
                  </button>
                  <button
                    onClick={handleRescheduleSubmit}
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded text-xs"
                  >
                    Confirmar
                  </button>
                </div>
              </div>
            ) : (
              <div className="flex flex-wrap justify-between items-center mt-4 gap-2">
                <button
                  onClick={() => downloadICS(event.cita)}
                  className="bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-2 rounded transition duration-300 ease-in-out flex items-center text-xs"
                >
                  <FaDownload className="mr-1" />
                  Descargar ICS
                </button>
                <button
                  onClick={() => setIsRescheduling(true)}
                  className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded transition duration-300 ease-in-out flex items-center text-xs"
                >
                  <FaCalendarAlt className="mr-1" />
                  Reprogramar
                </button>
                <button
                  onClick={() => {
                    handleCancel(event.cita.id);
                    onClose();
                  }}
                  className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-1 px-2 rounded transition duration-300 ease-in-out flex items-center text-xs"
                >
                  <FaBan className="mr-1" />
                  Cancelar Cita
                </button>
                <button
                  onClick={() => {
                    handleDelete(event.cita.id);
                    onClose();
                  }}
                  className="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-2 rounded transition duration-300 ease-in-out flex items-center text-xs"
                >
                  <FaTrash className="mr-1" />
                  Eliminar
                </button>
                <button
                  onClick={() => handleSendReminder(event.cita)}
                  className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-1 px-2 rounded transition duration-300 ease-in-out flex items-center text-xs"
                >
                  <FaClock className="mr-1" />
                  Enviar recordatorio
                </button>
              </div>
            )}
            <button onClick={onClose} className="mt-4 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded w-full transition duration-300 ease-in-out text-xs">
              Cerrar
            </button>
          </div>
        )}
      </Modal>
    );
  };

  console.log('Festivos:', festivos);
  console.log('Calendar Events:', calendarEvents);

  const handleIcsUrlChange = async (e) => {
    e.preventDefault();
    try {
      const token = sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken');
      await axios.put('/api/festivos/ics-url', { ics_url: icsUrl }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      alert('URL del ICS actualizada con éxito');
    } catch (error) {
      console.error('Error updating ICS URL:', error);
      setError('Error al actualizar la URL del ICS');
    }
  };

  const handleAddCustomFestivo = async (e) => {
    e.preventDefault();
    const fecha = e.target.fecha.value;
    const descripcion = e.target.descripcion.value;
    try {
      const token = sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken');
      if (!token) {
        throw new Error('No se encontró el token de autenticación');
      }
      const response = await axios.post('/api/festivos', { fecha, descripcion }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (response.status === 201) {
        alert('Festivo personalizado añadido con éxito');
        fetchFestivos();
        e.target.reset(); // Reset the form after successful submission
      } else {
        throw new Error('Error al añadir festivo personalizado');
      }
    } catch (error) {
      console.error('Error adding custom festivo:', error);
      setError(error.response?.data?.error || 'Error al añadir festivo personalizado');
    }
  };




  const AddCitaModal = () => (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={() => setIsModalOpen(false)}
      className="modal"
      overlayClassName="overlay"
    >
      <h2 className="text-2xl font-bold mb-4">Agregar Nueva Cita</h2>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <input
            type="date"
            name="fecha"
            value={newCita.fecha}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
            required
          />
          <input
            type="time"
            name="hora_inicio"
            value={newCita.hora_inicio}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
            required
          />
          <input
            type="time"
            name="hora_fin"
            value={newCita.hora_fin}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
            required
          />
          <select
            name="servicio_id"
            value={newCita.servicio_id}
            onChange={handleInputChange}
            className="w-full p-2 border rounded"
          >
            <option value={null}>Sin servicio asociado</option>
            {servicios.map(servicio => (
              <option key={servicio.id} value={servicio.id}>{servicio.nombre}</option>
            ))}
          </select>
          <input
            type="text"
            name="contacto_nombre"
            value={newCita.contacto_nombre}
            onChange={handleInputChange}
            placeholder="Nombre del contacto"
            className="w-full p-2 border rounded"
            required
          />
          <input
            type="email"
            name="contacto_email"
            value={newCita.contacto_email}
            onChange={handleInputChange}
            placeholder="Email de contacto"
            className="w-full p-2 border rounded"
            required
          />
          <input
            type="tel"
            name="contacto_telefono"
            value={newCita.contacto_telefono}
            onChange={handleInputChange}
            placeholder="Teléfono de contacto (opcional)"
            className="w-full p-2 border rounded"
          />
        </div>
        <div className="mt-4 flex justify-end">
          <button
            type="button"
            onClick={() => setIsModalOpen(false)}
            className="mr-2 px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
          >
            Cancelar
          </button>
          <button
            type="submit"
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Agregar Cita
          </button>
        </div>
      </form>
    </Modal>
  );

  const importarFestivos = async () => {
    try {
      const response = await axios.post('/api/cron/importar-festivos-usuario');
      alert(response.data.message);
      fetchCitas();
    } catch (error) {
      setError('Error al importar festivos: ' + error.response?.data?.error || error.message);
    }
  };

  return (
    <div className="container mx-auto px-4">
      <h2 className="text-2xl font-bold mb-4">Citas</h2>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      
      <div className="mb-4 flex space-x-1">
        <button
          onClick={() => setViewMode('list')}
          title="Lista"
          className={`p-1 rounded-full transition-colors duration-200 ${viewMode === 'list' ? 'bg-blue-100 text-blue-800' : 'bg-gray-100 text-gray-600 hover:bg-gray-200'}`}
        >
          <FaList className="w-4 h-4" />
        </button>
        <button
          onClick={() => setViewMode('calendar')}
          title="Calendario"
          className={`p-1 rounded-full transition-colors duration-200 ${viewMode === 'calendar' ? 'bg-blue-100 text-blue-800' : 'bg-gray-100 text-gray-600 hover:bg-gray-200'}`}
        >
          <FaCalendarAlt className="w-4 h-4" />
        </button>
        <button
          onClick={() => setShowAddCitaMenu(!showAddCitaMenu)}
          title="Agregar Cita"
          className="bg-white text-green-600 hover:bg-green-50 border border-green-300 p-1 rounded-full transition-colors duration-200"
        >
          <FaPlus className="w-4 h-4" />
        </button>
        <button
          onClick={() => setShowFestivosMenu(!showFestivosMenu)}
          title="Gestión de Festivos"
          className="bg-white text-blue-600 hover:bg-blue-50 border border-blue-300 p-1 rounded-full transition-colors duration-200"
        >
          <FaCalendarCheck className="w-4 h-4" />
        </button>
      </div>

      {showFestivosMenu && (
        <div className="bg-white shadow-lg rounded-lg p-6 mb-8">
          <h3 className="text-xl font-semibold mb-4 text-gray-800 flex items-center">
            <FaCalendarCheck className="mr-2 text-red-500" /> Gestión de Festivos
          </h3>
          
          {/* Widget para seleccionar Provincia y Localidad */}
          <div className="mb-6">
            <h4 className="font-semibold mb-2 text-gray-700">Festivos por Provincia</h4>
            {error && <p className="text-red-500 mb-2">{error}</p>}
            <Select
              options={provincias}
              onChange={handleProvinciaChange}
              value={selectedProvincia}
              placeholder="Selecciona una Provincia"
              className="mb-2"
              isLoading={provincias.length === 0}
              noOptionsMessage={() => "No hay provincias disponibles"}
            />
            <Select
              options={localidades}
              onChange={handleLocalidadChange}
              value={selectedLocalidad}
              placeholder="Selecciona una Localidad"
              isDisabled={!selectedProvincia}
              className="mb-2"
            />
            <div className="mt-4 mb-4 border-t border-gray-200 pt-4">
              <h5 className="font-semibold mb-2 text-gray-700">Días festivos a importar:</h5>
              {festivosAImportar.length > 0 ? (
                <ul className="bg-gray-100 rounded-lg p-4 max-h-40 overflow-y-auto">
                  {festivosAImportar.map((festivo, index) => (
                    <li key={index} className="mb-2 p-2 bg-white rounded shadow flex justify-between items-center">
                      <span>{festivo.fecha} - {festivo.descripcion}</span>
                      <button
                        onClick={() => handleRemoveFestivoAImportar(index)}
                        className="ml-2 px-2 py-1 rounded bg-red-500 text-white hover:bg-red-600"
                      >
                        Eliminar
                      </button>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="text-gray-500">No hay festivos para importar.</p>
              )}
            </div>
            <button
              onClick={handleImportFestivosProvinciales}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300 w-full"
              disabled={!selectedProvincia || isImportingFestivos || festivosAImportar.length === 0}
            >
              {isImportingFestivos ? 'Importando...' : 'Importar festivos seleccionados'}
            </button>
          </div>
          
          {/* Widget para añadir días personalizados */}
          <div className="mb-6">
            <h4 className="font-semibold mb-2 text-gray-700">Añadir Días Personalizados</h4>
            <form onSubmit={handleAddCustomFestivo} className="space-y-2">
              <input
                type="date"
                name="fecha"
                required
                className="w-full bg-gray-100 p-2 rounded border border-gray-300"
              />
              <input
                type="text"
                name="descripcion"
                placeholder="Descripción (ej. Vacaciones, Día no operativo)"
                className="w-full bg-gray-100 p-2 rounded border border-gray-300"
              />
              <button
                type="submit"
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-300 w-full"
              >
                Añadir Día Personalizado
              </button>
            </form>
          </div>
          
          {/* Lista de festivos */}
          <div>
            <h4 className="font-semibold mb-2 text-gray-700">Festivos:</h4>
            <div className="flex items-center space-x-2 mb-4">
              <FaFilter className="text-gray-600" />
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  checked={festivosFilter.nacionales}
                  onChange={() => setFestivosFilter(prev => ({ ...prev, nacionales: !prev.nacionales }))}
                  className="form-checkbox h-5 w-5 text-blue-600"
                />
                <span className="ml-2 text-gray-700">Nacionales</span>
              </label>
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  checked={festivosFilter.locales}
                  onChange={() => setFestivosFilter(prev => ({ ...prev, locales: !prev.locales }))}
                  className="form-checkbox h-5 w-5 text-orange-600"
                />
                <span className="ml-2 text-gray-700">Locales</span>
              </label>
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  checked={festivosFilter.personalizados}
                  onChange={() => setFestivosFilter(prev => ({ ...prev, personalizados: !prev.personalizados }))}
                  className="form-checkbox h-5 w-5 text-green-600"
                />
                <span className="ml-2 text-gray-700">Personalizados</span>
              </label>
            </div>
            {festivos.length > 0 ? (
              <>
                <ul className="bg-gray-100 rounded-lg p-4">
                  {festivos.filter(festivo => 
                    (festivosFilter.nacionales && festivo.tipo === 'nacional') ||
                    (festivosFilter.locales && festivo.tipo === 'local') ||
                    (festivosFilter.personalizados && festivo.tipo === 'personalizado')
                  ).map((festivo, index) => (
                    <li key={index} className="mb-2 p-2 bg-white rounded shadow flex items-center">
                      <input
                        type="checkbox"
                        checked={selectedFestivos.includes(festivo.id)}
                        onChange={() => {
                          if (selectedFestivos.includes(festivo.id)) {
                            setSelectedFestivos(selectedFestivos.filter(id => id !== festivo.id));
                          } else {
                            setSelectedFestivos([...selectedFestivos, festivo.id]);
                          }
                        }}
                        className="mr-2"
                      />
                      <span className="font-medium">{festivo.fecha}</span> - {festivo.descripcion} 
                      {festivo.tipo ? `(${festivo.tipo})` : '👤'}
                    </li>
                  ))}
                </ul>
                <div className="mt-4 flex justify-between items-center">
                  <span>{selectedFestivos.length} festivos seleccionados</span>
                  <button
                    onClick={() => {
                      if (window.confirm(`¿Estás seguro de que quieres eliminar ${selectedFestivos.length} festivos?`)) {
                        Promise.all(selectedFestivos.map(id => handleDeleteFestivo(id)))
                          .then(() => {
                            setSelectedFestivos([]);
                            fetchFestivos();
                          })
                          .catch(error => {
                            console.error('Error al eliminar festivos:', error);
                            setError('Error al eliminar festivos');
                          });
                      }
                    }}
                    className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded flex items-center"
                    disabled={selectedFestivos.length === 0}
                  >
                    <FaTrash className="mr-2" /> Eliminar seleccionados
                  </button>
                </div>
              </>
            ) : (
              <p className="text-gray-500">No hay festivos importados.</p>
            )}
          </div>
        </div>
      )}
      {showAddCitaMenu && (
        <div className="bg-white shadow-lg rounded-lg p-6 mb-8">
          <h3 className="text-xl font-semibold mb-4 text-gray-800 flex items-center">
            <FaPlus className="mr-2 text-green-500" /> Agregar Nueva Cita
          </h3>
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="mb-4">
                <label htmlFor="fecha" className="block text-sm font-medium text-gray-700 mb-1">Fecha</label>
                <input
                  type="date"
                  id="fecha"
                  name="fecha"
                  value={newCita.fecha}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="hora_inicio" className="block text-sm font-medium text-gray-700 mb-1">Hora de inicio</label>
                <input
                  type="time"
                  id="hora_inicio"
                  name="hora_inicio"
                  value={newCita.hora_inicio}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="hora_fin" className="block text-sm font-medium text-gray-700 mb-1">Hora de fin</label>
                <input
                  type="time"
                  id="hora_fin"
                  name="hora_fin"
                  value={newCita.hora_fin}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="servicio_id" className="block text-sm font-medium text-gray-700 mb-1">Servicio</label>
                <select
                  id="servicio_id"
                  name="servicio_id"
                  value={newCita.servicio_id}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                >
                  <option value="">Sin servicio asociado</option>
                  {servicios.map(servicio => (
                    <option key={servicio.id} value={servicio.id}>{servicio.nombre}</option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
                <label htmlFor="contacto_nombre" className="block text-sm font-medium text-gray-700 mb-1">Nombre del contacto</label>
                <input
                  type="text"
                  id="contacto_nombre"
                  name="contacto_nombre"
                  value={newCita.contacto_nombre}
                  onChange={handleInputChange}
                  placeholder="Nombre del contacto"
                  className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="contacto_email" className="block text-sm font-medium text-gray-700 mb-1">Email de contacto</label>
                <input
                  type="email"
                  id="contacto_email"
                  name="contacto_email"
                  value={newCita.contacto_email}
                  onChange={handleInputChange}
                  placeholder="Email de contacto"
                  className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="contacto_telefono" className="block text-sm font-medium text-gray-700 mb-1">Teléfono de contacto</label>
                <input
                  type="tel"
                  id="contacto_telefono"
                  name="contacto_telefono"
                  value={newCita.contacto_telefono}
                  onChange={handleInputChange}
                  placeholder="Teléfono de contacto (opcional)"
                  className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
            </div>
            <div className="mt-4 flex justify-end">
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Agregar Cita
              </button>
            </div>
          </form>
        </div>
      )}
      <div className={`${viewMode === 'list' ? '' : 'hidden'}`}>
        <div className="mb-4 flex flex-col md:flex-row justify-between items-center">
          <h2 className="text-2xl md:text-3xl font-bold text-indigo-700 mb-2 md:mb-0">Citas</h2>
          <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 w-full md:w-auto">
            <select
              onChange={(e) => setFiltroTiempo(e.target.value)}
              className="border rounded p-2 bg-indigo-100 text-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 w-full md:w-auto"
            >
              <option value="todos">Todos</option>
              <option value="dia">Hoy</option>
              <option value="semana">Esta semana</option>
              <option value="mes">Este mes</option>
            </select>
            <button
              onClick={handleDeleteCancelled}
              className="text-gray-500 hover:text-red-600 transition-colors duration-300 p-2 border rounded w-full md:w-auto flex justify-center items-center"
              title="Eliminar citas canceladas"
            >
              <FaBroom className="w-5 h-5 mr-2" />
              {!isMobile && "Eliminar canceladas"}
            </button>
          </div>
        </div>
        {['pendientes', 'pasadas', 'canceladas'].map((categoria) => (
          <div key={categoria} className="mb-8">
            <h3 className="text-xl md:text-2xl font-bold mb-4 capitalize text-indigo-600">{categoria}</h3>
            <div className="overflow-x-auto shadow-lg rounded-lg">
              {isMobile ? (
                <div className="bg-white">
                  {filtrarCitasPorTiempo(Array.isArray(citas[categoria]) ? citas[categoria] : []).map(cita => (
                    <div key={cita.id} className="border-b border-indigo-200 p-4">
                      <div className="flex justify-between items-center mb-2">
                        <div className="font-semibold">{cita.contacto_nombre || 'No disponible'}</div>
                        <div className="text-sm text-indigo-600">
                          {cita.fecha ? new Date(cita.fecha).toLocaleDateString() : 'N/A'}
                        </div>
                      </div>
                      <div className="text-sm mb-2">
                        {cita.hora_inicio ? cita.hora_inicio.substring(0, 5) : 'N/A'} - {cita.hora_fin ? cita.hora_fin.substring(0, 5) : 'N/A'}
                      </div>
                      <div className="text-sm text-indigo-600 mb-2">{cita.contacto_email || 'No disponible'}</div>
                      <div className="text-sm text-indigo-600 mb-2">{cita.contacto_telefono || 'No disponible'}</div>
                      <div className="text-sm mb-2">
                        {servicios.find(s => s.id === cita.servicio_id)?.nombre || 'Sin servicio asociado'}
                      </div>
                      <div className="flex justify-between items-center mt-2">
                        <a href={cita.jitsi_url} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700 transition duration-150" title="Enlace JITSI">
                          <FaVideo className="w-5 h-5" />
                        </a>
                        {categoria === 'pendientes' && (
                          <>
                            <button onClick={() => downloadICS(cita)} title="Descargar ICS" className="text-green-500 hover:text-green-700 transition duration-150">
                              <FaDownload className="w-5 h-5" />
                            </button>
                            <button onClick={() => { setSelectedEvent({ cita }); setIsModalOpen(true); }} title="Reprogramar" className="text-blue-500 hover:text-blue-700 transition duration-150">
                              <FaCalendarAlt className="w-5 h-5" />
                            </button>
                            <button onClick={() => handleCancel(cita.id)} title="Cancelar" className="text-yellow-500 hover:text-yellow-700 transition duration-150">
                              <FaBan className="w-5 h-5" />
                            </button>
                          </>
                        )}
                        {categoria === 'canceladas' && (
                          <button onClick={() => handleDeleteSingleCancelled(cita.id)} title="Eliminar" className="text-red-500 hover:text-red-700 transition duration-150">
                            <FaTrash className="w-5 h-5" />
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <table className="min-w-full bg-white">
                  <thead className="bg-indigo-500 text-white">
                    <tr>
                      <th className="py-3 px-4 text-left">Fecha</th>
                      <th className="py-3 px-4 text-left">Horario</th>
                      <th className="py-3 px-4 text-left">Contacto</th>
                      <th className="py-3 px-4 text-left">Servicio</th>
                      <th className="py-3 px-4 text-left">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filtrarCitasPorTiempo(Array.isArray(citas[categoria]) ? citas[categoria] : []).map(cita => (
                      <tr key={cita.id} className="hover:bg-indigo-50 transition duration-150">
                        <td className="py-3 px-4 border-b border-indigo-200">
                          {cita.fecha ? new Date(cita.fecha).toLocaleDateString() : 'N/A'}
                        </td>
                        <td className="py-3 px-4 border-b border-indigo-200">
                          {cita.hora_inicio ? cita.hora_inicio.substring(0, 5) : 'N/A'} - {cita.hora_fin ? cita.hora_fin.substring(0, 5) : 'N/A'}
                        </td>
                        <td className="py-3 px-4 border-b border-indigo-200">
                          <div className="font-semibold">{cita.contacto_nombre || 'No disponible'}</div>
                          <div className="text-sm text-indigo-600">{cita.contacto_email || 'No disponible'}</div>
                          <div className="text-sm text-indigo-600">{cita.contacto_telefono || 'No disponible'}</div>
                        </td>
                        <td className="py-3 px-4 border-b border-indigo-200">
                          {servicios.find(s => s.id === cita.servicio_id)?.nombre || 'Sin servicio asociado'}
                        </td>
                        <td className="py-3 px-4 border-b border-indigo-200">
                          <div className="flex space-x-2">
                            <a href={cita.jitsi_url} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700 transition duration-150" title="Enlace JITSI">
                              <FaVideo className="w-5 h-5" />
                            </a>
                            {categoria === 'pendientes' && (
                              <>
                                <button onClick={() => downloadICS(cita)} title="Descargar ICS" className="text-green-500 hover:text-green-700 transition duration-150">
                                  <FaDownload className="w-5 h-5" />
                                </button>
                                <button onClick={() => { setSelectedEvent({ cita }); setIsModalOpen(true); }} title="Reprogramar" className="text-blue-500 hover:text-blue-700 transition duration-150">
                                  <FaCalendarAlt className="w-5 h-5" />
                                </button>
                                <button onClick={() => handleCancel(cita.id)} title="Cancelar" className="text-yellow-500 hover:text-yellow-700 transition duration-150">
                                  <FaBan className="w-5 h-5" />
                                </button>
                              </>
                            )}
                            {categoria === 'canceladas' && (
                              <button onClick={() => handleDeleteSingleCancelled(cita.id)} title="Eliminar" className="text-red-500 hover:text-red-700 transition duration-150">
                                <FaTrash className="w-5 h-5" />
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className={`${viewMode === 'calendar' ? '' : 'hidden'}`}>
        <div className="mb-4 flex justify-end space-x-4">
          <div className="flex items-center">
            <div className="w-4 h-4 bg-blue-500 mr-2"></div>
            <span>Citas</span>
          </div>
          <div className="flex items-center">
            <div className="w-4 h-4 bg-red-500 mr-2"></div>
            <span>Festivos Nacionales</span>
          </div>
          <div className="flex items-center">
            <div className="w-4 h-4 bg-[#FFA500] mr-2" style={{backgroundColor: '#FFA500'}}></div>
            <span>Festivos Locales</span>
          </div>
          <div className="flex items-center">
            <div className="w-4 h-4 bg-green-500 mr-2"></div>
            <span>Festivos Personalizados</span>
          </div>
        </div>
        <div className="h-[calc(100vh-250px)]">
          <Calendar
            localizer={localizer}
            events={calendarEvents}
            startAccessor="start"
            endAccessor="end"
            style={{ height: '100%' }}
            eventPropGetter={(event) => ({
              style: {
                backgroundColor: event.tipo ? event.color : '#3174ad',
              },
            })}
            onSelectEvent={handleEventClick}
            views={['month', 'week', 'day']}
            defaultView='month'
            className="react-big-calendar"
            messages={{
              next: "Siguiente",
              previous: "Anterior",
              today: "Hoy",
              month: "Mes",
              week: "Semana",
              day: "Día"
            }}
            formats={{
              dateFormat: 'D',
              dayFormat: (date, culture, localizer) =>
                localizer.format(date, 'ddd D', culture),
              monthHeaderFormat: (date, culture, localizer) =>
                localizer.format(date, 'MMMM YYYY', culture),
              timeGutterFormat: (date, culture, localizer) =>
                localizer.format(date, 'HH:mm', culture),
            }}
            components={{
              month: {
                dateHeader: ({ date, label }) => (
                  <span>{localizer.format(date, 'D', 'es')}</span>
                ),
              },
            }}
          />
        </div>
      </div>
      <AddCitaModal />
      <EventModal
        event={selectedEvent}
        onClose={() => setSelectedEvent(null)}
        isOpen={!!selectedEvent}
      />
    </div>
  );
}

export default Citas;
