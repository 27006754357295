import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
  Container, Typography, Button, TextField, List, ListItem, 
  ListItemText, Grid, IconButton, Box, CircularProgress,
  Dialog, DialogTitle, DialogContent, DialogActions, Card, CardContent,
  Snackbar, Alert, ListItemIcon, Paper, Divider
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import LinkIcon from '@mui/icons-material/Link';
import RefreshIcon from '@mui/icons-material/Refresh';
import DescriptionIcon from '@mui/icons-material/Description';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableChartIcon from '@mui/icons-material/TableChart';
import ImageIcon from '@mui/icons-material/Image';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import MDEditor from '@uiw/react-md-editor';
import { uploadDocument, createDocumentByText } from '../routes/uploadDocument.js';

const DIFY_HOST = process.env.REACT_APP_DIFY_HOST;
const DIFY_API_KEY = process.env.REACT_APP_DIFY_API_KEY;

if (!DIFY_HOST || !DIFY_API_KEY) {
  console.error('REACT_APP_DIFY_HOST o REACT_APP_DIFY_API_KEY no están definidos en las variables de entorno');
}

console.log('DIFY_HOST:', DIFY_HOST);
console.log('DIFY_API_KEY:', DIFY_API_KEY ? 'Definido' : 'No definido');

// Función para obtener el nombre del archivo sin usar 'path'
const getFileName = (filePath) => {
  const parts = filePath.split(/[\/\\]/);
  return parts[parts.length - 1];
};

function KnowledgeBase() {
  const [file, setFile] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [error, setError] = useState('');
  const [newDocumentName, setNewDocumentName] = useState('');
  const [newDocumentContent, setNewDocumentContent] = useState('');
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [datasetId, setDatasetId] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [shortToken, setShortToken] = useState(null);

  const handleFileChange = (event) => {
    setFiles(Array.from(event.target.files));
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleDocumentContentChange = (value) => {
    setNewDocumentContent(value);
  };

  useEffect(() => {
    const token = sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken');
    if (token) {
      setShortToken(token);
      connectDatastore(token);
    } else {
      setError('No se ha podido obtener el token del usuario');
    }
  }, []);

  const connectDatastore = async (token) => {
    if (!token) {
      setSnackbar({ open: true, message: 'No se ha podido obtener el token del usuario', severity: 'error' });
      return;
    }
    setLoading(true);
    try {
      if (!DIFY_HOST || !DIFY_API_KEY) {
        throw new Error('DIFY_HOST o DIFY_API_KEY no están definidos');
      }

      console.log('DIFY_HOST:', DIFY_HOST);
      console.log('DIFY_API_KEY (primeros 4 caracteres):', DIFY_API_KEY ? DIFY_API_KEY.substring(0, 4) : 'No disponible');

      let dataset;
      const response = await axios.get(DIFY_HOST + '/v1/datasets?page=1&limit=1000', {
        headers: {
          'Authorization': 'Bearer ' + DIFY_API_KEY
        }
      });
      dataset = response.data.data.find(dataset => dataset.name === token);
    
      if (!dataset) {
        const createResponse = await axios.post(DIFY_HOST + '/v1/datasets', 
          { name: token },
          {
            headers: {
              'Authorization': 'Bearer ' + DIFY_API_KEY,
              'Content-Type': 'application/json'
            }
          }
        );
        dataset = createResponse.data;
        setSnackbar({ open: true, message: 'Se ha creado un nuevo dataset para este usuario', severity: 'info' });
      }
    
      if (dataset) {
        setDatasetId(dataset.id);
        setIsConnected(true);
        setSnackbar({ open: true, message: 'Conexión exitosa con el datastore', severity: 'success' });
        console.log('Calling fetchDocuments with dataset id:', dataset.id);
        await fetchDocuments(dataset.id);
      } else {
        throw new Error('No se pudo obtener o crear el dataset');
      }
    } catch (err) {
      setSnackbar({ 
        open: true, 
        message: `Error al conectar con el datastore: ${err.response?.data?.message || err.message}`, 
        severity: 'error' 
      });
      console.error('Error completo:', err);
    } finally {
      setLoading(false);
    }
  };

  const createDocumentByText = async (documentName, documentText) => {
    if (!datasetId) {
      setSnackbar({ open: true, message: 'No se ha podido obtener el ID del dataset', severity: 'error' });
      return;
    }
    if (!DIFY_HOST || !DIFY_API_KEY) {
      setSnackbar({ open: true, message: 'DIFY_HOST o DIFY_API_KEY no están definidos', severity: 'error' });
      return;
    }
    try {
      const response = await axios.post(DIFY_HOST + '/v1/datasets/' + datasetId + '/document/create_by_text', {
        name: documentName,
        text: documentText,
        indexing_technique: "high_quality",
        process_rule: {
          rules: {
            pre_processing_rules: [
              { id: "remove_extra_spaces", enabled: true },
              { id: "remove_urls_emails", enabled: true }
            ],
            segmentation: {
              separator: "###",
              max_tokens: 500
            }
          },
          mode: "custom"
        }
      }, {
        headers: {
          'Authorization': `Bearer ${DIFY_API_KEY}`,
          'Content-Type': 'application/json'
        }
      });

      setSnackbar({ open: true, message: 'Documento creado con éxito', severity: 'success' });
      fetchDocuments(datasetId);
    } catch (err) {
      console.error(err);
      setSnackbar({ open: true, message: `Error al crear el documento: ${err.message}`, severity: 'error' });
    }
  };

  const fetchDocuments = async (id) => {
    if (!id) {
      setError('No se ha podido obtener el ID del dataset');
      return;
    }
    setLoading(true);
    try {
      if (!DIFY_HOST || !DIFY_API_KEY) {
        throw new Error('REACT_APP_DIFY_HOST o REACT_APP_DIFY_API_KEY no están definidos');
      }

      console.log('Fetching documents for dataset:', id);
      const response = await axios.get(DIFY_HOST + '/v1/datasets/' + id + '/documents', {
        headers: {
          'Authorization': 'Bearer ' + DIFY_API_KEY
        }
      });
      console.log('Documents response:', response.data);
      setDocuments(response.data.data || []);
      setError(''); // Clear any previous errors
    } catch (err) {
      console.error('Error fetching documents:', err);
      setSnackbar({ open: true, message: `Error al obtener los documentos: ${err.message}`, severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (timestamp) => {
    return new Date(timestamp * 1000).toLocaleString();
  };

  const createDocument = async (e) => {
    e.preventDefault();
    if (!datasetId) {
      setSnackbar({ open: true, message: 'No se ha podido obtener el ID del dataset', severity: 'error' });
      return;
    }
    try {
      await createDocumentByText(newDocumentName, newDocumentContent);
      setNewDocumentName('');
      setNewDocumentContent('');
      setSnackbar({ open: true, message: 'Documento creado con éxito', severity: 'success' });
      fetchDocuments(datasetId);
    } catch (err) {
      console.error(err);
      setSnackbar({ open: true, message: `Error al crear el documento: ${err.message}`, severity: 'error' });
    }
  };

  const createDocumentByFile = async (e) => {
    e.preventDefault();
    if (!datasetId) {
      setSnackbar({ open: true, message: 'No se ha podido obtener el ID del dataset', severity: 'error' });
      return;
    }
    if (!file) {
      setSnackbar({ open: true, message: 'Por favor, selecciona un archivo', severity: 'error' });
      return;
    }
    setLoading(true);
    try {
      const response = await uploadDocument(file, datasetId);
      console.log('Respuesta del servidor:', response);

      fetchDocuments(datasetId);
      setFile(null);
      setSnackbar({ open: true, message: 'Archivo subido con éxito', severity: 'success' });
    } catch (err) {
      console.error('Error completo:', err);
      let errorMessage = 'Error al subir el archivo';
      if (err.response && err.response.data && err.response.data.message) {
        errorMessage += `: ${err.response.data.message}`;
      } else if (err.message) {
        errorMessage += `: ${err.message}`;
      }
      setSnackbar({ open: true, message: errorMessage, severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const deleteDocument = async (documentId) => {
    try {
      await axios.delete(DIFY_HOST + '/v1/datasets/' + datasetId + '/documents/' + documentId, {
        headers: {
          'Authorization': 'Bearer ' + DIFY_API_KEY
        }
      });

      await fetchDocuments(datasetId);
      setSnackbar({ open: true, message: 'Documento eliminado con éxito', severity: 'success' });
    } catch (err) {
      setSnackbar({ open: true, message: 'Error al eliminar el documento', severity: 'error' });
      console.error(err);
    }
  };

  const getDocumentIcon = (dataSourceType) => {
    switch (dataSourceType.toLowerCase()) {
      case 'text':
      case 'markdown':
        return <DescriptionIcon />;
      case 'pdf':
        return <PictureAsPdfIcon />;
      case 'csv':
      case 'excel':
        return <TableChartIcon />;
      case 'image':
        return <ImageIcon />;
      default:
        return <DescriptionIcon />;
    }
  };

  return (
    <Container maxWidth="lg">
      <Box my={4}>
        <Typography variant="h4" gutterBottom align="center">Base de Conocimientos</Typography>
        
        {!isConnected ? (
          <Box display="flex" justifyContent="center" my={4}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<LinkIcon />}
              onClick={connectDatastore}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Conectar Datastore'}
            </Button>
          </Box>
        ) : (
          <Paper elevation={3}>
            <Box p={3}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" gutterBottom>Crear nuevo documento</Typography>
                  <form onSubmit={createDocument}>
                    <TextField
                      fullWidth
                      value={newDocumentName}
                      onChange={(e) => setNewDocumentName(e.target.value)}
                      label="Nombre del documento"
                      required
                      margin="normal"
                      variant="outlined"
                    />
                    <Typography variant="subtitle1" gutterBottom>
                      Contenido del documento
                    </Typography>
                    <MDEditor
                      value={newDocumentContent}
                      onChange={handleDocumentContentChange}
                      preview="edit"
                      height={300}
                    />
                    <Box mt={2}>
                      <Button 
                        type="submit" 
                        variant="contained" 
                        color="primary" 
                        startIcon={<CloudUploadIcon />}
                        fullWidth
                      >
                        Crear Documento
                      </Button>
                    </Box>
                  </form>
                </Grid>
                
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" gutterBottom>Subir archivo</Typography>
                  <form onSubmit={createDocumentByFile}>
                    <Button
                      variant="outlined"
                      component="label"
                      startIcon={<FileUploadIcon />}
                      fullWidth
                      style={{ marginBottom: '1rem' }}
                    >
                      Seleccionar Archivo
                      <input
                        type="file"
                        hidden
                        onChange={(e) => setFile(e.target.files[0])}
                        required
                      />
                    </Button>
                    {file && (
                      <Typography variant="body2" gutterBottom>
                        Archivo seleccionado: {file.name}
                      </Typography>
                    )}
                    <Button 
                      type="submit" 
                      variant="contained" 
                      color="primary" 
                      fullWidth
                      disabled={!file}
                    >
                      Subir Archivo
                    </Button>
                  </form>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        )}

        {isConnected && (
          <Box mt={4}>
            <Paper elevation={3}>
              <Box p={3}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                  <Typography variant="h6">Lista de documentos</Typography>
                  <Button
                    startIcon={<RefreshIcon />}
                    onClick={() => fetchDocuments(datasetId)}
                    disabled={loading}
                  >
                    Actualizar
                  </Button>
                </Box>
                <Divider />
                {loading ? (
                  <Box display="flex" justifyContent="center" my={2}>
                    <CircularProgress />
                  </Box>
                ) : documents.length > 0 ? (
                  <List>
                    {documents.map(doc => (
                      <ListItem key={doc.id}>
                        <ListItemIcon>
                          {getDocumentIcon(doc.data_source_type)}
                        </ListItemIcon>
                        <ListItemText 
                          primary={
                            <Box display="flex" alignItems="center">
                              {doc.name}
                              <FiberManualRecordIcon 
                                style={{ 
                                  color: doc.display_status === 'available' ? 'green' : 'grey',
                                  fontSize: 12,
                                  marginLeft: 8
                                }} 
                              />
                            </Box>
                          }
                          secondary={
                            <>
                              <Typography component="span" variant="body2" color="textPrimary">
                                Tipo: {doc.data_source_type} | Estado: {doc.display_status}
                              </Typography>
                              <br />
                              <Typography component="span" variant="body2" color="textSecondary">
                                Creado: {formatDate(doc.created_at)} | Palabras: {doc.word_count} | Tokens: {doc.tokens}
                              </Typography>
                            </>
                          }
                        />
                        <IconButton onClick={() => deleteDocument(doc.id)} color="secondary">
                          <DeleteIcon />
                        </IconButton>
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Typography variant="body1" align="center">
                    No hay documentos disponibles. Crea o sube un documento para comenzar.
                  </Typography>
                )}
              </Box>
            </Paper>
          </Box>
        )}
      </Box>



      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default KnowledgeBase;
