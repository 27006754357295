import React, { useState, useEffect, useMemo, useCallback } from 'react';
import axios from 'axios';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FaUserPlus, FaCheckCircle, FaComments, FaTrash, FaTags, FaTimes, FaEdit, FaEnvelope, FaClock, FaPlus, FaSortUp, FaSortDown, FaSort, FaChartBar, FaDownload, FaList, FaThLarge, FaTag, FaCalendarPlus } from 'react-icons/fa';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function LeadsEmbed() {
  const [error, setError] = useState('');
  const [leads, setLeads] = useState([]);
  const [currentChatwootLead, setCurrentChatwootLead] = useState(null);
  const [token, setToken] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [viewMode, setViewMode] = useState('list');
  const [kanbanColumns, setKanbanColumns] = useState({});
  const [selectedLead, setSelectedLead] = useState(null);
  const [showSidebar, setShowSidebar] = useState(false);
  const [newNote, setNewNote] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [tagFilter, setTagFilter] = useState('');
  const [scheduledMessage, setScheduledMessage] = useState('');
  const [scheduledTime, setScheduledTime] = useState(null);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [newLead, setNewLead] = useState({ nombre: '', email: '', telefono: '', estado: 'No contactado' });
  const [showAnalytics, setShowAnalytics] = useState(false);
  const [usuarioId, setUsuarioId] = useState(null);
  const [showAddCitaModal, setShowAddCitaModal] = useState(false);
  const [newCita, setNewCita] = useState({ fecha: '', hora_inicio: '', hora_fin: '', servicio_id: null, contacto_nombre: '', contacto_email: '', contacto_telefono: '' });
  const [servicios, setServicios] = useState([]);
  const [citaAsignada, setCitaAsignada] = useState(null);
  const [isCheckingCita, setIsCheckingCita] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const shortToken = urlParams.get('token');
    if (shortToken) {
      setToken(shortToken);
    }
  }, []);

  const updateKanbanColumns = useCallback((leadsData) => {
    const newColumns = {
      'No contactado': [],
      'Contactado': [],
      'Precalificado': [],
      'En negociación': [],
      'Cerrado': []
    };
    leadsData.forEach((lead) => {
      if (lead.estado && newColumns[lead.estado]) {
        newColumns[lead.estado].push(lead);
      }
    });
    setKanbanColumns(newColumns);
  }, []);

  const handleChatwootMessage = useCallback((event) => {
    if (!event.data || typeof event.data !== 'string') return;
    
    try {
      const eventData = JSON.parse(event.data);
      console.log('Parsed Chatwoot event data:', eventData);
      
      if (eventData.event === 'appContext' && eventData.data) {
        const { conversation, contact } = eventData.data;
        if (conversation && contact) {
          const newLead = {
            id: `chatwoot_${conversation.id}`,
            email: contact.email || '',
            nombre: contact.name || '',
            telefono: contact.phone_number || 'Sin teléfono',
            additionalInfo: contact.additional_attributes || {},
            customAttributes: contact.custom_attributes || {},
            conversationId: conversation.id,
            estado: 'No contactado',
            tags: [],
            priority: 'Normal',
            notas: []
          };
          setCurrentChatwootLead(newLead);
          console.log('Current Chatwoot Lead set:', newLead);
          
          setLeads(prevLeads => {
            const existingLead = prevLeads.find(lead => lead.email === newLead.email);
            if (!existingLead) {
              const updatedLeads = [...prevLeads, newLead];
              updateKanbanColumns(updatedLeads);
              return updatedLeads;
            }
            return prevLeads;
          });
        }
      }
    } catch (error) {
      console.error('Error parsing Chatwoot message:', error);
    }
  }, [setLeads, updateKanbanColumns]);

  useEffect(() => {
    const handleChatwootEvent = (event) => {
      if (event.data && typeof event.data === 'string') {
        try {
          const eventData = JSON.parse(event.data);
          if (eventData.event === 'appContext') {
            console.log('Chatwoot event received:', eventData);
            const { conversation, contact } = eventData.data;
            if (contact) {
              const newChatwootLead = {
                id: `chatwoot_${contact.id}`,
                nombre: contact.name || 'Sin nombre',
                email: contact.email || 'Sin email',
                telefono: contact.phone_number || 'Sin teléfono',
                estado: 'No contactado',
                notas: [],
                additionalInfo: contact.additional_attributes || {},
                customAttributes: contact.custom_attributes || {},
                conversationId: conversation.id,
                tags: contact.labels || []
              };
              
              setCurrentChatwootLead(prevLead => {
                if (!prevLead || JSON.stringify(prevLead) !== JSON.stringify(newChatwootLead)) {
                  console.log('Nuevo contacto de Chatwoot detectado:', newChatwootLead);
                  return newChatwootLead;
                }
                return prevLead;
              });
            }
          }
        } catch (error) {
          console.error('Error parsing Chatwoot event:', error);
        }
      }
    };

    window.addEventListener('message', handleChatwootEvent);

    return () => {
      window.removeEventListener('message', handleChatwootEvent);
    };
  }, [updateKanbanColumns]);

  const filteredLeads = useMemo(() => {
    return leads.filter(lead => 
      (lead.nombre.toLowerCase().includes(searchTerm.toLowerCase()) ||
       lead.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
       lead.telefono.includes(searchTerm)) &&
      (statusFilter === '' || lead.estado === statusFilter) &&
      (tagFilter === '' || (lead.tags && lead.tags.includes(tagFilter)))
    );
  }, [leads, searchTerm, statusFilter, tagFilter]);

  const fetchLeads = useCallback(async () => {
    setIsLoading(true);
    if (!token) {
      setError('No se encontró el token de autenticación.');
      setIsLoading(false);
      return;
    }
    try {
      const response = await axios.get(`/api/leads`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setLeads(response.data);
      updateKanbanColumns(response.data);
      setError('');
    } catch (error) {
      console.error('Error fetching leads:', error);
      setError('Error al cargar los leads: ' + (error.response?.data?.error || error.message));
    } finally {
      setIsLoading(false);
    }
  }, [token, updateKanbanColumns]);

  const fetchUsuarioId = useCallback(async () => {
    if (!token) {
      setError('No se encontró el token de autenticación.');
      return;
    }
    try {
      const response = await axios.get('/api/usuarios/me', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUsuarioId(response.data.id);
    } catch (error) {
      console.error('Error al obtener el ID del usuario:', error);
      setError('Error al obtener la información del usuario');
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      fetchLeads();
      fetchUsuarioId();
      fetchServicios();
    }
  }, [token, fetchLeads, fetchUsuarioId]);

  const fetchServicios = async () => {
    try {
      const response = await axios.get('/api/servicios', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setServicios(response.data);
    } catch (error) {
      console.error('Error fetching servicios:', error);
      setError('Error al cargar servicios: ' + error.message);
    }
  };

  const checkCitaAsignada = async (leadId) => {
    setIsCheckingCita(true);
    setCitaAsignada(null); // Reset citaAsignada before checking
    setError(''); // Clear any previous errors
    try {
      const response = await axios.get(`/api/citas/lead/${leadId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (response.data && Object.keys(response.data).length > 0) {
        setCitaAsignada(response.data);
      } else {
        console.log('No cita assigned for this lead');
      }
    } catch (error) {
      console.error('Error checking cita asignada:', error);
      if (error.response && error.response.status === 404) {
        // If 404, it means no appointment is assigned, which is not an error condition
        console.log('No cita assigned for this lead');
      } else {
        // For other errors, set an error state
        setError('Error al verificar cita asignada: ' + (error.response?.data?.message || error.message));
      }
    } finally {
      setIsCheckingCita(false);
    }
    
    const lead = leads.find(l => l.id === leadId);
    if (lead) {
      setNewCita({
        ...newCita,
        contacto_nombre: lead.nombre || '',
        contacto_email: lead.email || '',
        contacto_telefono: lead.telefono || '',
        servicio_id: 1 // Valor por defecto si no se selecciona ningún servicio
      });
    }
    setShowAddCitaModal(true);
  };

  const handleSubmitCita = async () => {
    try {
      const token = sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken');
      if (!token) {
        throw new Error('No se encontró el token de autenticación');
      }
      
      const citaData = {
        ...newCita,
        servicio_id: newCita.servicio_id || 1 // Usar 1 si no se seleccionó ningún servicio
      };

      const response = await axios.post('/api/citas', citaData, {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.data && response.data.cita) {
        setShowAddCitaModal(false);
        setError('');
        alert('Cita creada con éxito');
      } else {
        throw new Error('La respuesta del servidor no contiene los datos esperados de la cita');
      }
    } catch (error) {
      console.error('Error al crear la cita:', error);
      setError('Error al crear la cita: ' + (error.response?.data?.error || error.message));
    }
  };

  // Nuevo useEffect para recargar los leads cuando cambia currentChatwootLead
  useEffect(() => {
    if (token && currentChatwootLead) {
      fetchLeads(token);
    }
  }, [token, currentChatwootLead, fetchLeads]);

  const handleAddNote = useCallback(async (id, content) => {
    if (!token) {
      console.error('Token no encontrado');
      setError('No se encontró el token de autenticación.');
      return;
    }
    try {
      console.log('Token usado para la solicitud:', token);
      const newNote = { content, timestamp: new Date().toISOString() };
      const response = await axios.post(`/api/leads/${id}/notes`, { nota: JSON.stringify(newNote) }, {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      console.log('Respuesta del servidor:', response.data);
      
      // Función auxiliar para actualizar las notas
      const updateNotas = (existingNotas) => {
        const notasArray = Array.isArray(existingNotas) ? existingNotas : [];
        return [...notasArray, newNote];
      };
      
      // Actualizar el estado de los leads
      setLeads(prevLeads => prevLeads.map(lead => 
        lead.id === id ? { ...lead, notas: updateNotas(lead.notas) } : lead
      ));
      
      // Actualizar el lead seleccionado
      setSelectedLead(prevLead => {
        if (prevLead && prevLead.id === id) {
          return { ...prevLead, notas: updateNotas(prevLead.notas) };
        }
        return prevLead;
      });
      
      // Actualizar las columnas del Kanban
      setKanbanColumns(prevColumns => {
        const updatedColumns = { ...prevColumns };
        Object.keys(updatedColumns).forEach(columnName => {
          updatedColumns[columnName] = updatedColumns[columnName].map(lead => 
            lead.id === id ? { ...lead, notas: updateNotas(lead.notas) } : lead
          );
        });
        return updatedColumns;
      });
      
      setNewNote('');
    } catch (error) {
      console.error('Error adding lead note:', error);
      setError('Error al añadir la nota al lead: ' + (error.response?.data?.error || error.message));
    }
  }, [token, setLeads, setSelectedLead, setKanbanColumns, setError, setNewNote]);

  const handleEditNote = useCallback(async (index) => {
    const editedContent = prompt('Editar nota:', selectedLead.notas[index].content);
    if (editedContent !== null && token) {
      try {
        const updatedNotes = [...selectedLead.notas];
        updatedNotes[index] = { ...updatedNotes[index], content: editedContent };
        await axios.put(`/api/leads/${selectedLead.id}/notes`, { notas: updatedNotes }, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setLeads(prevLeads => prevLeads.map(lead => 
          lead.id === selectedLead.id ? { ...lead, notas: updatedNotes } : lead
        ));
      } catch (error) {
        console.error('Error editing lead note:', error);
        setError('Error al editar la nota del lead');
      }
    }
  }, [token, selectedLead, setLeads, setError]);

  const handleDeleteNote = useCallback(async (leadId, noteIndex) => {
    if (window.confirm('¿Estás seguro de que quieres eliminar esta nota?') && token) {
      try {
        await axios.delete(`/api/leads/${leadId}/notes/${noteIndex}`, {
          headers: { Authorization: `Bearer ${token}` }
        });

        setLeads(prevLeads => prevLeads.map(lead => {
          if (lead.id === leadId) {
            const updatedNotes = [...lead.notas];
            updatedNotes.splice(noteIndex, 1);
            return { ...lead, notas: updatedNotes };
          }
          return lead;
        }));

        if (selectedLead && selectedLead.id === leadId) {
          const updatedNotes = [...selectedLead.notas];
          updatedNotes.splice(noteIndex, 1);
          setSelectedLead({ ...selectedLead, notas: updatedNotes });
        }

        setKanbanColumns(prevColumns => {
          const updatedColumns = { ...prevColumns };
          Object.keys(updatedColumns).forEach(columnName => {
            updatedColumns[columnName] = updatedColumns[columnName].map(lead => {
              if (lead.id === leadId) {
                const updatedNotes = [...lead.notas];
                updatedNotes.splice(noteIndex, 1);
                return { ...lead, notas: updatedNotes };
              }
              return lead;
            });
          });
          return updatedColumns;
        });

        setError('');
      } catch (error) {
        console.error('Error deleting note:', error);
        setError('Error al eliminar la nota: ' + (error.response?.data?.error || error.message));
      }
    }
  }, [token, setLeads, selectedLead, setSelectedLead, setKanbanColumns, setError]);

  const handleDeleteLead = useCallback(async (leadId) => {
    if (window.confirm('¿Estás seguro de que quieres eliminar este lead? Esta acción no se puede deshacer.') && token) {
      try {
        await axios.delete(`/api/leads/${leadId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setLeads(prevLeads => prevLeads.filter(lead => lead.id !== leadId));
        setSelectedLead(null);
        setError('');
      } catch (error) {
        console.error('Error deleting lead:', error);
        setError('Error al eliminar el lead: ' + (error.response?.data?.error || error.message));
      }
    }
  }, [token, setLeads, setSelectedLead, setError]);

  const handleAddLead = useCallback(async (leadData = newLead) => {
    if (!token) {
      setError('No se encontró el token de autenticación');
      return;
    }
    try {
      if (!leadData.nombre) {
        setError('Por favor, complete al menos el nombre del lead');
        return;
      }

      // Verificar si ya existe un lead con este email (solo si se proporcionó un email)
      if (leadData.email) {
        const existingLead = leads.find(lead => lead.email === leadData.email);
        if (existingLead) {
          setError('Ya existe un lead con este correo electrónico');
          return;
        }
      }

      const leadToAdd = {
        usuario_id: usuarioId,
        nombre: leadData.nombre,
        email: leadData.email || null,
        telefono: leadData.telefono || null,
        estado: leadData.estado || 'No contactado'
      };

      const response = await axios.post(`/api/leads`, leadToAdd, {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      const createdLead = response.data;
      
      setError('');
      setNewLead({ nombre: '', email: '', telefono: '', estado: 'No contactado' }); // Limpiar el formulario
      console.log('Lead creado con éxito:', createdLead);

      // Si el lead añadido es el lead actual de Chatwoot, limpiamos currentChatwootLead
      if (leadData.id && leadData.id.startsWith('chatwoot_')) {
        setCurrentChatwootLead(null);
      }

      // Recargar los leads después de añadir uno nuevo
      await fetchLeads();
    } catch (error) {
      console.error('Error creating lead:', error);
      setError('Error al crear el lead: ' + (error.response?.data?.message || error.message));
    }
  }, [token, newLead, setError, setNewLead, setCurrentChatwootLead, usuarioId, fetchLeads, leads]);

  const leadExists = useMemo(() => {
    return currentChatwootLead && leads.some(lead => lead.email === currentChatwootLead.email);
  }, [currentChatwootLead, leads]);

  const existingLead = useMemo(() => {
    return currentChatwootLead && leads.find(lead => lead.email === currentChatwootLead.email);
  }, [currentChatwootLead, leads]);

  useEffect(() => {
    if (leads.length > 0) {
      updateKanbanColumns(leads);
    }
  }, [leads, updateKanbanColumns]);

  const columnColors = {
    'No contactado': 'bg-red-100',
    'Contactado': 'bg-yellow-100',
    'Precalificado': 'bg-orange-100',
    'En negociación': 'bg-blue-100',
    'Cerrado': 'bg-green-100'
  };

  const columnEmojis = {
    'No contactado': '📞',
    'Contactado': '✉️',
    'Precalificado': '🔍',
    'En negociación': '💼',
    'Cerrado': '🎉'
  };

  const updateLeadStatus = useCallback(async (leadId, newStatus) => {
    try {
      if (!token) {
        throw new Error('No se encontró el token de autenticación');
      }
      await axios.put(`/api/leads/${leadId}`, { estado: newStatus }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      setLeads(prevLeads => prevLeads.map(lead => 
        lead.id === leadId ? { ...lead, estado: newStatus } : lead
      ));
      
      setError('');
    } catch (error) {
      console.error('Error updating lead status:', error);
      setError('Error al actualizar el estado del lead: ' + error.message);
    }
  }, [token, setLeads, setError]);

  const onDragEnd = useCallback((result) => {
    const { source, destination } = result;
    if (!destination) return;

    const sourceColumn = Object.keys(kanbanColumns)[parseInt(source.droppableId)];
    const destColumn = Object.keys(kanbanColumns)[parseInt(destination.droppableId)];
    const sourceTasks = [...kanbanColumns[sourceColumn]];
    const destTasks = sourceColumn === destColumn ? sourceTasks : [...kanbanColumns[destColumn]];
    const [removed] = sourceTasks.splice(source.index, 1);
    destTasks.splice(destination.index, 0, removed);

    const newKanbanColumns = {
      ...kanbanColumns,
      [sourceColumn]: sourceTasks,
      [destColumn]: destTasks,
    };

    setKanbanColumns(newKanbanColumns);

    // Actualizar el estado del lead en el backend y en el estado local
    updateLeadStatus(removed.id, destColumn);
  }, [kanbanColumns, updateLeadStatus]);

  const renderKanbanBoard = () => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="flex space-x-2 overflow-x-auto">
          {Object.entries(kanbanColumns).map(([columnName, columnLeads], columnIndex) => (
            <Droppable droppableId={columnIndex.toString()} key={columnName}>
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={`flex-shrink-0 w-64 ${columnColors[columnName]} p-2 rounded`}
                >
                  <h3 className="font-bold text-sm mb-2 flex justify-between items-center">
                    <span>{columnEmojis[columnName]} {columnName}</span>
                    <span className="bg-white text-gray-700 px-2 py-1 rounded-full text-xs">
                      {columnLeads.length}
                    </span>
                  </h3>
                  {columnLeads && columnLeads.length > 0 ? (
                    columnLeads.map((lead, index) => (
                      <Draggable key={lead.id.toString()} draggableId={lead.id.toString()} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="bg-white p-2 mb-2 rounded shadow text-xs"
                          >
                            <h4 className="font-semibold truncate">{lead.nombre}</h4>
                            <p className="truncate">{lead.email}</p>
                            <div className="mt-1 flex justify-between items-center">
                              <div>
                                <button
                                  onClick={() => {
                                    setSelectedLead(lead);
                                    setShowSidebar(true);
                                  }}
                                  className="bg-blue-500 text-white px-2 py-1 rounded mr-1"
                                >
                                  <FaComments className="inline" />
                                </button>
                                <button
                                  onClick={() => handleDeleteLead(lead.id)}
                                  className="bg-red-500 text-white px-2 py-1 rounded"
                                >
                                  <FaTrash className="inline" />
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))
                  ) : (
                    <p className="text-gray-500 text-xs">No hay leads.</p>
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ))}
        </div>
      </DragDropContext>
    );
  };


  const getAnalyticsData = () => {
    const leadsByStatus = Object.fromEntries(
      Object.entries(kanbanColumns).map(([status, leads]) => [status, leads.length])
    );
    const statusColors = {
      'No contactado': 'rgba(239, 68, 68, 0.7)',
      'Contactado': 'rgba(245, 158, 11, 0.7)',
      'Precalificado': 'rgba(249, 115, 22, 0.7)',
      'En negociación': 'rgba(59, 130, 246, 0.7)',
      'Cerrado': 'rgba(16, 185, 129, 0.7)'
    };
    return {
      labels: Object.keys(leadsByStatus),
      datasets: [
        {
          label: 'Leads por estado',
          data: Object.values(leadsByStatus).map(Math.round),
          backgroundColor: Object.keys(leadsByStatus).map(status => statusColors[status]),
          borderColor: Object.keys(leadsByStatus).map(status => statusColors[status].replace('0.7', '1')),
          borderWidth: 1,
        },
      ],
    };
  };

  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        max: 4,
        ticks: {
          stepSize: 1
        }
      }
    }
  };


  const getCSVData = () => {
    return leads.map(lead => ({
      Nombre: lead.nombre,
      Email: lead.email,
      Teléfono: lead.telefono,
      Estado: lead.estado,
      'Fecha de creación': lead.created_at
    }));
  };

  const handleScheduleMessage = async () => {
    if (!scheduledMessage || !scheduledTime || !currentChatwootLead || !token) {
      setError('Por favor, complete el mensaje y la hora programada');
      return;
    }

    try {
      const response = await axios.post(`/api/chatwoot/schedule-message`, {
        conversationId: currentChatwootLead.conversationId,
        message: scheduledMessage,
        scheduledAt: scheduledTime
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });

      if (response.data.success) {
        setError('');
        setScheduledMessage('');
        setScheduledTime('');
        alert('Mensaje programado con éxito');
      } else {
        setError('Error al programar el mensaje');
      }
    } catch (error) {
      console.error('Error scheduling message:', error);
      setError('Error al programar el mensaje: ' + (error.response?.data?.error || error.message));
    }
  };


  return (
    <div className="p-4">
      <h2 className="text-xl font-bold mb-4">Gestión de Leads</h2>
      {error && (
        <div className="text-center p-4 mb-4 bg-red-100 border border-red-400 text-red-700 rounded">
          <h2 className="text-xl font-bold">{error}</h2>
        </div>
      )}
      <div className="mb-4 flex flex-wrap justify-between items-center">
        <div className="flex items-center space-x-2 mb-2 sm:mb-0">
          <button
            onClick={() => setViewMode('list')}
            className={`px-4 py-2 rounded ${viewMode === 'list' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          >
            <FaList className="inline mr-1" /> Lista
          </button>
          <button
            onClick={() => setViewMode('kanban')}
            className={`px-4 py-2 rounded ${viewMode === 'kanban' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          >
            <FaThLarge className="inline mr-1" /> Kanban
          </button>
        </div>
        <div className="flex-grow mx-2 mb-2 sm:mb-0">
          <input
            type="text"
            placeholder="Buscar leads..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full px-4 py-2 border rounded"
          />
        </div>
        <div className="flex space-x-2">
          <CSVLink
            data={getCSVData()}
            filename={"leads.csv"}
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
          >
            <FaDownload className="inline mr-1" /> Exportar CSV
          </CSVLink>
          <button
            onClick={() => setShowAnalytics(!showAnalytics)}
            className="bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-600"
          >
            <FaChartBar className="inline mr-1" /> {showAnalytics ? 'Ocultar Análisis' : 'Mostrar Análisis'}
          </button>
        </div>
      </div>
      {currentChatwootLead && (
        <div className="mb-4 p-4 bg-blue-100 rounded-lg shadow">
          <h3 className="text-lg font-semibold mb-2">Lead Actual de Chatwoot</h3>
          <div className="grid grid-cols-2 gap-4">
            <p><strong>Nombre:</strong> {currentChatwootLead.nombre}</p>
            <p><strong>Email:</strong> {currentChatwootLead.email}</p>
            <p><strong>Teléfono:</strong> {currentChatwootLead.telefono}</p>
            <p><strong>Estado:</strong> {currentChatwootLead.estado}</p>
          </div>
          <div className="mt-4 flex space-x-2">
            {!leadExists && (
              <button
                onClick={() => handleAddLead(currentChatwootLead)}
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
              >
                <FaUserPlus className="inline mr-1" /> Añadir como nuevo lead
              </button>
            )}
            <span className={`px-3 py-2 rounded ${leadExists ? 'bg-yellow-200 text-yellow-800' : 'bg-green-200 text-green-800'}`}>
              {leadExists ? 'Este contacto ya existe como lead' : 'Este contacto no existe como lead'}
            </span>
            <button
              onClick={() => {
                setSelectedLead(existingLead || currentChatwootLead);
                setShowSidebar(true);
              }}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              <FaEdit className="inline mr-1" /> Ver detalles
            </button>
          </div>
        </div>
      )}
      <hr className="my-4 border-gray-300" />
      {showAnalytics && (
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Análisis de Leads</h3>
          <Bar data={getAnalyticsData()} options={chartOptions} />
        </div>
      )}
      {isLoading ? (
        <div className="text-center">
          <p className="text-gray-500">Cargando leads...</p>
        </div>
      ) : (
        <>
          {viewMode === 'kanban' ? (
            renderKanbanBoard()
          ) : (
            <>
              {filteredLeads.length === 0 ? (
                <p className="text-center text-gray-500">No se encontraron leads.</p>
              ) : (
                <ul className="space-y-4">
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                    {filteredLeads.map((lead) => {
                      const columnColor = columnColors[lead.estado] || 'bg-gray-100';
                      const columnEmoji = columnEmojis[lead.estado] || '❓';
                      return (
                        <div key={lead.id} className={`${columnColor} p-3 rounded shadow`}>
                          <div className="flex justify-between items-center mb-2">
                            <h3 className="font-bold text-sm truncate">{lead.nombre}</h3>
                            <span className="text-xl" title={lead.estado}>{columnEmoji}</span>
                          </div>
                          <p className="text-xs truncate">{lead.email || 'Email no disponible'}</p>
                          <p className="text-xs truncate">{lead.telefono || 'Teléfono no disponible'}</p>
                          <div className="mt-2 flex space-x-2">
                            <button
                              onClick={() => {
                                console.log("Lead seleccionado:", lead);
                                setSelectedLead({...lead, notas: lead.notas || []});
                                setShowSidebar(true);
                              }}
                              className="bg-blue-500 text-white px-2 py-1 rounded text-xs flex-grow"
                            >
                              <FaComments className="inline mr-1" /> Ver detalles
                            </button>
                            <button
                              onClick={() => handleDeleteLead(lead.id)}
                              className="bg-red-500 text-white px-2 py-1 rounded text-xs"
                            >
                              <FaTrash className="inline" />
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </ul>
              )}
            </>
          )}
        </>
      )}
      {showSidebar && selectedLead && (
        <div className="fixed inset-y-0 right-0 w-96 bg-white shadow-lg overflow-y-auto">
          <div className="p-4">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-bold">{selectedLead.nombre || 'Sin nombre'}</h3>
              <button onClick={() => setShowSidebar(false)} className="text-gray-500 hover:text-gray-700">
                <FaTimes />
              </button>
            </div>
            <div className="mb-4">
              <p><strong>Email:</strong> {selectedLead.email || 'No disponible'}</p>
              <p><strong>Teléfono:</strong> {selectedLead.telefono || 'No disponible'}</p>
            </div>
            <div className="mb-4">
              <h4 className="font-semibold mb-2">Estado:</h4>
              <div className="inline-block bg-gray-200 px-3 py-2 rounded-full text-sm font-semibold text-gray-700">
                <FaCheckCircle className="inline mr-2 text-green-500" />
                {selectedLead.estado || 'No especificado'}
              </div>
            </div>
            <div className="mb-4">
              <h4 className="font-semibold mb-2">Notas:</h4>
              {selectedLead.notas && Array.isArray(selectedLead.notas) && selectedLead.notas.length > 0 ? (
                <ul className="space-y-2">
                  {selectedLead.notas.map((nota, index) => {
                    let parsedNota;
                    try {
                      parsedNota = typeof nota === 'string' ? JSON.parse(nota) : nota;
                      // Si parsedNota.content es una cadena JSON, intentamos analizarla también
                      if (typeof parsedNota.content === 'string' && parsedNota.content.startsWith('{')) {
                        const innerContent = JSON.parse(parsedNota.content);
                        parsedNota = {
                          content: innerContent.content,
                          timestamp: innerContent.timestamp || parsedNota.timestamp
                        };
                      }
                    } catch (error) {
                      console.error('Error parsing nota:', error);
                      parsedNota = { content: 'Error al cargar la nota', timestamp: null };
                    }
                    return (
                      <li key={index} className="bg-gray-100 p-3 rounded-lg shadow-sm">
                        <div className="flex justify-between items-start">
                          <p className="text-sm">{parsedNota.content || 'Contenido no disponible'}</p>
                          <div className="flex space-x-2">
                            <button onClick={() => handleEditNote(selectedLead.id, index)} className="text-blue-500 hover:text-blue-700">
                              <FaEdit />
                            </button>
                            <button onClick={() => handleDeleteNote(selectedLead.id, index)} className="text-red-500 hover:text-red-700">
                              <FaTrash />
                            </button>
                          </div>
                        </div>
                        <p className="text-xs text-gray-500 mt-1">
                          {parsedNota.timestamp ? new Date(parsedNota.timestamp).toLocaleString() : 'Fecha no disponible'}
                        </p>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <p className="text-gray-500">No hay notas disponibles.</p>
              )}
            </div>
            <div className="mb-4">
              <textarea
                className="w-full p-2 border rounded mb-2"
                rows="3"
                placeholder="Añadir nueva nota..."
                value={newNote}
                onChange={(e) => setNewNote(e.target.value)}
              ></textarea>
              <button
                onClick={() => {
                  handleAddNote(selectedLead.id, newNote);
                  setNewNote('');
                }}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 w-full"
              >
                <FaPlus className="inline mr-1" /> Añadir Nota
              </button>
            </div>
            <div className="flex flex-col space-y-2">
              <a
                href={`mailto:${selectedLead.email}`}
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 inline-block text-center"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaEnvelope className="inline mr-1" /> Enviar Email
              </a>
              <button
                onClick={() => {
                  setShowScheduleModal(true);
                }}
                className="bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-600"
              >
                <FaClock className="inline mr-1" /> Programar Mensaje
              </button>
              <button
                onClick={() => {
                  setError('');
                  setCitaAsignada(undefined);
                  checkCitaAsignada(selectedLead.id).then(() => {
                    setShowAddCitaModal(true);
                  });
                }}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                <FaCalendarPlus className="inline mr-1" /> Agendar Cita
              </button>
              {isCheckingCita ? (
                <p className="text-sm text-gray-500 mt-2">Verificando citas asignadas...</p>
              ) : citaAsignada ? (
                <div className="mt-2 p-2 bg-yellow-100 rounded">
                  <p className="text-sm font-semibold">Cita asignada:</p>
                  <p className="text-xs">{new Date(citaAsignada.fecha).toLocaleDateString()} - {citaAsignada.hora_inicio}</p>
                </div>
              ) : (
                <p className="text-sm text-gray-500 mt-2">No hay citas asignadas</p>
              )}
            </div>
          </div>
        </div>
      )}
      {showScheduleModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center" onClick={() => setShowScheduleModal(false)}>
          <div className="bg-white w-full max-w-md mx-auto rounded-lg shadow-xl overflow-hidden" onClick={e => e.stopPropagation()}>
            <div className="bg-blue-500 text-white px-6 py-4">
              <h3 className="text-lg font-bold flex items-center">
                <FaEnvelope className="mr-2" /> Programar mensaje
              </h3>
            </div>
            <div className="p-6">
              <textarea
                className="w-full p-2 border rounded mb-4 h-32 resize-none"
                placeholder="Escriba su mensaje aquí..."
                value={scheduledMessage}
                onChange={(e) => setScheduledMessage(e.target.value)}
              ></textarea>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">Fecha y hora de envío</label>
                <DatePicker
                  selected={scheduledTime}
                  onChange={(date) => setScheduledTime(date)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="Hora"
                  dateFormat="dd/MM/yyyy HH:mm"
                  className="w-full p-2 border rounded"
                  placeholderText="Seleccionar fecha y hora"
                  minDate={new Date()}
                  locale="es"
                />
              </div>
              <div className="flex justify-end space-x-2">
                <button
                  onClick={() => setShowScheduleModal(false)}
                  className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400 transition duration-200"
                >
                  Cancelar
                </button>
                <button
                  onClick={() => {
                    handleScheduleMessage();
                    setShowScheduleModal(false);
                  }}
                  className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition duration-200"
                >
                  Programar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showAddCitaModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
          <div className="bg-white p-8 rounded-lg shadow-2xl w-full max-w-md">
            <h2 className="text-2xl font-bold mb-6 text-gray-800">Agendar Cita</h2>
            <form onSubmit={(e) => {
              e.preventDefault();
              handleSubmitCita();
            }}>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="fecha">
                  Fecha
                </label>
                <input
                  type="date"
                  id="fecha"
                  name="fecha"
                  value={newCita.fecha}
                  onChange={(e) => setNewCita({...newCita, fecha: e.target.value})}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="hora_inicio">
                  Hora de inicio
                </label>
                <input
                  type="time"
                  id="hora_inicio"
                  name="hora_inicio"
                  value={newCita.hora_inicio}
                  onChange={(e) => setNewCita({...newCita, hora_inicio: e.target.value})}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="hora_fin">
                  Hora de fin
                </label>
                <input
                  type="time"
                  id="hora_fin"
                  name="hora_fin"
                  value={newCita.hora_fin}
                  onChange={(e) => setNewCita({...newCita, hora_fin: e.target.value})}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="servicio_id">
                  Servicio
                </label>
                <select
                  id="servicio_id"
                  name="servicio_id"
                  value={newCita.servicio_id || ''}
                  onChange={(e) => setNewCita({...newCita, servicio_id: e.target.value || 1})}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value="">Seleccione un servicio</option>
                  {servicios.map(servicio => (
                    <option key={servicio.id} value={servicio.id}>{servicio.nombre}</option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="contacto_nombre">
                  Nombre del contacto
                </label>
                <input
                  type="text"
                  id="contacto_nombre"
                  name="contacto_nombre"
                  value={newCita.contacto_nombre}
                  onChange={(e) => setNewCita({...newCita, contacto_nombre: e.target.value})}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="contacto_email">
                  Email del contacto
                </label>
                <input
                  type="email"
                  id="contacto_email"
                  name="contacto_email"
                  value={newCita.contacto_email}
                  onChange={(e) => setNewCita({...newCita, contacto_email: e.target.value})}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="contacto_telefono">
                  Teléfono del contacto
                </label>
                <input
                  type="tel"
                  id="contacto_telefono"
                  name="contacto_telefono"
                  value={newCita.contacto_telefono}
                  onChange={(e) => setNewCita({...newCita, contacto_telefono: e.target.value})}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="flex items-center justify-between">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="submit"
                >
                  Agendar Cita
                </button>
                <button
                  className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="button"
                  onClick={() => setShowAddCitaModal(false)}
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default LeadsEmbed;
