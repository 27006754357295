import React, { useState, useEffect } from 'react';
import axios from 'axios';

function MauticConfig({ isOpen, onClose }) {
  const [mauticUrl, setMauticUrl] = useState('');
  const [mauticUsername, setMauticUsername] = useState('');
  const [mauticPassword, setMauticPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [syncStatus, setSyncStatus] = useState('');
  const [lastSync, setLastSync] = useState('');

  useEffect(() => {
    fetchMauticConfig();
    fetchSyncStatus();
  }, []);

  const fetchMauticConfig = async () => {
    try {
      const response = await axios.get('/api/usuarios/mautic-config', {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken')}` }
      });
      setMauticUrl(response.data.mauticUrl || '');
      setMauticUsername(response.data.mauticUsername || '');
    } catch (error) {
      console.error('Error fetching Mautic config:', error);
      if (error.response) {
        setError(`Error ${error.response.status}: ${error.response.data.error}`);
      } else if (error.request) {
        setError('No se recibió respuesta del servidor');
      } else {
        setError('Error al configurar la solicitud');
      }
    }
  };

  const fetchSyncStatus = async () => {
    try {
      const response = await axios.get('/api/usuarios/mautic-sync-status', {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken')}` }
      });
      setSyncStatus(response.data.syncStatus);
      setLastSync(response.data.lastSync);
    } catch (error) {
      console.error('Error fetching sync status:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      await axios.post('/api/usuarios/mautic-config', {
        mauticUrl,
        mauticUsername,
        mauticPassword
      }, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken')}` }
      });
      setSuccess('Configuración de Mautic guardada exitosamente');
      setMauticPassword('');
    } catch (error) {
      console.error('Error saving Mautic config:', error);
      if (error.response) {
        setError(`Error al guardar la configuración de Mautic: ${error.response.data.error}. Detalles: ${error.response.data.details || 'No hay detalles disponibles'}`);
      } else if (error.request) {
        setError('No se recibió respuesta del servidor al intentar guardar la configuración de Mautic');
      } else {
        setError(`Error al guardar la configuración de Mautic: ${error.message}`);
      }
    }
  };

  const handleSyncContacts = async () => {
    setError('');
    setSuccess('');
    try {
      setSyncStatus('Sincronizando...');
      const response = await axios.post('/api/usuarios/sync-mautic-contacts', {}, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken')}` }
      });
      setSuccess(`Sincronización completada. Contactos sincronizados: ${response.data.syncedCount}, Errores: ${response.data.errorCount}`);
      fetchSyncStatus();
    } catch (error) {
      console.error('Error syncing contacts:', error);
      setError('Error al sincronizar contactos: ' + (error.response?.data?.error || error.message));
    } finally {
      setSyncStatus('');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" onClick={onClose}>
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white" onClick={e => e.stopPropagation()}>
        <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">Configuración de Mautic</h3>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        {success && <p className="text-green-500 mb-4">{success}</p>}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="mauticUrl" className="block text-sm font-medium text-gray-700">URL de Mautic</label>
            <input
              type="url"
              id="mauticUrl"
              value={mauticUrl}
              onChange={(e) => setMauticUrl(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="mauticUsername" className="block text-sm font-medium text-gray-700">Usuario de Mautic</label>
            <input
              type="text"
              id="mauticUsername"
              value={mauticUsername}
              onChange={(e) => setMauticUsername(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="mauticPassword" className="block text-sm font-medium text-gray-700">Contraseña de Mautic</label>
            <input
              type="password"
              id="mauticPassword"
              value={mauticPassword}
              onChange={(e) => setMauticPassword(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              required
            />
          </div>
          <div className="mt-6">
            <button type="submit" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm">
              Guardar configuración
            </button>
          </div>
        </form>
        <div className="mt-6">
          <button onClick={handleSyncContacts} className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm">
            Sincronizar contactos
          </button>
        </div>
        <div className="mt-4">
          <p className="text-sm text-gray-600">Estado de sincronización: {syncStatus}</p>
          <p className="text-sm text-gray-600">Última sincronización: {lastSync ? new Date(lastSync).toLocaleString() : 'Nunca'}</p>
        </div>
      </div>
    </div>
  );
}

export default MauticConfig;
