import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { FaSort, FaSortUp, FaSortDown, FaDownload, FaPlus, FaList, FaThLarge, FaCog, FaArchive, FaComments, FaRobot, FaTrash, FaFilter } from 'react-icons/fa';
import MauticConfig from '../components/MauticConfig';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

function Leads() {
  const [leads, setLeads] = useState([]);
  const [error, setError] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [searchTerm, setSearchTerm] = useState('');
  const [viewMode, setViewMode] = useState('list');
  const [selectedLead, setSelectedLead] = useState(null);
  const [newNote, setNewNote] = useState('');
  const [kanbanColumns, setKanbanColumns] = useState({
    'No contactado': [],
    'Contactado': [],
    'Precalificado': [],
    'En negociación': [],
    'Cerrado': []
  });
  const [statusFilter, setStatusFilter] = useState('Todos');
  const [token, setToken] = useState(sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken'));

  useEffect(() => {
    fetchLeads();
  }, []);
  const [showMauticConfig, setShowMauticConfig] = useState(false);

  useEffect(() => {
    fetchLeads();
  }, []);

  const statusColors = {
    'No contactado': 'bg-red-100',
    'Contactado': 'bg-yellow-100',
    'Precalificado': 'bg-orange-100',
    'En negociación': 'bg-blue-100',
    'Cerrado': 'bg-green-100'
  };

  async function fetchLeads() {
    try {
      const token = sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken');
      if (!token) {
        setError('No se encontró el token de autenticación. Por favor, inicie sesión nuevamente.');
        console.error('Token no encontrado');
        return;
      }
      const response = await axios.get('/api/leads', {
        headers: { Authorization: `Bearer ${token}` }
      });
      const leadsWithNotes = response.data.map(lead => ({
        ...lead,
        notas: lead.notas || []
      }));
      setLeads(leadsWithNotes);
      updateKanbanColumns(leadsWithNotes);
      console.log('Leads fetched:', leadsWithNotes);
      setError('');
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setError('Sesión expirada. Por favor, inicie sesión nuevamente.');
      } else {
        setError('Error al cargar leads: ' + (error.response?.data?.error || error.message));
      }
      console.error('Error fetching leads:', error.response?.data || error);
    }
  }

  const updateKanbanColumns = (leadsData) => {
    const newColumns = {
      'No contactado': [],
      'Contactado': [],
      'Precalificado': [],
      'En negociación': [],
      'Cerrado': []
    };
    leadsData.forEach((lead) => {
      if (lead.estado && newColumns[lead.estado]) {
        newColumns[lead.estado].push(lead);
      } else {
        newColumns['No contactado'].push(lead);
      }
    });
    setKanbanColumns(newColumns);
  };

  const sortedLeads = React.useMemo(() => {
    let sortableLeads = [...leads];
    if (sortConfig.key !== null) {
      sortableLeads.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableLeads;
  }, [leads, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (columnName) => {
    if (sortConfig.key === columnName) {
      return sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />;
    }
    return <FaSort />;
  };

  const filteredLeads = sortedLeads.filter(lead =>
    lead.nombre.toLowerCase().includes(searchTerm.toLowerCase()) ||
    lead.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
    lead.telefono.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleDownloadCSV = async () => {
    try {
      const response = await axios.get('/api/leads/download', {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken')}` },
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'leads.csv');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      setError('Error al descargar el CSV: ' + (error.response?.data?.error || error.message));
    }
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const { source, destination, draggableId } = result;
    if (source.droppableId === destination.droppableId) return;

    const newKanbanColumns = { ...kanbanColumns };
    const [movedLead] = newKanbanColumns[source.droppableId].splice(source.index, 1);
    movedLead.estado = destination.droppableId;
    newKanbanColumns[destination.droppableId].splice(destination.index, 0, movedLead);

    setKanbanColumns(newKanbanColumns);

    try {
      const response = await axios.put(`/api/leads/${draggableId}`, { 
        estado: destination.droppableId,
        nombre: movedLead.nombre,
        email: movedLead.email,
        telefono: movedLead.telefono
      }, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken')}` }
      });
      if (response.data && response.data.id) {
        const updatedLeads = leads.map(lead => 
          lead.id === draggableId || (lead.id.startsWith('temp-') && draggableId === lead.id.replace('temp-', '')) 
            ? { ...lead, id: response.data.id, estado: destination.droppableId } 
            : lead
        );
        setLeads(updatedLeads);
        console.log(`Lead ${draggableId} actualizado a estado: ${destination.droppableId}`);
        updateKanbanColumns(updatedLeads);
      } else {
        throw new Error('La respuesta del servidor no incluyó el ID del lead actualizado');
      }
    } catch (error) {
      console.error('Error updating lead state:', error);
      let errorMessage = 'Error al actualizar el estado del lead: ';
      if (error.response) {
        if (error.response.status === 404) {
          errorMessage = 'El lead no fue encontrado o no pertenece al usuario actual.';
        } else {
          errorMessage += error.response.data?.error || error.response.statusText;
        }
      } else if (error.request) {
        errorMessage += 'No se recibió respuesta del servidor';
      } else {
        errorMessage += error.message;
      }
      setError(errorMessage);
      // Revertir el cambio en el estado local si la actualización en el servidor falla
      fetchLeads();
    }
  };

  const handleNoteChange = async (id, notas) => {
    try {
      await axios.put(`/api/leads/${id}`, { notas }, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken')}` }
      });
      setLeads(leads.map(lead => lead.id === id ? { ...lead, notas } : lead));
    } catch (error) {
      console.error('Error updating lead notes:', error);
      setError('Error al actualizar las notas del lead');
    }
  };

  const handleAddNote = useCallback(async (id, content) => {
    try {
      if (!token) {
        console.error('Token no encontrado');
        setError('No se encontró el token de autenticación.');
        return;
      }
      console.log('Token usado para la solicitud:', token);
      const innerNote = JSON.stringify({ content, timestamp: new Date().toISOString() });
      const newNote = JSON.stringify({ content: innerNote, timestamp: new Date().toISOString() });
      const response = await axios.post(`/api/leads/${id}/notes`, { nota: newNote }, {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      console.log('Respuesta del servidor:', response.data);
      
      // Función auxiliar para actualizar las notas
      const updateNotas = (existingNotas) => {
        const notasArray = Array.isArray(existingNotas) ? existingNotas : [];
        return [...notasArray, newNote];
      };
      
      // Actualizar el estado de los leads
      setLeads(prevLeads => prevLeads.map(lead => 
        lead.id === id ? { ...lead, notas: updateNotas(lead.notas) } : lead
      ));
      
      // Actualizar el lead seleccionado
      setSelectedLead(prevLead => {
        if (prevLead && prevLead.id === id) {
          return { ...prevLead, notas: updateNotas(prevLead.notas) };
        }
        return prevLead;
      });
      
      // Actualizar las columnas del Kanban
      setKanbanColumns(prevColumns => {
        const updatedColumns = { ...prevColumns };
        Object.keys(updatedColumns).forEach(columnName => {
          updatedColumns[columnName] = updatedColumns[columnName].map(lead => 
            lead.id === id ? { ...lead, notas: updateNotas(lead.notas) } : lead
          );
        });
        return updatedColumns;
      });
      
      setNewNote('');
    } catch (error) {
      console.error('Error adding lead note:', error);
      setError('Error al añadir la nota al lead: ' + (error.response?.data?.error || error.message));
    }
  }, [token, setLeads, setSelectedLead, setKanbanColumns, setError, setNewNote]);

  const handleDeleteNote = async (leadId, noteIndex) => {
    try {
      await axios.delete(`/api/leads/${leadId}/notes/${noteIndex}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken')}` }
      });

      setLeads(leads.map(lead => {
        if (lead.id === leadId) {
          const updatedNotes = [...lead.notas];
          updatedNotes.splice(noteIndex, 1);
          return { ...lead, notas: updatedNotes };
        }
        return lead;
      }));

      if (selectedLead && selectedLead.id === leadId) {
        const updatedNotes = [...selectedLead.notas];
        updatedNotes.splice(noteIndex, 1);
        setSelectedLead({ ...selectedLead, notas: updatedNotes });
      }

      setError('');
    } catch (error) {
      console.error('Error deleting note:', error);
      setError('Error al eliminar la nota: ' + (error.response?.data?.error || error.message));
    }
  };

  const handleDeleteLead = async (leadId) => {
    if (window.confirm('¿Estás seguro de que quieres eliminar este lead? Esta acción no se puede deshacer.')) {
      try {
        await axios.delete(`/api/leads/${leadId}`, {
          headers: { Authorization: `Bearer ${sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken')}` }
        });
        setLeads(leads.filter(lead => lead.id !== leadId));
        setSelectedLead(null);
        setError('');
      } catch (error) {
        console.error('Error deleting lead:', error);
        setError('Error al eliminar el lead: ' + (error.response?.data?.error || error.message));
      }
    }
  };

  const renderKanbanBoard = () => {
    const columnColors = {
      'No contactado': 'bg-red-100',
      'Contactado': 'bg-yellow-100',
      'Precalificado': 'bg-orange-100',
      'En negociación': 'bg-blue-100',
      'Cerrado': 'bg-green-100'
    };

    const columnEmojis = {
      'No contactado': '📞',
      'Contactado': '💬',
      'Precalificado': '📋',
      'En negociación': '🤝',
      'Cerrado': '✅'
    };

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-3 pb-3">
          {Object.entries(kanbanColumns).map(([columnName, columnLeads]) => (
            <div key={columnName} className={`${columnColors[columnName]} rounded-lg shadow-md`}>
              <h3 className="font-bold mb-3 text-xl p-3 bg-opacity-50 rounded-t-lg flex items-center">
                <span className="mr-2">{columnEmojis[columnName]}</span>
                {columnName}
              </h3>
              <Droppable droppableId={columnName}>
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="bg-white p-2 rounded min-h-[400px] max-h-[calc(100vh-180px)] overflow-y-auto"
                  >
                    {columnLeads.map((lead, index) => (
                      <Draggable key={lead.id} draggableId={lead.id ? lead.id.toString() : `temp-${index}`} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="bg-white p-3 mb-3 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200"
                            onClick={() => setSelectedLead(lead)}
                          >
                            <p className="font-semibold text-xl mb-2">{lead.nombre}</p>
                            <p className="text-sm text-gray-600 mb-2">{lead.email}</p>
                            <p className="text-sm text-gray-500 mb-3">{lead.telefono}</p>
                            <div className="mt-3 pt-3 border-t border-gray-200">
                              <p className="text-sm text-gray-500 mb-1">Última cita: {new Date(lead.ultima_cita).toLocaleDateString()}</p>
                              <p className="text-sm text-gray-500 mb-1">Total citas: {lead.total_citas}</p>
                              <p className="text-sm text-gray-500 mb-2">
                                {lead.tiene_cita ? 'Tiene cita asignada' : 'Sin cita asignada'}
                              </p>
                            </div>
                            {lead.notas && lead.notas.length > 0 && (
                              <div className="mt-2 pt-2 border-t border-gray-200">
                                <p className="text-xs font-semibold text-gray-600">Notas:</p>
                                <ul className="list-disc list-inside">
                                  {lead.notas.slice(0, 2).map((nota, index) => {
                                    const parsedNota = JSON.parse(nota);
                                    return (
                                      <li key={index} className="text-xs text-gray-500">
                                        {parsedNota.content.substring(0, 50)}
                                        {parsedNota.content.length > 50 ? '...' : ''}
                                      </li>
                                    );
                                  })}
                                </ul>
                                {lead.notas.length > 2 && (
                                  <p className="text-xs text-blue-500 cursor-pointer" onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedLead(lead);
                                  }}>Ver más...</p>
                                )}
                              </div>
                            )}
                            <div className="mt-2 flex justify-between">
                              <button
                                className="text-xs bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600 flex items-center"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectedLead(lead);
                                }}
                              >
                                <FaComments className="mr-1" /> Notas
                              </button>
                              <button
                                className="text-xs bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600 flex items-center"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDeleteLead(lead.id);
                                }}
                              >
                                <FaTrash className="mr-1" /> Eliminar
                              </button>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          ))}
        </div>
      </DragDropContext>
    );
  };

  return (
    <div className="w-full px-2">
      <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
        <h2 className="text-3xl font-bold mb-4 sm:mb-0">Leads (CRM)</h2>
        <div className="flex flex-wrap justify-center sm:justify-end space-x-2 space-y-2 sm:space-y-0">
          <button
            onClick={() => setShowMauticConfig(true)}
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded flex items-center"
          >
            <FaCog className="mr-2" /> Configurar Mautic
          </button>
          <button
            onClick={() => setViewMode(viewMode === 'list' ? 'kanban' : 'list')}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center"
          >
            {viewMode === 'list' ? <><FaThLarge className="mr-2" /> Ver Kanban</> : <><FaList className="mr-2" /> Ver Lista</>}
          </button>
          <button
            onClick={handleDownloadCSV}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded flex items-center"
          >
            <FaDownload className="mr-2" /> Descargar CSV
          </button>
        </div>
      </div>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      {viewMode === 'list' ? (
        <>
          <div className="flex justify-between items-center mb-4">
            <input
              type="text"
              placeholder="Buscar leads..."
              className="p-2 border rounded w-1/2"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <select
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
              className="p-2 border rounded"
            >
              <option value="Todos">Todos los estados</option>
              <option value="No contactado">No contactado</option>
              <option value="Contactado">Contactado</option>
              <option value="Precalificado">Precalificado</option>
              <option value="En negociación">En negociación</option>
              <option value="Cerrado">Cerrado</option>
            </select>
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white">
              <thead>
                <tr>
                  <th className="px-4 py-2 cursor-pointer" onClick={() => requestSort('nombre')}>
                    Nombre {getSortIcon('nombre')}
                  </th>
                  <th className="px-4 py-2 cursor-pointer" onClick={() => requestSort('email')}>
                    Email {getSortIcon('email')}
                  </th>
                  <th className="px-4 py-2 cursor-pointer" onClick={() => requestSort('telefono')}>
                    Teléfono {getSortIcon('telefono')}
                  </th>
                  <th className="px-4 py-2 cursor-pointer" onClick={() => requestSort('ultima_cita')}>
                    Última cita {getSortIcon('ultima_cita')}
                  </th>
                  <th className="px-4 py-2 cursor-pointer" onClick={() => requestSort('ultimo_servicio')}>
                    Último servicio {getSortIcon('ultimo_servicio')}
                  </th>
                  <th className="px-4 py-2 cursor-pointer" onClick={() => requestSort('total_citas')}>
                    Total citas {getSortIcon('total_citas')}
                  </th>
                  <th className="px-4 py-2 cursor-pointer" onClick={() => requestSort('dias_desde_ultima_cita')}>
                    Días desde última cita {getSortIcon('dias_desde_ultima_cita')}
                  </th>
                  <th className="px-4 py-2 cursor-pointer" onClick={() => requestSort('dias_como_cliente')}>
                    Días como cliente {getSortIcon('dias_como_cliente')}
                  </th>
                  <th className="px-4 py-2 cursor-pointer" onClick={() => requestSort('estado')}>
                    Estado {getSortIcon('estado')}
                  </th>
                  <th className="px-4 py-2">Contactos</th>
                  <th className="px-4 py-2">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {filteredLeads
                  .filter(lead => statusFilter === 'Todos' || lead.estado === statusFilter)
                  .map((lead, index) => (
                  <tr key={index} className={`${statusColors[lead.estado] || 'bg-white'}`}>
                    <td className="border px-4 py-2">{lead.nombre}</td>
                    <td className="border px-4 py-2">{lead.email}</td>
                    <td className="border px-4 py-2">{lead.telefono || 'No disponible'}</td>
                    <td className="border px-4 py-2">{lead.ultima_cita ? new Date(lead.ultima_cita).toLocaleDateString() : 'Sin citas'}</td>
                    <td className="border px-4 py-2 relative">
                      {lead.es_de_pago && <span role="img" aria-label="billete" className="absolute top-0 right-0">💵</span>}
                      {lead.ultimo_servicio} {lead.es_de_pago && <span className="ml-2">{lead.precio} €</span>}
                    </td>
                    <td className="border px-4 py-2">{lead.total_citas}</td>
                    <td className="border px-4 py-2">{lead.dias_desde_ultima_cita}</td>
                    <td className="border px-4 py-2">{lead.dias_como_cliente}</td>
                    <td className="border px-4 py-2">
                      <span className={`px-2 py-1 rounded-full text-xs font-semibold
                        ${lead.estado === 'No contactado' ? 'bg-red-200 text-red-800' :
                          lead.estado === 'Contactado' ? 'bg-yellow-200 text-yellow-800' :
                          lead.estado === 'Precalificado' ? 'bg-orange-200 text-orange-800' :
                          lead.estado === 'En negociación' ? 'bg-blue-200 text-blue-800' :
                          lead.estado === 'Cerrado' ? 'bg-green-200 text-green-800' :
                          'bg-gray-200 text-gray-800'}`}>
                        {lead.estado}
                      </span>
                    </td>
                    <td className="border px-4 py-2">
                      {lead.contactos && lead.contactos.length > 0 ? (
                        <ul>
                          {lead.contactos.map((contacto, idx) => (
                            <li key={idx}>{contacto.nombre} - {contacto.email}</li>
                          ))}
                        </ul>
                      ) : 'Sin contactos'}
                    </td>
                    <td className="border px-4 py-2">
                      <div className="flex justify-center space-x-2">
                        <button
                          onClick={() => setSelectedLead(lead)}
                          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded text-xs"
                        >
                          <FaComments className="inline mr-1" /> Notas
                        </button>
                        <button
                          onClick={() => handleDeleteLead(lead.id)}
                          className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded text-xs"
                        >
                          <FaTrash className="inline mr-1" /> Eliminar
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="mt-6 bg-white p-4 rounded-lg shadow">
            <h3 className="text-xl font-bold mb-4">Estadísticas generales</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <p><strong>Total de leads:</strong> {leads.length}</p>
              <p><strong>Promedio de citas por lead:</strong> {(leads.reduce((sum, lead) => sum + lead.total_citas, 0) / leads.length).toFixed(2)}</p>
              <p><strong>Lead más antiguo:</strong> {leads.length > 0 ? `${leads.reduce((oldest, lead) => lead.dias_como_cliente > oldest.dias_como_cliente ? lead : oldest).nombre} (${leads.reduce((oldest, lead) => lead.dias_como_cliente > oldest.dias_como_cliente ? lead : oldest).dias_como_cliente} días)` : 'N/A'}</p>
              <p><strong>Lead más reciente:</strong> {leads.length > 0 ? `${leads.reduce((newest, lead) => lead.dias_como_cliente < newest.dias_como_cliente ? lead : newest).nombre} (${leads.reduce((newest, lead) => lead.dias_como_cliente < newest.dias_como_cliente ? lead : newest).dias_como_cliente} días)` : 'N/A'}</p>
            </div>
          </div>
          <div className="mt-6 bg-white p-4 rounded-lg shadow">
            <h3 className="text-xl font-bold mb-4">Leyenda de estados</h3>
            <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
              {Object.entries(statusColors).map(([status, color]) => (
                <div key={status} className={`${color} p-2 rounded flex items-center justify-center`}>
                  {status}
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        renderKanbanBoard()
      )}
      {selectedLead && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" onClick={() => setSelectedLead(null)}>
          <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white" onClick={e => e.stopPropagation()}>
            <div className="mt-3">
              <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">{selectedLead.nombre}</h3>
              <div className="mt-2 px-7 py-3">
                <p className="text-sm text-gray-500 mb-1">
                  <strong>Email:</strong> {selectedLead.email}
                </p>
                <p className="text-sm text-gray-500 mb-1">
                  <strong>Teléfono:</strong> {selectedLead.telefono || 'No disponible'}
                </p>
                <p className="text-sm text-gray-500 mb-1">
                  <strong>Última cita:</strong> {selectedLead.ultima_cita ? new Date(selectedLead.ultima_cita).toLocaleDateString() : 'Sin citas'}
                </p>
                <p className="text-sm text-gray-500 mb-4">
                  <strong>Total citas:</strong> {selectedLead.total_citas}
                </p>
                <div className="mt-4">
                  <h4 className="font-semibold mb-2">Notas:</h4>
                  {selectedLead.notas && (Array.isArray(selectedLead.notas) ? selectedLead.notas : [selectedLead.notas]).map((nota, index) => {
                    let parsedNota, formattedDate;
                    try {
                      const outerParsed = typeof nota === 'string' ? JSON.parse(nota) : nota;
                      parsedNota = typeof outerParsed.content === 'string' ? JSON.parse(outerParsed.content) : outerParsed;
                      formattedDate = new Date(parsedNota.timestamp).toLocaleString();
                    } catch (error) {
                      console.error('Error parsing note:', error);
                      parsedNota = { content: 'Error al cargar la nota', timestamp: new Date() };
                      formattedDate = 'Fecha desconocida';
                    }
                    return (
                      <div key={index} className="flex flex-col mb-4 border-b pb-2">
                        <div className="flex justify-between items-start mb-1">
                          <p className="text-sm text-gray-600">{parsedNota.content}</p>
                          <button
                            onClick={() => handleDeleteNote(selectedLead.id, index)}
                            className="text-red-500 hover:text-red-700 ml-2"
                          >
                            Eliminar
                          </button>
                        </div>
                        <p className="text-xs text-gray-400">{formattedDate}</p>
                      </div>
                    );
                  })}
                </div>
                <textarea
                  className="mt-4 w-full p-2 border rounded"
                  rows="4"
                  placeholder="Añadir nueva nota..."
                  value={newNote}
                  onChange={(e) => setNewNote(e.target.value)}
                ></textarea>
                <button
                  onClick={() => {
                    handleAddNote(selectedLead.id, newNote);
                  }}
                  className="mt-2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                >
                  Añadir Nota
                </button>
              </div>
              <div className="items-center px-4 py-3">
                <div className="flex justify-between">
                  <button
                    onClick={() => handleDeleteLead(selectedLead.id)}
                    className="px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-300"
                  >
                    Eliminar Lead
                  </button>
                  <button
                    onClick={() => setSelectedLead(null)}
                    className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
                  >
                    Cerrar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showMauticConfig && (
        <MauticConfig
          isOpen={showMauticConfig}
          onClose={() => setShowMauticConfig(false)}
        />
      )}
    </div>
  );
}

export default Leads;

