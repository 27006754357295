const uploadDocument = async (file, datasetId, metadata = {}) => {
  console.log('Iniciando uploadDocument con datasetId:', datasetId);

  const DIFY_HOST = process.env.REACT_APP_DIFY_HOST;
  const DIFY_API_KEY = process.env.REACT_APP_DIFY_API_KEY;

  if (!DIFY_HOST || !DIFY_API_KEY) {
    throw new Error('DIFY_HOST o DIFY_API_KEY no están configurados');
  }

  const processRule = {
    rules: {
      pre_processing_rules: [
        { id: "remove_extra_spaces", enabled: true },
        { id: "remove_urls_emails", enabled: true }
      ],
      segmentation: {
        separator: "###",
        max_tokens: 500
      }
    },
    mode: "custom"
  };

  try {
    const url = `${DIFY_HOST}/v1/datasets/${datasetId}/document/create_by_file`;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('data', JSON.stringify({
      name: file.name,
      indexing_technique: 'high_quality',
      process_rule: processRule,
      ...metadata
    }));

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${DIFY_API_KEY}`,
      },
      body: formData
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error('Error response:', errorData);
      throw new Error(`Error al subir el documento: ${response.status} - ${JSON.stringify(errorData)}`);
    }

    const responseData = await response.json();
    console.log('Respuesta del servidor:', response.status, response.statusText);
    console.log('Datos de respuesta:', responseData);
    return responseData;
  } catch (error) {
    console.error('Error al subir el archivo:', error);
    throw error;
  }
};

const createDocumentByText = async (datasetId, name, text) => {
  console.log('Iniciando createDocumentByText con datasetId:', datasetId);

  const DIFY_HOST = process.env.DIFY_HOST || process.env.REACT_APP_DIFY_HOST;
  const DIFY_API_KEY = process.env.DIFY_API_KEY || process.env.REACT_APP_DIFY_API_KEY;

  if (!DIFY_HOST || !DIFY_API_KEY) {
    throw new Error('DIFY_HOST o DIFY_API_KEY no están configurados');
  }

  const processRule = {
    rules: {
      pre_processing_rules: [
        { id: "remove_extra_spaces", enabled: true },
        { id: "remove_urls_emails", enabled: true }
      ],
      segmentation: {
        separator: "###",
        max_tokens: 500
      }
    },
    mode: "custom"
  };

  try {
    const url = `${DIFY_HOST}/v1/datasets/${datasetId}/document/create_by_text`;
    const data = {
      name,
      text,
      indexing_technique: 'high_quality',
      process_rule: processRule
    };

    const response = await axios.post(url, data, {
      headers: {
        'Authorization': `Bearer ${DIFY_API_KEY}`,
        'Content-Type': 'application/json'
      }
    });

    console.log('Respuesta del servidor:', response.status, response.statusText);
    console.log('Datos de respuesta:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error al crear el documento por texto:', error);
    if (error.response) {
      console.error('Respuesta de error del servidor:', error.response.status, error.response.data);
      throw new Error(`Error al crear el documento: ${error.response.status} - ${JSON.stringify(error.response.data)}`);
    } else if (error.request) {
      throw new Error('Error al crear el documento: No se recibió respuesta del servidor');
    } else {
      throw new Error(`Error al configurar la solicitud: ${error.message}`);
    }
  }
};

module.exports = { uploadDocument, createDocumentByText };
