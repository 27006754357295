import React, { useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Login({ setIsLoggedIn, setIsAdmin }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post('/api/usuarios/login', { email, password });
      const { shortToken, es_admin } = response.data;
      localStorage.setItem('shortToken', shortToken);
      localStorage.setItem('isAdmin', es_admin);
      setIsLoggedIn(true);
      setIsAdmin(es_admin);
      toast.success('Inicio de sesión exitoso');
      history.push('/');
    } catch (error) {
      console.error('Login error:', error);
      toast.error('Error de login: ' + (error.response?.data?.error || error.message));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-no-repeat bg-fixed" style={{backgroundImage: "url('https://images.unsplash.com/photo-1449247709967-d4461a6a6103?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&dl=bench-accounting-nvzvOPQW0gc-unsplash.jpg')", height: '100vh', width: '100vw', backgroundSize: 'cover', backgroundPosition: 'center', backgroundAttachment: 'fixed'}}>
      <ToastContainer />
      <div className="w-full max-w-md bg-white bg-opacity-90 rounded-lg shadow-md p-8">
        <div className="text-4xl font-bold text-center text-blue-600 mb-2">CitasPro</div>
        <div className="text-lg text-center text-gray-600 mb-8">by Nimbox360</div>
        <h1 className="text-3xl font-bold text-center text-gray-800 mb-8">Iniciar Sesión</h1>
        <form onSubmit={handleLogin} className="space-y-6">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
              Email o username
            </label>
            <input
              type="text"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
              Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
          <button
            type="submit"
            disabled={isLoading}
            className={`w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
              isLoading ? 'bg-blue-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'
            } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
          >
            {isLoading ? 'Cargando...' : 'Adelante'}
          </button>
        </form>
      </div>
    </div>
  );
}

export default Login;
