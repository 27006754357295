import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, NavLink, Redirect } from 'react-router-dom';
import { FaUsers, FaClock, FaCalendarAlt, FaSignOutAlt, FaCog, FaChartBar, FaUser, FaAddressBook, FaRobot, FaBars, FaTimes } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LeadsEmbed from './components/LeadsEmbed';
import Usuarios from './components/Usuarios';
import Horarios from './components/Horarios';
import Citas from './components/Citas';
import Servicios from './components/Servicios';
import Leads from './components/Leads';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import UserProfile from './components/UserProfile';
import AI from './components/AI';
import Integraciones from './components/Integraciones';
import uploadDocumentRouter from './routes/uploadDocument';
import axios from 'axios';

// Configurar axios para incluir la ruta base
axios.defaults.baseURL = 'https://supabase.nimbox360.com';

// Configurar axios para incluir el token en todas las peticiones, excepto para /ai y KnowledgeBase
axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('shortToken');
    // La siguiente línea ha sido comentada para desactivar la adición automática del token
    // if (token && !config.url.includes('/ai') && !config.url.includes('KnowledgeBase')) {
    //   config.headers['Authorization'] = `Bearer ${token}`;
    // }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Configurar la ruta para DocumentUpload
axios.defaults.baseURL = process.env.REACT_APP_API_URL || 'https://citaspro.cloud';
axios.interceptors.request.use(config => {
  if (config.url === '/api/uploadDocument') {
    config.baseURL = process.env.REACT_APP_API_URL || 'https://citaspro.cloud';
  }
  return config;
}, error => {
  return Promise.reject(error);
});

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [error, setError] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  useEffect(() => {
    const token = localStorage.getItem('shortToken');
    const userIsAdmin = localStorage.getItem('isAdmin') === 'true';
    setIsLoggedIn(!!token);
    setIsAdmin(userIsAdmin);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('shortToken');
    localStorage.removeItem('isAdmin');
    setIsLoggedIn(false);
    setIsAdmin(false);
    window.location.href = '/login';
  };

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!isLoggedIn && window.location.pathname !== '/nimboxtalk_embed') {
    return (
      <Router>
        <div className="App flex h-screen bg-gray-100">
          <div className="flex-1 flex items-center justify-center">
            <Route path="/" render={(props) => <Login {...props} setIsLoggedIn={setIsLoggedIn} setIsAdmin={setIsAdmin} />} />
          </div>
        </div>
      </Router>
    );
  }

  return (
    <Router>
      <div className="App flex flex-col md:flex-row min-h-screen bg-gray-100">
        <Switch>
          <Route path="/nimboxtalk_embed" render={(props) => {
            const urlParams = new URLSearchParams(props.location.search);
            const token = urlParams.get('token');
            if (!token) {
              return <Redirect to="/login" />;
            }
            return <LeadsEmbed token={token} />;
          }} />
          <Route>
            <>
              <button 
                className="md:hidden fixed top-6 left-2 z-50 text-gray-700 hover:text-blue-600 bg-white rounded-full p-2 shadow-md"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              >
                {isMenuOpen ? <FaTimes size={18} /> : <FaBars size={18} />}
              </button>
              <nav className={`w-64 bg-white shadow-lg fixed inset-y-0 left-0 z-40 transform ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'} md:relative md:translate-x-0 transition duration-200 ease-in-out`}>
                <div className="p-5 h-full overflow-y-auto">
                  <h1 className="text-2xl font-bold text-blue-600 mb-0">CitasPro</h1>
                  <p className="text-xs text-gray-500 mb-6 mt-1">by Nimbox360</p>
                  <ul className="space-y-2">
                    <li>
                      <NavLink to="/" exact className="flex items-center p-2 text-gray-700 hover:bg-blue-100 rounded transition-colors duration-200" activeClassName="bg-blue-100 text-blue-600">
                        <FaChartBar className="mr-3" />
                        Dashboard
                      </NavLink>
                    </li>
                    {isAdmin && (
                      <li>
                        <NavLink to="/usuarios" className="flex items-center p-2 text-gray-700 hover:bg-blue-100 rounded transition-colors duration-200" activeClassName="bg-blue-100 text-blue-600">
                          <FaUsers className="mr-3" />
                          Usuarios
                        </NavLink>
                      </li>
                    )}
                    <li>
                      <NavLink to="/horarios" className="flex items-center p-2 text-gray-700 hover:bg-blue-100 rounded transition-colors duration-200" activeClassName="bg-blue-100 text-blue-600">
                        <FaClock className="mr-3" />
                        Horarios
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/citas" className="flex items-center p-2 text-gray-700 hover:bg-blue-100 rounded transition-colors duration-200" activeClassName="bg-blue-100 text-blue-600">
                        <FaCalendarAlt className="mr-3" />
                        Citas
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/servicios" className="flex items-center p-2 text-gray-700 hover:bg-blue-100 rounded transition-colors duration-200" activeClassName="bg-blue-100 text-blue-600">
                        <FaCog className="mr-3" />
                        Servicios
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/profile" className="flex items-center p-2 text-gray-700 hover:bg-blue-100 rounded transition-colors duration-200" activeClassName="bg-blue-100 text-blue-600">
                        <FaUser className="mr-3" />
                        Perfil
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/leads" className="flex items-center p-2 text-gray-700 hover:bg-blue-100 rounded transition-colors duration-200" activeClassName="bg-blue-100 text-blue-600">
                        <FaAddressBook className="mr-3" />
                        Leads
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/ai" className="flex items-center p-2 text-gray-700 hover:bg-blue-100 rounded transition-colors duration-200" activeClassName="bg-blue-100 text-blue-600">
                        <FaRobot className="mr-3" />
                        AI
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/integraciones" className="flex items-center p-2 text-gray-700 hover:bg-blue-100 rounded transition-colors duration-200" activeClassName="bg-blue-100 text-blue-600">
                        <FaCog className="mr-3" />
                        Integraciones
                      </NavLink>
                    </li>
                    <li>
                      <button onClick={handleLogout} className="flex items-center p-2 text-gray-700 hover:bg-blue-100 rounded transition-colors duration-200">
                        <FaSignOutAlt className="mr-3" />
                        Logout
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="absolute bottom-0 w-64 p-5 text-sm text-gray-500">
                  <p>&copy; 2023 CitasPro. Todos los derechos reservados.</p>
                </div>
              </nav>
              <div className="flex-1 p-4 md:p-10 mt-16 md:mt-0">
                <Switch>
                  <Route exact path="/" component={Dashboard} />
                  {isAdmin && <Route path="/usuarios" component={Usuarios} />}
                  <Route path="/horarios" component={Horarios} />
                  <Route path="/citas" component={Citas} />
                  <Route path="/servicios" component={Servicios} />
                  <Route path="/profile" component={UserProfile} />
                  <Route path="/leads" component={Leads} />
                  <Route path="/ai" component={AI} />
                  <Route path="/integraciones" component={Integraciones} />
                  <Route path="/api/uploadDocument" component={uploadDocumentRouter} />
                  <Redirect to="/" />
                </Switch>
              </div>
            </>
          </Route>
        </Switch>
      </div>
      <ToastContainer position="bottom-right" />
    </Router>
  );
}

export default App;
