import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PromptAndCompanyData from './PromptAndCompanyData';
import { toast } from 'react-toastify';
import KnowledgeBase from './KnowledgeBase';
import WhatsAppIntegration from './WhatsAppIntegration';

const DIFY_HOST = process.env.REACT_APP_DIFY_HOST;
const DIFY_API_KEY = process.env.REACT_APP_DIFY_API_KEY;

if (!DIFY_HOST || !DIFY_API_KEY) {
  console.error('DIFY_HOST o DIFY_API_KEY no están definidos en las variables de entorno');
}
const WEBHOOK_URL = 'https://webhook.nimboxtalk.chat/webhook/bbfce48a-d516-44ac-967d-a77a97321190';

if (!DIFY_HOST || !DIFY_API_KEY) {
  console.error('REACT_APP_DIFY_HOST o REACT_APP_DIFY_API_KEY no están definidos en las variables de entorno');
}

function AI() {
  const [shortToken, setShortToken] = useState('');
  const [step, setStep] = useState(1);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [promptText, setPromptText] = useState('');
  const [companyData, setCompanyData] = useState({
    name: '',
    description: '',
    contact: '',
    email: ''
  });
  const [updateKnowledgeBase, setUpdateKnowledgeBase] = useState(false);
  const [botPersonalization, setBotPersonalization] = useState({
    name: '',
    tone: ''
  });
  const [prompts, setPrompts] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const [isDeploying, setIsDeploying] = useState(false);
  const [deployMessage, setDeployMessage] = useState('');

  useEffect(() => {
    const storedShortToken = localStorage.getItem('shortToken') || sessionStorage.getItem('shortToken');
    if (storedShortToken) {
      setShortToken(storedShortToken);
    } else {
      console.error('No se encontró el shortToken en el almacenamiento del navegador');
      toast.error('No se pudo autenticar. Por favor, inicie sesión nuevamente.');
      // Aquí podrías agregar lógica para redirigir al usuario a la página de inicio de sesión
    }
  }, []);

  useEffect(() => {
    if (shortToken) {
      // Verificar la validez del token
      axios.get('/api/verify-token', {
        headers: { Authorization: `Bearer ${shortToken}` }
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          console.error('Token inválido o expirado');
          toast.error('Su sesión ha expirado. Por favor, inicie sesión nuevamente.');
          // Limpiar el token inválido
          localStorage.removeItem('shortToken');
          sessionStorage.removeItem('shortToken');
          setShortToken('');
          // Aquí podrías agregar lógica para redirigir al usuario a la página de inicio de sesión
        }
      });
    }
  }, [shortToken]);

  useEffect(() => {
    if (shortToken) {
      loadPromptsAndTemplates();
    }
  }, [shortToken]);

  const loadPromptsAndTemplates = async () => {
    try {
      const response = await axios.get('/api/prompts-and-templates', {
        headers: { Authorization: `Bearer ${shortToken}` }
      });
      setPrompts(response.data.prompts);
      setTemplates(response.data.templates);
      
      // Cargar la plantilla por defecto
      if (response.data.templates.length > 0) {
        const defaultTemplate = response.data.templates.find(t => t.isDefault) || response.data.templates[0];
        handleLoadTemplate(defaultTemplate);
      }
      
      toast.success('Prompts y plantillas cargados exitosamente');
    } catch (error) {
      toast.error(`Error al cargar prompts y plantillas: ${error.message}`);
    }
  };

  const handleLoadTemplate = async (template) => {
    if (template) {
      setSelectedTemplate(template);
      setLocalPromptText(template.prompt || '');
      setLocalCompanyData({
        name: template.companyData?.name || '',
        description: template.companyData?.description || '',
        contact: template.companyData?.contact || '',
        email: template.companyData?.email || '',
        botName: template.companyData?.botName || ''
      });
      setTone(template.tone || 'Neutro');
    } else {
      setSelectedTemplate(null);
      setLocalPromptText('');
      setLocalCompanyData({ name: '', description: '', contact: '', email: '', botName: '' });
      setTone('Neutro');
    }
  };

  const handleSetDefaultTemplate = async (template) => {
    try {
      const response = await axios.post('/api/set-default-template', { templateName: template.name }, {
        headers: { Authorization: `Bearer ${shortToken}` }
      });
      if (response.data.success) {
        toast.success('Plantilla establecida como predeterminada');
        loadPromptsAndTemplates(); // Recargar las plantillas para reflejar el cambio
      } else {
        throw new Error(response.data.message || 'Error al establecer la plantilla predeterminada');
      }
    } catch (error) {
      console.error('Error al establecer la plantilla predeterminada:', error);
      toast.error(`Error: ${error.message}`);
    }
  };

  const DefaultTemplateButton = ({ template, onClick }) => (
    <button
      onClick={() => onClick(template)}
      className="px-3 py-1 bg-green-500 text-white rounded-full text-sm font-medium hover:bg-green-600 transition duration-300 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
    >
      Usar como plantilla por defecto
    </button>
  );

  const saveCurrentData = async (data) => {
    try {
      const { promptText, companyData, botPersonalization } = data;
      const templateName = botPersonalization.name || 'Plantilla sin nombre';
      
      if (!promptText.trim() && !companyData.name.trim() && !companyData.description.trim() && 
          !companyData.contact.trim() && !companyData.email.trim() && !botPersonalization.tone.trim()) {
        toast.warning('No se puede guardar una plantilla vacía. Por favor, complete al menos un campo.');
        return;
      }

      const replaceVariables = (text) => {
        return text.replace(/{{(\w+)}}/g, (_, key) => {
          switch(key) {
            case 'companyName': return companyData.name;
            case 'companyDescription': return companyData.description;
            case 'companyContact': return companyData.contact;
            case 'companyEmail': return companyData.email;
            case 'botName': return botPersonalization.name;
            case 'tone': return botPersonalization.tone;
            default: return `{{${key}}}`;
          }
        });
      };

      const interpretedPrompt = replaceVariables(promptText);

      await axios.post('/api/save-template', {
        name: templateName,
        tone: botPersonalization.tone,
        prompt: interpretedPrompt,
        companyData: companyData,
      }, {
        headers: { Authorization: `Bearer ${shortToken}` }
      });
      
      toast.success('Datos guardados correctamente');
      setDeployMessage('Cambios guardados');
      
      loadPromptsAndTemplates();
    } catch (error) {
      toast.error(`Error al guardar los datos: ${error.message}`);
      setDeployMessage('Hubo un error al guardar los cambios. Por favor, inténtelo de nuevo.');
    }
  };

  const handleDeployBot = async () => {
    setIsDeploying(true);
    setDeployMessage('');
    try {
      if (!shortToken) {
        throw new Error('Short token no disponible');
      }

      // Obtener la plantilla predeterminada
      const response = await axios.get('/api/prompts-and-templates', {
        headers: { Authorization: `Bearer ${shortToken}` }
      });
      
      const defaultTemplate = response.data.templates.find(t => t.isDefault) || {};

      const payload = {
        UserID: shortToken,
        companyName: companyData.name,
        companyDescription: companyData.description,
        companyContact: companyData.contact,
        companyEmail: companyData.email,
        botName: botPersonalization.name,
        tone: botPersonalization.tone,
        prompt: promptText,
        defaultTemplate: {
          name: defaultTemplate.name || '',
          prompt: defaultTemplate.prompt || '',
          tone: defaultTemplate.tone || '',
          companyData: defaultTemplate.company_data || {}
        }
      };

      await axios.post(WEBHOOK_URL, payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      await axios.post('/api/save-prompt', {
        text: promptText,
        companyName: companyData.name
      }, {
        headers: { Authorization: `Bearer ${shortToken}` }
      });

      setDeployMessage('El bot se actualizará en unos instantes.');
    } catch (error) {
      console.error('Error al desplegar el bot:', error);
      setDeployMessage('Hubo un error al desplegar el bot. Por favor, inténtelo de nuevo.');
    } finally {
      setIsDeploying(false);
    }
  };


  const handleSaveTemplate = async (newTemplate) => {
    try {
      if (!newTemplate.name || !promptText.trim()) {
        toast.error('El nombre de la plantilla y el texto del prompt son obligatorios');
        return;
      }

      const templateToSave = {
        name: newTemplate.name,
        prompt: promptText.trim(),
        companyData: {
          name: companyData.name || '',
          description: companyData.description || '',
          contact: companyData.contact || '',
          email: companyData.email || ''
        },
        botPersonalization: {
          name: botPersonalization.name || '',
          tone: botPersonalization.tone || ''
        },
        tone: botPersonalization.tone || ''
      };

      const response = await axios.post('/api/save-template', templateToSave, {
        headers: { Authorization: `Bearer ${shortToken}` }
      });

      if (response.data.success) {
        const updatedTemplates = [...templates, templateToSave];
        setTemplates(updatedTemplates);
        toast.success('Plantilla guardada correctamente');
      } else {
        throw new Error(response.data.message || 'Error desconocido al guardar la plantilla');
      }
    } catch (error) {
      console.error('Error al guardar la plantilla:', error);
      if (error.response && error.response.status === 401) {
        toast.error('Error de autenticación. Por favor, inicie sesión nuevamente.');
        // Aquí podrías agregar lógica para redirigir al usuario a la página de inicio de sesión
      } else if (error.response && error.response.data && error.response.data.error === 'Token inválido') {
        toast.error('Su sesión ha expirado. Por favor, inicie sesión nuevamente.');
        // Aquí podrías agregar lógica para redirigir al usuario a la página de inicio de sesión
      } else {
        toast.error(`Error al guardar la plantilla: ${error.response?.data?.message || error.message}`);
      }
    }
  };

  const handleDeleteTemplate = async (templateName) => {
    try {
      await axios.delete(`/api/delete-template/${encodeURIComponent(templateName)}`, {
        headers: { Authorization: `Bearer ${shortToken}` }
      });
      const updatedTemplates = templates.filter(t => t.name !== templateName);
      setTemplates(updatedTemplates);
      setSelectedTemplate(null);
      toast.success('Plantilla eliminada correctamente');
    } catch (error) {
      console.error('Error al eliminar la plantilla:', error);
      toast.error('Error al eliminar la plantilla');
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handlePromptChange = (newPrompt) => {
    setPromptText(newPrompt);
  };

  const handleCompanyDataChange = (newData) => {
    setCompanyData(newData);
  };

  const handleNextStep = () => {
    setStep(prevStep => prevStep + 1);
    if (step === 1) {
      setUpdateKnowledgeBase(prev => !prev);
    }
  };

  const handlePrevStep = () => {
    setStep(prevStep => prevStep - 1);
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return <PromptAndCompanyData 
          promptText={promptText}
          companyData={companyData}
          botPersonalization={botPersonalization}
          onPromptChange={handlePromptChange}
          onCompanyDataChange={handleCompanyDataChange}
          onBotPersonalizationChange={handleBotPersonalizationChange}
          templates={templates}
          onLoadTemplate={handleLoadTemplate}
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={setSelectedTemplate}
          saveCurrentData={saveCurrentData}
          onSaveTemplate={handleSaveTemplate}
          onDeleteTemplate={handleDeleteTemplate}
          onSetDefaultTemplate={handleSetDefaultTemplate}
          DefaultTemplateButton={DefaultTemplateButton}
        />;
      case 2:
        return <KnowledgeBase key={updateKnowledgeBase} />;
      case 3:
        return <WhatsAppIntegration />;
      default:
        return null;
    }
  };

  const handleBotPersonalizationChange = (newData) => {
    setBotPersonalization(newData);
  };

  return (
    <div className="w-full p-4 md:p-8 bg-gray-100 min-h-screen">
      <h1 className="text-3xl md:text-4xl font-bold mb-6 md:mb-8 text-center text-blue-600">Configuración del Agente IA</h1>
      
      <div className="mb-6 flex justify-center">
        <div className="flex items-center">
          {[1, 2, 3].map((stepNumber) => (
            <React.Fragment key={stepNumber}>
              <div className={`w-8 h-8 rounded-full flex items-center justify-center ${step === stepNumber ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-600'}`}>
                {stepNumber}
              </div>
              {stepNumber < 3 && <div className="w-16 h-1 bg-gray-300 mx-2"></div>}
            </React.Fragment>
          ))}
        </div>
      </div>

      {renderStep()}

      <div className="mt-6 flex justify-between">
        {step > 1 && (
          <button
            onClick={handlePrevStep}
            className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 transition duration-300"
          >
            Anterior
          </button>
        )}
        {step < 3 ? (
          <button
            onClick={handleNextStep}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300 ml-auto"
          >
            Siguiente
          </button>
        ) : (
          <button
            onClick={handleDeployBot}
            disabled={isDeploying}
            className={`px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition duration-300 ml-auto ${isDeploying ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            {isDeploying ? 'Desplegando...' : 'Desplegar Bot'}
          </button>
        )}
      </div>
      {deployMessage && (
        <div className="mt-4 p-4 bg-blue-100 text-blue-700 rounded">
          {deployMessage}
        </div>
      )}
    </div>
  );
}

export default AI;
