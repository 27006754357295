import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faAddressBook, faComments } from '@fortawesome/free-solid-svg-icons';

function WhatsAppIntegration() {
  const [qrCode, setQrCode] = useState('');
  const [connectionStatus, setConnectionStatus] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [profileName, setProfileName] = useState('');
  const [profilePictureUrl, setProfilePictureUrl] = useState('');
  const [error, setError] = useState('');
  const [messageCount, setMessageCount] = useState(0);
  const [contactCount, setContactCount] = useState(0);
  const [chatCount, setChatCount] = useState(0);

  const evolutionHost = process.env.REACT_APP_EVOLUTION_HOST;
  const evolutionKey = process.env.REACT_APP_EVOLUTION_KEY;

  useEffect(() => {
    const shortToken = sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken');
    if (shortToken) {
      createInstance(shortToken);
    }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (connectionStatus !== 'open') {
        refreshQRCode();
      }
    }, 40000);

    return () => clearInterval(intervalId);
  }, [connectionStatus]);

  const createInstance = async (shortToken) => {
    const instanceName = `CitasPro_${shortToken}`;
    try {
      const response = await axios.get(`${evolutionHost}/instance/connect/${instanceName}`, {
        headers: { 'apikey': evolutionKey }
      });

      if (response.data && response.data.base64) {
        setQrCode(response.data.base64);
      }

      checkConnectionStatus(instanceName);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // La instancia no existe, intentamos crearla
        try {
          const createResponse = await axios.post(`${evolutionHost}/instance/create`, {
            instanceName: instanceName,
            qrcode: true,
            integration: "WHATSAPP-BAILEYS"
          }, {
            headers: { 'apikey': evolutionKey }
          });

          if (createResponse.data && createResponse.data.qrcode && createResponse.data.qrcode.base64) {
            setQrCode(createResponse.data.qrcode.base64);
          }

          checkConnectionStatus(instanceName);
        } catch (createError) {
          console.error('Error al crear la instancia:', createError);
          setError('Error al crear la instancia. Por favor, intente de nuevo.');
        }
      } else {
        console.error('Error al conectar con la instancia:', error);
        setError('Error al conectar con la instancia. Por favor, intente de nuevo.');
      }
    }
  };

  const refreshQRCode = async () => {
    const shortToken = sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken');
    if (shortToken) {
      createInstance(shortToken);
    }
  };

  const checkConnectionStatus = async (instanceName) => {
    try {
      const response = await axios.get(`${evolutionHost}/instance/connectionState/${instanceName}`, {
        headers: { 'apikey': evolutionKey }
      });

      if (response.data) {
        const state = response.data.instance?.state || response.data.state;
        setConnectionStatus(state);
        if (state === 'open') {
          fetchInstanceInfo(instanceName);
        } else if (state !== 'close') {
          setTimeout(() => checkConnectionStatus(instanceName), 5000);
        }
      } else {
        throw new Error('Respuesta inesperada del servidor');
      }
    } catch (error) {
      console.error('Error al verificar el estado de conexión:', error);
      setError('Error al verificar el estado de conexión. Por favor, intente de nuevo.');
      setTimeout(() => checkConnectionStatus(instanceName), 5000);
    }
  };

  const fetchInstanceInfo = async (instanceName) => {
    try {
      const response = await axios.get(`${evolutionHost}/instance/fetchInstances`, {
        headers: { 'apikey': evolutionKey }
      });

      const instance = response.data.find(inst => inst.name === instanceName);
      if (instance) {
        setConnectionStatus(instance.connectionStatus);
        setPhoneNumber(instance.ownerJid.split('@')[0]);
        setProfileName(instance.profileName);
        setProfilePictureUrl(instance.profilePicUrl);
        setMessageCount(instance._count.Message);
        setContactCount(instance._count.Contact);
        setChatCount(instance._count.Chat);
      } else {
        console.error('No se encontró la instancia o la información está incompleta');
      }
    } catch (error) {
      console.error('Error al obtener la información de la instancia:', error);
      setError('Error al obtener la información de la instancia. Por favor, intente de nuevo.');
    }
  };

  const handleCheckStatus = () => {
    const shortToken = sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken');
    if (shortToken) {
      checkConnectionStatus(`CitasPro_${shortToken}`);
    } else {
      setError('No se encontró el token de autenticación');
    }
  };

  const handleDisconnect = async () => {
    const shortToken = sessionStorage.getItem('shortToken') || localStorage.getItem('shortToken');
    if (shortToken) {
      try {
        const response = await fetch(`${process.env.REACT_APP_EVOLUTION_HOST}/instance/logout/CitasPro_${shortToken}`, {
          method: 'DELETE',
          headers: { 'apikey': process.env.REACT_APP_EVOLUTION_KEY }
        });
        const data = await response.json();
        if (data.status === 'SUCCESS' && !data.error) {
          setConnectionStatus('');
          setPhoneNumber('');
          setProfileName('');
          setProfilePictureUrl('');
          setMessageCount(0);
          setContactCount(0);
          setChatCount(0);
          setError('');
          // Volver a mostrar el código QR después de desconectar
          createInstance(shortToken);
        } else {
          setError('Error al desconectar la instancia');
        }
      } catch (error) {
        console.error('Error al desconectar:', error);
        setError('Error al desconectar la instancia');
      }
    } else {
      setError('No se encontró el token de autenticación');
    }
  };

  return (
    <div className="whatsapp-integration bg-white shadow-lg rounded-lg p-6 max-w-xl mx-auto">
      <h2 className="text-2xl font-bold mb-6 text-center text-gray-800">Integración con WhatsApp</h2>
      {error && (
        <div className="error-message mb-4 p-3 bg-red-100 text-red-700 rounded">
          {error}
        </div>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="connection-info">
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2 text-gray-700">Estado de conexión</h3>
            <p className={`font-medium ${connectionStatus === 'open' ? 'text-green-600' : 'text-red-600'}`}>
              {connectionStatus === 'open' ? 'Conectado' : connectionStatus || 'Desconectado'}
            </p>
          </div>
          {connectionStatus === 'open' && (
            <>
              <div className="mb-4">
                <h3 className="text-lg font-semibold mb-2 text-gray-700">Número de teléfono</h3>
                <p className="font-medium">{phoneNumber}</p>
              </div>
              {profileName && (
                <div className="mb-4">
                  <h3 className="text-lg font-semibold mb-2 text-gray-700">Nombre del perfil</h3>
                  <p className="font-medium">{profileName}</p>
                </div>
              )}
              <div className="mb-4">
                <h3 className="text-lg font-semibold mb-3 text-gray-700">Estadísticas</h3>
                <div className="grid grid-cols-3 gap-4">
                  <div className="bg-blue-100 p-3 rounded-lg text-center">
                    <FontAwesomeIcon icon={faEnvelope} className="text-2xl text-blue-500 mb-2" />
                    <p className="font-medium text-gray-800">{messageCount}</p>
                    <p className="text-sm text-gray-600">Mensajes</p>
                  </div>
                  <div className="bg-green-100 p-3 rounded-lg text-center">
                    <FontAwesomeIcon icon={faAddressBook} className="text-2xl text-green-500 mb-2" />
                    <p className="font-medium text-gray-800">{contactCount}</p>
                    <p className="text-sm text-gray-600">Contactos</p>
                  </div>
                  <div className="bg-purple-100 p-3 rounded-lg text-center">
                    <FontAwesomeIcon icon={faComments} className="text-2xl text-purple-500 mb-2" />
                    <p className="font-medium text-gray-800">{chatCount}</p>
                    <p className="text-sm text-gray-600">Chats</p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="qr-code-or-profile">
          {connectionStatus === 'open' ? (
            profilePictureUrl && (
              <div className="text-center">
                <h3 className="text-lg font-semibold mb-2 text-gray-700">Foto de perfil</h3>
                <img src={profilePictureUrl} alt="Foto de perfil" className="mx-auto rounded-full w-32 h-32 border-4 border-green-500" />
              </div>
            )
          ) : qrCode ? (
            <div className="qr-code-container text-center">
              <h3 className="text-lg font-semibold mb-2 text-gray-700">Código QR</h3>
              <img src={qrCode} alt="QR Code" className="mx-auto border-2 border-gray-300 rounded" />
              <p className="mt-2 text-sm text-gray-600">Escanea este código con tu WhatsApp</p>
            </div>
          ) : (
            <p className="text-center text-gray-600">Cargando código QR...</p>
          )}
        </div>
      </div>
      <div className="flex justify-center mt-6">
        {connectionStatus === 'open' ? (
          <button 
            onClick={handleDisconnect}
            className="px-6 py-2 bg-red-500 text-white rounded-full hover:bg-red-600 transition duration-300 shadow-md"
          >
            Desconectar
          </button>
        ) : (
          <button 
            onClick={handleCheckStatus}
            className="px-6 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition duration-300 shadow-md"
          >
            Verificar estado
          </button>
        )}
      </div>
    </div>
  );
}

export default WhatsAppIntegration;
